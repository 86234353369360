import React, { Fragment, useState, useEffect, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import './Header.scss';
import Banding from '../../Sass/img/LOGO.png';
import { useHistory, useLocation } from 'react-router';
import { getData, getNetwork, getBalance } from '../../Utils/helpers';
import {
	deleteAll,
	deleteCookie,
	getCookie,
	setCookie,
} from '../../Utils/cookieHandling';
import axios from 'axios';
import { URL } from '../../Utils/url';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { toast } from 'react-toastify';
import web3 from 'web3';
import getWeb3 from '../../Utils/getWeb3';
import * as Icon from 'react-bootstrap-icons';
import { useMetaMask, useConnectedMetaMask } from 'metamask-react';
export default function LandingPageHeader(props) {
	const roleContext = useContext(UserRoleContext);
	let { status, connect, ethereum, account } = useMetaMask();
	//let { ethereum } = window;
	const location = useLocation();
	const history = useHistory();
	const [disableForm, setDisableForm] = useState(true);
	const [showMenu, setShowMenu] = useState(true);
	const [state, setState] = useState({
		modal: false,
		address: '',
		balance: '',
		network: '',
		symbol: '',
		isMetaMaskInstall: false,
		isConnected: false,
	});
	const [whiteListCheck, setWhiteListCheck] = useState(false);
	useEffect(() => {
		if (localStorage.getItem('token')) {
			roleContext.updateContext({ isLoggedIn: true });
		}
		checktoWhitelist();
	}, []);
	// useEffect(() => {
	//   if (!window.ethereum) {
	//     ethereum = window.ethereum;
	//   }
	// }, [window.ethereum])
	// useEffect(() => {
	//   initialize();

	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props]);
	useEffect(() => {
		sessionStorage.setItem('balance', state.balance);
	}, [state.balance]);

	useEffect(() => {
		if (state.address !== '') {
			getBalance(ethereum, state.address).then((result) => {
				setState((state) => ({
					...state,
					balance: result,
				}));
			});
		} else {
			setState((state) => ({
				...state,
				balance: '',
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.address, state.network]);

	const { id } = useParams();
	const partner_name = id ? id : '';

	// login api
	const loginHandler = async () => {
		if (getCookie('metamaskId')) {
			try {
				const response = await fetch(`${URL}/users/loginWithAddress`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						partnerName: partner_name,
						userAddress: getCookie('metamaskId'),
					}),
				});

				const data = await response.json();

				if (response.status === 200) {
					setCookie('token', data.token);
					setCookie('email', data.email);
					setCookie('address', data.address);
					setCookie('partnerName', data.partnerName);
					roleContext.updateContext({ isLoggedIn: true });
					toast.success(data.message);
					if (location.pathname === '/login') {
						history.push('/dashboard');
					}
				} else {
					toast.error(data.message);
				}
			} catch (err) {
			} finally {
			}
		}
	};
	const checktoWhitelist = () => {
		axios
			.get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('metamaskId')}`)
			.then(function (response) {
				if (response.status === 200) {
					roleContext.updateContext({ card_type: response.data.card_type });
					roleContext.updateContext({
						jdbCardNumber1: response.data.jdbCardNumber1,
					});
					roleContext.updateContext({
						card_activated: response.data.card_activated,
					});

					if (
						response.data.jdbCardNumber1?.length === 16 &&
						response.data.card_activated == 2
					) {
						setDisableForm(false);
					} else {
						setDisableForm(true);
					}

					if (response.data.card_applied == 1) {
						roleContext.updateContext({ card_applied: true });
					}

					if (response.data.kycStatus == '1' || response.data.kycStatus == 1) {
						roleContext.updateContext({ card_purchase: true });
					}
					if (response.data.card_activated == 2) {
						roleContext.updateContext({
							card_activated: response.data.card_activated,
						});
					}
					if (response.data.cardStatus == 'paid') {
						roleContext.updateContext({ card_status: true });
					}
				}
			})
			.catch(function (error) {});
	};
	const initialize = () => {
		if (ethereum && ethereum.isMetaMask) {
			console.info('Metamask installed!', ethereum);

			setState((state) => ({
				...state,
				isMetaMaskInstall: true,
			}));

			getData(ethereum)
				.then((result) => {
					if (result) {
						const [account, network, symbol] = result;
						localStorage.setItem('metamaskId', account);
						setState((state) => ({
							...state,
							address: account,
							network,
							symbol,
							isConnected: true,
						}));
					} else {
						setState((state) => ({
							...state,
							isConnected: false,
						}));
					}
				})
				.catch((error) => {});

			ethereum.on('chainChanged', (_chainId) => {
				getNetwork(ethereum, _chainId).then((result) => {
					const [network, symbol] = result;
					if (_chainId !== '0x38') {
						toast.error('Connect to bsc chain');
						localStorage.clear();
						setState((state) => ({
							...state,
							isConnected: false,
							address: '',
							network: '',
						}));
						return;
					} else {
						setState((state) => ({
							...state,
							network,
							symbol,
						}));
					}
				});
			});

			ethereum.on('accountsChanged', (accounts) => {
				if (accounts[0]) {
					setState((state) => ({
						...state,
						address: accounts[0],
					}));
				} else {
					setState((state) => ({
						...state,
						address: '',
						network: '',
						isConnected: false,
					}));
				}
			});
		} else {
			toast.error('Metamask not installed!');

			setState((state) => ({
				...state,
				isMetaMaskInstall: false,
			}));
		}
	};

	const connectClickHandler = async () => {
		if (ethereum && ethereum.isMetaMask) {
			const web3 = await getWeb3();
			const chainId = await web3.eth.getChainId();
			if (chainId != '56') {
				toast.error('Please Connect to bsc chain');
				localStorage.clear();
				setState((state) => ({
					...state,
					isConnected: false,
					address: '',
					network: '',
				}));
				return;
			}
			connect()
				.then((res) => {
					console.log(res, 'res');
					localStorage.setItem('metamaskId', res[0]);
					whiteListHandler();
					loginHandler();
					setState((state) => ({
						...state,
						address: res[0],
						isConnected: true,
					}));
				})
				.catch((err) => {
					console.error(err, 'fsgvb');
					toast.error(err.message);
				});

			// ethereum
			//   .request({ method: "eth_requestAccounts" })
			//   .then((result) => getData(ethereum, result))
			//   .then((result) => {
			//     const [account, network, symbol] = result;

			//     localStorage.setItem("metamaskId", account);
			//     whiteListHandler()
			//     loginHandler()
			//     setState((state) => ({
			//       ...state,
			//       address: account,
			//       network,
			//       symbol,
			//       isConnected: true,
			//     }));
			//   })
			//   .catch((error) => {

			//   });
		} else if (status !== 'unavailable') {
			connect()
				.then((res) => {
					console.log(res, 'res');
					localStorage.setItem('metamaskId', res[0]);
					whiteListHandler();
					loginHandler();
					setState((state) => ({
						...state,
						address: res[0],
						isConnected: true,
					}));
				})
				.catch((err) => {
					console.error(err, 'fsgvb');
					toast.error(err.message);
				});
		} else {
			toast.error('Please install MetaMask!');
			setState((state) => ({ ...state, modal: true }));
		}
	};

	const whiteListHandler = () => {
		if (!getCookie('metamaskId')) {
			// updateAllCoins()
			connectClickHandler();
			return;
		}
		axios
			.get(
				`${URL}/users/whiteList?userAddress=${getCookie(
					'metamaskId'
				)}&email=${getCookie('email')}`,
				{
					headers: {
						Authorization: getCookie('token'),
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					// updateAllCoins()
					setWhiteListCheck(true);
				} else {
					toast.error(response.data.message);
				}
			})
			.catch(function (error) {
				toast.success(error);
			});
	};

	return (
		<nav class='navbar navbar-expand-lg pb-0 header-new'>
			<div class='container-fluid'>
				<div
					class='navbar-brand d-flex justify-content-between w-100 align-items-center flex-wrap'
					onClick={() => history.push('/')}>
					<img src={Banding} alt='' class='img-fluid w-50' />
					<button
						class='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarResponsive'
						aria-controls='navbarResponsive'
						aria-expanded='false'
						aria-label='Toggle navigation'
						onClick={() => setShowMenu(!showMenu)}>
						<span class='navbar-toggler-icon d-flex align-items-center justify-content-center'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='50'
								height='50'
								fill='white'
								class='bi bi-list'
								viewBox='0 0 16 16'>
								<path
									fill-rule='evenodd'
									d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
								/>
							</svg>
						</span>
					</button>
				</div>

				<div class='navbar-collapse' id='navbarResponsive'>
					<ul class='navbar-nav ms-auto align-items-center'>
						<li
							class='nav-item'
							style={{ display: showMenu ? 'block' : 'none' }}>
							<span
								class='nav-link'
								onClick={() => {
									//deleteCookie('token');
									history.push('/');
								}}>
								Home
							</span>
						</li>
						<li
							class='nav-item'
							style={{ display: showMenu ? 'block' : 'none' }}>
							<a class='nav-link' href='#'>
								Features
							</a>
						</li>
						<li
							class='nav-item'
							style={{ display: showMenu ? 'block' : 'none' }}>
							<a class='nav-link' href='#'>
								Roadmap
							</a>
						</li>
						<li
							class='nav-item'
							style={{ display: showMenu ? 'block' : 'none' }}>
							<a class='nav-link' href='#'>
								Card
							</a>
						</li>
						<li
							class='nav-item'
							style={{ display: showMenu ? 'block' : 'none' }}>
							<a class='nav-link' href='#'>
								Blog
							</a>
						</li>
						<li
							class='nav-item'
							style={{ display: showMenu ? 'block' : 'none' }}>
							<a class='nav-link' href='#'>
								Faq
							</a>
						</li>

						<a href='#' class='Nbtn1 mr--20 cursor-pointer custom'>
							GitBook
						</a>
						<a
							onClick={() => history.push('/affiliate')}
							class='Nbtn1 cursor-pointer d-xm-none custom'>
							Launch App
						</a>
						<div class='d-flex justify-content-between d-xm-flex d-sm-none d-lg-none d-xl-none'>
							<a href='#' class='Nbtn1 mr--20 cursor-pointer'>
								GitBook
							</a>
							<a
								href='#'
								class='Nbtn1 cursor-pointer'
								onClick={() => history.push('/affiliate')}>
								Launch App
							</a>
						</div>
					</ul>
				</div>
			</div>
		</nav>
	);
}
