import React, { useEffect } from 'react';
import CardImage from '../../Sass/img/Credit Card_Monochromatic.svg';
import { useHistory } from 'react-router';
export default function CardActivate() {
	const history = useHistory();
	useEffect(() => {
		setTimeout(() => {
			history.push('/cardisactivate');
			window.location.reload();
		}, 1000);
	}, []);

	return (
		<div className='background-image-test'>
			<div className='container py-4'>
				<div className='row justify-content-center pt-6'>
					<div className='col-lg-6 mb-lg-0 mb-4'>
						<span className='navbar-brand'>
							<img src={CardImage} alt='' className='img-fluid' />
						</span>
						<p className='text-center activetext pt-4'>
							Your KYC approval may take some time. Refresh this page
							periodically to check your status.
						</p>
					</div>
				</div>
				{/* <p className="notetext text-center pt-4">
          Note: Please wait patiently and we will notify you on the next steps
        </p> */}
			</div>
		</div>
	);
}
