import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Sass/helper.scss';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { UserRoleContextProvider } from './Utils/UserAuthorization';
import './Sass/soft-ui-dashboard.css';
ReactDOM.render(
	<BrowserRouter>
		<UserRoleContextProvider>
			<App />
		</UserRoleContextProvider>
	</BrowserRouter>,
	document.getElementById('root')
);
