import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { getCookie } from '../../Utils/cookieHandling';
import { URL } from '../../Utils/url';
import './affiliate.scss';
import AffiliateTable from './AffiliateTable';
import Table from 'react-bootstrap/Table';

import SubAffiliateList from './SubAffiliateList';
import { useHistory } from 'react-router';
import Earnings from './Earnings';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { toast } from 'react-toastify';
import copy from '../../Sass/img/copy_address.png';
import CurrentTierScreen from './currentTierScreen';
import ReactDatePicker from 'react-datepicker';
import EarningsTwo from './Earnings2';
import { ClockLoader } from 'react-spinners';
export default function Affiliate() {
	const [listData, setListData] = React.useState(null);
	const roleContext = useContext(UserRoleContext);
	const [subAffiliate, setSubAffiliate] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState(1);
	const [earnings, setEarnings] = React.useState([]);
	const [currentTierLevel, setCurrentTierLevel] = React.useState(null);
	const history = useHistory();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Simulate a delay of 2000 milliseconds (2 seconds)
		const delay = 2000;

		const timer = setTimeout(() => {
			// Set isLoading to false after the delay
			setLoading(false);
		}, delay);

		// Clear the timer if the component is unmounted
		return () => clearTimeout(timer);
	}, []);
	const override = {
		display: 'block',
		margin: '0 auto',
	};

	const currentUsersAddress = localStorage.getItem('address');

	const fetchTotalEarnings = async () => {
		try {
			const response = await axios.get(
				`${URL}/backOffice/get/affiliate/card/user/total/earnings/0x3cfb94d7b4f733a83d29101ac928c2a9e0369179`
			);
			await setEarnings(response.data);
			console.log(response.data);
		} catch (error) {
			console.log(error);
			toast.error(error.response.data);
			if (error.response.data) {
				setEarnings([]);
			}
			// toast.error(error.response.data);
		}
	};
	const fetchDownlineUsers = async () => {
		try {
			const response = await axios.get(
				`${URL}/backOffice/get/card/users/downLines/${currentUsersAddress}`
			);
			await setListData(response.data);
			console.log(response.data);
		} catch (error) {
			console.log(error);
			toast.error(error.response.data);
			if (error.response.data) {
				setListData([]);
			}
			// toast.error(error.response.data);
		}
	};

	// useEffect(() => {
	// 	try {
	// 		axios
	// 			.get(
	// 				`${URL}/backOffice/get/affiliate/card/user/earnings/0x3cfb94d7b4f733a83d29101ac928c2a9e0369179/2023/10`
	// 			)
	// 			.then((res) => {
	// 				console.log("RES", res.data);
	// 			});
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }, []);

	useEffect(() => {
		if (currentTab === 1) {
			fetchTotalEarnings();
		} else if (currentTab === 3) {
			fetchDownlineUsers();
		}
	}, [currentTab]);

	const copyAddressToClipboard = async (add) => {
		navigator.clipboard.writeText(add);
		toast.success('Link copied to clipboard');
	};

	return (
		<div
			className='background-image-test2 custom-class-con'
			id='data-new'
			style={{
				height: '100% !important',
				padding: '0px !important',
			}}>
			{loading ? (
				<div className='row' style={{ height: '500px' }}>
					<div className='col-12 text-center my-auto d-flex justify-content-center'>
						<div className='m-auto'>
							<ClockLoader color='white' size={86} override={override} />
						</div>
					</div>
				</div>
			) : (
				<>
					<div
						className='d-flex justify-content-center align-items-center'
						style={{ paddingTop: '20px' }}>
						<span
							className='text-center  '
							style={{ marginRight: '10px', color: 'white', fontSize: '30px' }}>
							{'https://' +
								window.location.hostname +
								'/' +
								roleContext.affiliate_link}
						</span>
						<img
							className='cursor-pointer'
							src={copy}
							width='30px'
							title='Copy'
							alt='copy'
							onClick={() => {
								copyAddressToClipboard(
									'https://' +
										window.location.hostname +
										'/' +
										roleContext.affiliate_link
								);
							}}
						/>
					</div>
					<div className='sidebar'>
						<div
							onClick={() => setCurrentTab(1)}
							className={`cursor-pointer ${currentTab === 1 ? 'active' : ''}`}>
							Total Earnings
						</div>

						<div
							onClick={() => setCurrentTab(2)}
							className={`cursor-pointer ${currentTab === 2 ? 'active' : ''}`}>
							Earning Form Cardload
						</div>
						<div
							onClick={() => setCurrentTab(3)}
							className={`cursor-pointer ${currentTab === 3 ? 'active' : ''}`}>
							List of Downline users
						</div>
					</div>
				</>
			)}

			{listData && currentTab === 3 && <AffiliateTable listData={listData} />}

			{earnings && currentTab === 2 && <Earnings />}
			{earnings && currentTab === 1 && <EarningsTwo earnings={earnings} />}
		</div>
	);
}

// React.useEffect(() => {

//   axios
//     .get(
//       `${URL}/users/${
//         currentTab === 6
//           ? "affiliate_directusers_cardload"
//           : "subaffiliate_directusers_cardload"
//       }/${getCookie("affiliate_link")}`,
//       {
//         headers: {
//           Authorization: getCookie("token"),
//         },
//       }
//     )
//     .then((res) => {
//       if (res.status === 200 || res.status === 201) {
//         setListData(res.data);
//       }
//     })
//     .catch((err) => {});
//   axios
//     .get(
//       `${URL}/users/get_sub_affiliatelist/${getCookie("affiliate_link")}`,
//       {
//         headers: {
//           Authorization: getCookie("token"),
//         },
//       }
//     )
//     .then((res) => {
//       if (res.status === 200 || res.status === 201) {
//         setSubAffiliate(res.data);
//       }
//     })
//     .catch((err) => {});
//   axios
//     .get(
//       `${URL}/users/${
//         currentTab === 1
//           ? "affiliate_lifetime_earnings"
//           : currentTab === 2
//           ? "affiliate_pending_earnings"
//           : "affiliate_paidout_earnings"
//       }/${getCookie("affiliate_link")}`,
//       {
//         headers: {
//           Authorization: getCookie("token"),
//         },
//       }
//     )
//     .then((res) => {
//       if (res.status === 200 || res.status === 201) {
//         setEarnings(res.data);
//       }
//     })
//     .catch((err) => {});
//   axios
//   	.get(
//   		`${URL}/users/affiliates_current_tierlevel/${getCookie(
//   			"affiliate_link"
//   		)}`,
//   		{
//   			headers: {
//   				Authorization: getCookie("token"),
//   			},
//   		}
//   	)
//   	.then((res) => {
//   		if (res.status === 200 || res.status === 201) {
//   			setCurrentTierLevel(res.data);
//   		}
//   	})
//   	.catch((err) => {});
// }, [currentTab]);
