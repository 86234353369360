import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import { getCookie } from '../../Utils/cookieHandling';
import { CardSubmitInitialValues } from '../../Utils/initialValues';
import { cardSubmitSchema } from '../../Utils/validations';
import Button from '../../Components/Common/Button/Button';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { URL } from '../../Utils/url';
import OtpInput from 'react-otp-input';
import { RotatingLines } from 'react-loader-spinner';
import { ClockLoader } from 'react-spinners';
export default function CardSubmit() {
	const [state, setState] = useState({
		number1: '',

		image: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(true);
	const [file, setFile] = useState(null);

	const handlefileChange = (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		if (file) {
			setFile(file);
		}
	};

	useEffect(() => {
		// Simulate a delay of 2000 milliseconds (2 seconds)
		const delay = 2000;

		const timer = setTimeout(() => {
			// Set isLoading to false after the delay
			setLoading(false);
		}, delay);

		// Clear the timer if the component is unmounted
		return () => clearTimeout(timer);
	}, []);
	const override = {
		display: 'block',
		margin: '0 auto',
	};
	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);

			fileReader.onload = () => {
				resolve(fileReader.result);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};
	const history = useHistory();

	const cardSubmitApi = async () => {
		console.log(state.number1);
		setIsLoading(true);
		if (!file) {
			toast.error('Please upload the image');
			return;
		}
		if (state.number1.length !== 16) {
			toast.error('Please enter the card number');
			return;
		}

		var data = JSON.stringify({
			jdbCardNumber1: state.number1.toString(),
			card_activated: '1',
		});

		const formData = new FormData();

		formData.append('card_img', file);

		formData.append('userData', JSON.stringify(data));
		var config = {
			method: 'put',
			url: `${URL}/users/verifyCard/${getCookie('metamaskId')}`,
			headers: {
				Authorization: getCookie('token'),
				'Content-Type': 'multipart/form-data',
			},
			data: formData,
		};
		axios(config)
			.then(function (response) {
				if (response.data.status === 200) {
					setIsLoading(false);
					toast.success(response.data.message);
					history.push('/card-process');
				}
			})
			.catch(function (error) {
				toast.error(error.response.data);
				setIsLoading(false);
			});
	};

	return (
		<div
			className='background-image-test card-form card-form-2'
			style={{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '50px' }}>
			{loading ? (
				<div className='row' style={{ height: '500px' }}>
					<div className='col-12 text-center my-auto d-flex justify-content-center'>
						<div className='m-auto'>
							<ClockLoader color='white' size={86} override={override} />
						</div>
					</div>
				</div>
			) : (
				<>
					<h6 class='ms-2 mb-0   headtext mb-3'>Card Activation</h6>
					<div class='row '>
						<div class='col-lg-6 mb-lg-0 mb-4'>
							<div class=' card2 card z-index-2'>
								<div class='card-body p-6 pt-4 pb-0'>
									<h6 class='ms-2 mb-0 text-center text-black headtext mb-3'>
										Input your 16 digit card number
									</h6>

									<div class='row justify-content-center '>
										<div class='col-md-12  p-4 pt-1 mb-0 '>
											{/* <div class=" d-flex justify-content-center "> */}
											{/* <input
                      value={state.number}
                      onChange={(e) => {
                        setState({ ...state, number: e.target.value });
                      }}
                    /> */}
											{/* <form> */}
											<OtpInput
												value={state.number1}
												onChange={(e) => {
													setState({ ...state, number1: e });
												}}
												numInputs={16}
												inputStyle={{
													marginLeft: '20px',
													width: '20px',
													color: 'black',
													border: 'none',
													borderBottom: '1px solid black  ',
													backgroundColor: 'transparent',
												}}
												containerStyle={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													flexWrap: 'wrap',
												}}
												separator={<span></span>}
											/>
											{/* </form> */}
											{/* </div> */}
										</div>
									</div>
								</div>
							</div>
							<p class='' style={{ textAlign: 'right' }}>
								Note: Wait patiently for your card to arrive.
							</p>
						</div>
						<div class='col-lg-6 mb-lg-0 mb-4'>
							<div
								class='card z-index-2'
								style={{ background: '#3A73C9 !important' }}>
								<div class='card-body p-6 pt-4 pb-0'>
									<h6 class='ms-2 mb-0 text-center text-black  headtext mb-3'>
										Upload a clear selfie of you holding the card and passport
										bio page
									</h6>
									<div class='row justify-content-center '>
										<div class='col-md-12  p-4 pt-1 mb-0 '>
											<form>
												<div class='  row pb-3 mb-4'>
													<div class=' d-flex justify-content-center'>
														<input
															type='file'
															class='filepond'
															name='filepond'
															onChange={async (e) => {
																handlefileChange(e);
															}}
														/>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class=' justify-content-center text-center pt-4 pb-0'>
							{/* <Button
            type="submit"
            className="btn btns"
            text={"Submit"}
            clicked={cardSubmitApi}
          /> */}
							{isLoading ? (
								<button className='btn btns'>
									<RotatingLines
										strokeColor='white'
										strokeWidth='5'
										animationDuration='0.75'
										width='30'
										visible={true}
									/>
								</button>
							) : (
								<Button
									type='submit'
									className='btn btns'
									text={'Submit'}
									clicked={cardSubmitApi}
								/>
							)}
						</div>
					</div>
					<div class='row my-4'></div>
				</>
			)}
		</div>
	);
}
