import { useEffect, React, useState, useContext } from 'react';
import './Home.scss';
import RData from '../../Sass/img/R.png';
import { URL } from '../../Utils/url';
import LogoIcon from '../../Sass/img/Logoicon.svg';
import USDT from '../../Sass/img/USDT.png';
import USDC from '../../Sass/img/USDC.png';
import BTC from '../../Sass/img/BTC.png';
import AUTO from '../../Sass/img/AUTO.png';
import ABI from '../../abi.json';
import ABIEther from '../../abiether.json';
import ABIEther2 from '../../abiether2.json';
import ABIXDC from '../../abixdc.json';
import getWeb3 from '../../Utils/getWeb3';
import EPS from '../../Sass/img/EPS.png';
import MBOX from '../../Sass/img/MBOX.png';
import XVS from '../../Sass/img/XVS.png';
import CAKE from '../../Sass/img/CAKE.png';
import BUSD from '../../Sass/img/BUSD.png';
import ETH from '../../Sass/img/ETH.png';
import CRV from '../../Sass/img/Curve.png';
import MKR from '../../Sass/img/MKR.png';
import CVX from '../../Sass/img/convex.png';
import LDO from '../../Sass/img/lido.png';
import AAVE from '../../Sass/img/aave.png';
import UNI from '../../Sass/img/uniswap.png';
import COMP from '../../Sass/img/compound.png';
import INST from '../../Sass/img/instadapp.png';
import BAL from '../../Sass/img/balancer.png';
import SUSHI from '../../Sass/img/sushiswap.png';
import YEARN from '../../Sass/img/yearn.png';
import DAI from '../../Sass/img/dai.png';
import AVAX from '../../Sass/img/avalanche.png';
import WBTC from '../../Sass/img/wrapedbtc.png';
import WETH from '../../Sass/img/wrappedeth.png';
import GBEX from '../../Sass/img/globiance.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../../Utils/cookieHandling';
import HeaderNavigator from '../../Components/Header/HeaderNavigator';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ethers } from 'ethers';
import { BigNumber as BN } from 'bignumber.js';
import BSCMetamaskBalance from './BSCMetamaskBalance';

export default function XDCMetamaskBalances() {
	const [balances, setbalances] = useState([]);
	const calculateDecimalFor8 = (value) => {
		if (value != undefined && value != null) {
			var num = value;
			var with5Decimals = num.toString().match(/^-?\d+(?:\.\d{0,8})?/)[0];
			return with5Decimals;
		}
		return 0;
	};
	useEffect(async () => {
		// const web3 = await getWeb3();
		// var accs = await web3.eth.getAccounts();
		// const newAccounts = await Promise.all(accs.map(async (address) => {
		//     const balance = await web3.eth.getBalance(address)
		//     const tokenBalances = await Promise.all(
		//         [
		//             {
		//                 address: 'xdcc6ae1db6c66d909f7bfeeeb24f9adb8620bf9dbf',
		//                 token: 'USDC',
		//                 image: USDC
		//             },
		//             {
		//                 address: 'xdcd4b5f10d61916bd6e0860144a91ac658de8a1437',
		//                 token: 'USDT',
		//                 image: USDT
		//             },
		//             // {
		//             //     address: 'xdc71f7f49ff81e9be77c9175611212ecb199ba0824',
		//             //     token: 'BTC',
		//             //     image: BTC
		//             // },
		//             {
		//                 address: 'xdc34514748F86A8dA01Ef082306b6d6e738F777f5A',
		//                 token: 'GBEX',
		//                 image: GBEX
		//             },
		//         ].map(async (token) => {
		//             const tokenInst = new web3.eth.Contract(ABIXDC, token.address);
		//             const balance = await tokenInst.methods.balanceOf(address).call()
		//             return {
		//                 token: token.token,
		//                 balance,
		//                 image: token.image
		//             }
		//         }))
		//     return tokenBalances;
		// }))
		// setbalances(newAccounts[0]);
		// console.log(newAccounts, "dataatatatat");
	}, []);
	return (
		<div className='row'>
			{/* {
                balances.map((balance, index) => {
                    return <div className={(localStorage.getItem("metamaskId") && localStorage.getItem("token")) ? 'col-md-3 p-2 pt-4' : "col-md-3 p-2 pt-4 reduce_opacity"}>
                        <div className="wbtc wbtc-w">
                            <div className="brificon">
                                <img src={balance.image} width="40px" />
                            </div>
                            <p>{balance.token} </p>
                            <h4 className="price" style={{ fontSize: "15px" }}>


                                <>
                                    {calculateDecimalFor8((
                                        balance.balance / Math.pow(10, 6)
                                    ))}
                                </>
                            </h4>
                           


                        </div>
                    </div>;
                })

            } */}

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={USDC} width='40px' />
					</div>
					<p>USDC</p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					{/* <h4 className="price">
                           $
                           {layerPricing("USDT") ?
                               <>
                                   {calculateDecimal((
                                       (layerPricing("USDT") ? layerPricing("USDT") : 0)
                                   ) * (
                                           metamaskBalance.findIndex(data => data.token === "USDT") !== -1 ? metamaskBalance.find(data => data.token === "USDT").balance : 0
                                       ))}
                               </> : 0}
                       </h4> */}
				</div>
			</div>

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={USDT} width='40px' />
					</div>
					<p>USDT </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					{/* <h4 className="price">
                           $
                           {layerPricing("USDT") ?
                               <>
                                   {calculateDecimal((
                                       (layerPricing("USDT") ? layerPricing("USDT") : 0)
                                   ) * (
                                           metamaskBalance.findIndex(data => data.token === "USDT") !== -1 ? metamaskBalance.find(data => data.token === "USDT").balance : 0
                                       ))}
                               </> : 0}
                       </h4> */}
				</div>
			</div>

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={GBEX} width='40px' />
					</div>
					<p>GBEX </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					{/* <h4 className="price">
                           $
                           {layerPricing("USDT") ?
                               <>
                                   {calculateDecimal((
                                       (layerPricing("USDT") ? layerPricing("USDT") : 0)
                                   ) * (
                                           metamaskBalance.findIndex(data => data.token === "USDT") !== -1 ? metamaskBalance.find(data => data.token === "USDT").balance : 0
                                       ))}
                               </> : 0}
                       </h4> */}
				</div>
			</div>
		</div>
	);
}
