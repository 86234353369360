import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import CardImage from '../../Sass/img/Credit Card_Monochromatic.svg';
import { getCookie } from '../../Utils/cookieHandling';
import { URL } from '../../Utils/url';
import { ClockLoader } from 'react-spinners';

export default function CardIsActivate() {
	const history = useHistory();
	const [loading, setLoading] = useState(true);

	const [activated, setActivated] = useState(false);
	const checktoWhitelist = () => {
		axios
			.get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('metamaskId')}`)
			.then(function (response) {
				if (response.status === 200) {
					if (response.data.card_active_reject === 'Activated') {
						setActivated(true);
					}
				}
			})
			.catch(function (error) {});
	};
	useEffect(() => {
		// Simulate a delay of 2000 milliseconds (2 seconds)
		const delay = 2000;

		const timer = setTimeout(() => {
			// Set isLoading to false after the delay
			setLoading(false);
		}, delay);

		// Clear the timer if the component is unmounted
		return () => clearTimeout(timer);
	}, []);

	const activateUser = () => {
		axios
			.get(`${URL}/users/user_activated/${getCookie('metamaskId')}`)
			.then(function (response) {
				toast.success('Card Activated Successfully');
				history.push('/');
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const override = {
		display: 'block',
		margin: '0 auto',
	};

	useEffect(() => {
		checktoWhitelist();
		setInterval(() => {
			checktoWhitelist();
		}, 5000);
	}, []);
	return (
		<div className='background-image-test'>
			{loading ? (
				<div className='row' style={{ height: '500px' }}>
					<div className='col-12 text-center my-auto d-flex justify-content-center'>
						<div className='m-auto'>
							<ClockLoader color='white' size={86} override={override} />
						</div>
					</div>
				</div>
			) : (
				<div className='container py-4'>
					<div className='row justify-content-center pt-6'>
						<div className='col-lg-6 mb-lg-0 mb-4'>
							<span className='navbar-brand'>
								<img src={CardImage} alt='' className='img-fluid' />
							</span>
							{activated ? (
								<div className='d-flex align-items-center flex-column'>
									<p className='text-center activetext pt-2'>
										your card has been activated, please allow up to 1 working
										day to be used globally
									</p>
									<button className='btn btn-w' onClick={activateUser}>
										Proceed To Dashboard
									</button>
								</div>
							) : (
								<p className='text-center activetext pt-2'>
									Your activation is being processed <br />
									正在处理您的激活
								</p>
							)}
						</div>
					</div>
					{!activated && (
						<p className='notetext text-center pt-4'>
							Refresh this page periodically to check your card
							Activation Status <br />
							定期刷新此页面以检查您的卡 激活状态
						</p>
					)}
				</div>
			)}
		</div>
	);
}
