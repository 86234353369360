import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './Screens/Home/Home';
import CardForm from './Screens/CardForm/CardForm';
import { UserRoleContextConsumer } from './Utils/UserAuthorization';
import React from 'react';
import HeaderNavigator from './Components/Header/HeaderNavigator';
import { ToastContainer } from 'react-toastify';
import CardActivate from './Screens/CardForm/CardActivate';
import CardIsActivate from './Screens/CardForm/CardIsActivate';
import Landing from './Screens/Landing/Landing';
import cardSubmit from './Screens/CardForm/card-submit';
import carLoad from './Screens/CardForm/card-load';
import Dopple from './Screens/Dopple/Dopple';
import CardPurchase from './Screens/CardPurchase/CardPurchase';
import CardLoadHome from './Screens/Home/cardLoadHome';
import { MetaMaskProvider, useMetaMask } from 'metamask-react';
import CardActivate2 from './Screens/CardForm/CardActive2';
import LogRocket from 'logrocket';
import SignUp from './Screens/Auth/signUp';
import Affiliate from './Screens/Home/Affiliate';
import 'react-toastify/dist/ReactToastify.css';
function App() {
	const notLoggedIn = (
		<Switch>
			{/* <ToastContainer /> */}
			<Route path='/login' component={Home} exact />
			<Route path='/affiliate' component={Home} exact />
			<Route path='/register' component={Home} exact />
			<Route path='/signup' component={SignUp} exact />
			<Route path='/login/:id' component={Dopple} exact />
			{/* <Route path="/forgotpassword" component={ForgotPasswordLink} exact /> */}
			{/* <Route path="/password-reset/:id/:id" component={ForgotPassword} exact /> */}
			{/* <Route path="/register" component={Auth} exact /> */}
			{/* <Route path="/login" component={Auth} exact /> */}
			{/* <Route path="/partnerShip" component={PartnerShip} exact /> */}
			<Route path='/:id' component={Dopple} exact />
			<Route path='/backoffice/:id' component={Dopple} exact />
			<Route path='/' component={Landing} exact />

			<Redirect to='/' />
		</Switch>
	);
	const loggedIn = (
		<div className='h-100 home-container'>
			{/* <ToastContainer /> */}
			{/* <Header /> */}

			<HeaderNavigator />
			<Switch>
				<Route path='/card-form' component={CardForm} exact />
				<Route path='/dashboard' component={Home} exact />
				<Route path='/card-load-home' component={CardLoadHome} exact />
				<Route path='/' component={Home} exact />
				{/* <Route path="/stake" component={Stake} exact /> */}
				<Route path='/card-process' component={CardActivate2} exact />
				<Route path='/card-activate' component={CardActivate} exact />
				<Route path='/cardisactivate' component={CardIsActivate} exact />
				<Route path='/card-submit' component={cardSubmit} exact />
				<Route path='/card-load' component={carLoad} exact />
				<Route path='/cardpurchase' component={CardPurchase} exact />
				{/* <Route path="/test-token-price" component={TestToken} exact /> */}
				<Route path='/affiliate/dashboard' component={Affiliate} exact />
				<Redirect to='/' />
			</Switch>
		</div>
	);

	React.useEffect(() => {
		LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
		LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
			name: localStorage.getItem('address'),
			email: 'masbank.io',
		});
	}, []);
	return (
		<>
			{' '}
			<MetaMaskProvider>
				<UserRoleContextConsumer>
					{(value) =>
						value?.isLoggedIn ||
						(localStorage.getItem('token') &&
							localStorage.getItem('metamaskId'))
							? loggedIn
							: notLoggedIn
					}
				</UserRoleContextConsumer>
				<ToastContainer limit={1} autoClose={2000} />
			</MetaMaskProvider>
		</>
	);
}

export default App;
