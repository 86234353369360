import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './Header.scss';
import Banding from '../../Sass/img/LOGO.png';
import { useHistory, useLocation } from 'react-router';
import { getData, getNetwork, getBalance } from '../../Utils/helpers';
import { deleteAll, getCookie, setCookie } from '../../Utils/cookieHandling';
import axios from 'axios';
import { URL } from '../../Utils/url';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { toast } from 'react-toastify';
import getWeb3 from '../../Utils/getWeb3';
import ETH from '../../Sass/img/ETH.png';
import { useMetaMask, useConnectedMetaMask } from 'metamask-react';
import LogRocket from 'logrocket';

export default function HeaderNavigator(props) {
	const roleContext = useContext(UserRoleContext);
	let { status, connect, ethereum, account } = useMetaMask();
	const [currentChain, setCurrentChain] = useState('');
	//let { ethereum } = window;
	const location = useLocation();
	const [clickData, setClickData] = useState(false);
	const history = useHistory();
	const [disableForm, setDisableForm] = useState(true);
	const [showMenu, setShowMenu] = useState(true);
	const [modifiedDate, setModifiedDate] = useState(false);
	const [state, setState] = useState({
		modal: false,
		address: '',
		balance: '',
		network: '',
		symbol: '',
		isMetaMaskInstall: false,
		isConnected: false,
	});
	const [whiteListCheck, setWhiteListCheck] = useState(false);
	useEffect(() => {
		if (localStorage.getItem('token') && localStorage.getItem('metamaskId')) {
			roleContext.updateContext({ isLoggedIn: true });
		}

		checktoWhitelist();
	}, []);

	useEffect(() => {
		if (roleContext.isLoggedIn) {
			LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
			LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
				name: getCookie('metamaskId'),
				email: 'masbank.io',
			});
		}
	}, [roleContext.isLoggedIn]);

	// useEffect(() => {
	//   if (!window.ethereum) {
	//     ethereum = window.ethereum;
	//   }
	// }, [window.ethereum])
	// useEffect(() => {
	//   initialize();

	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props]);
	useEffect(() => {
		sessionStorage.setItem('balance', state.balance);
	}, [state.balance]);

	useEffect(() => {
		if (state.address !== '') {
			getBalance(ethereum, state.address).then((result) => {
				setState((state) => ({
					...state,
					balance: result,
				}));
			});
		} else {
			setState((state) => ({
				...state,
				balance: '',
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.address, state.network]);

	const { id } = useParams();

	console.log(location.pathname);
	// login api
	const loginHandler = async () => {
		const partner =
			(await location?.pathname) !== '/affiliate' && location?.pathname;
		const partner_name =
			(await location.pathname) === '/affiliate'
				? 'masbank.io'
				: 'masbank.io' + partner;
		let body = JSON.stringify({
			partnerName: partner_name,
			userAddress: getCookie('metamaskId'),
			affiliate_level: getCookie('affiliate_level')
				? JSON.parse(getCookie('affiliate_level')).affiliate_level + 1
				: null,
			domain_name: 'Mascap',
			backOffice: true,
		});

		if (getCookie('metamaskId')) {
			try {
				axios
					.post(`${URL}/users/loginWithAddress`, body, {
						headers: {
							'Content-Type': 'application/json',
						},
					})
					.then((response) => {
						const { data } = response;

						if (response.status === 200) {
							setCookie('token', data.token);
							setCookie('email', data.email);
							setCookie('address', data.address);
							setCookie(
								'partnerName',
								data.partnerName ? data.partnerName : ''
							);
							roleContext.updateContext({ isLoggedIn: true });
							toast.success(data.message);
							if (location.pathname == '/affiliate') {
								axios
									.put(
										`${URL}/users/updateaffiliate/${getCookie('metamaskId')}`,
										{ affiliate: true },
										{
											headers: {
												Authorization: data.token,
											},
										}
									)
									.then((res) => {
										if (res.status == 200 || res.status == 201) {
											console.log('affiliate updated');
											roleContext.updateContext({ affiliate: true });
											history.push('/');
										} else {
											toast.error(res.data.message);
										}
									})
									.catch((e) => toast.error('Something went wrong'));
							}
							if (location.pathname === '/login') {
								history.push('/');
							}
							if (location.pathname !== '/affiliate') {
								history.push('/');
							}
						}
					})
					.catch((error) => {
						console.log(error.response.data, 'called1');
						toast.error(error.response.data);
						localStorage.clear();
					});
			} catch (error) {
				console.log('called2');
				localStorage.clear();

				toast.error(error.response.data);
			}
		}
	};
	const checktoWhitelist = () => {
		axios
			.get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('metamaskId')}`)
			.then(function (response) {
				if (response.status === 200) {
					roleContext.updateContext({ affiliate: response.data.affiliate });
					roleContext.updateContext({ card_type: response.data.card_type });
					roleContext.updateContext({
						affiliate_link: response.data.affiliate_link,
					});
					roleContext.updateContext({
						affiliate_level: response.data.affiliate_level,
					});
					roleContext.updateContext({
						jdbCardNumber1: response.data.jdbCardNumber1,
					});
					roleContext.updateContext({
						card_activated: response.data.card_activated,
					});
					roleContext.updateContext({ ascii_value: response.data.ascii_value });
					roleContext.updateContext({ ascii_value: response.data.ascii_value });
					roleContext.updateContext({
						card_active_reject: response.data.card_active_reject,
					});
					roleContext.updateContext({
						card_active_reject: response.data.card_active_reject,
					});
					setCookie('affiliate_link', response.data.affiliate_link);
					var now;
					let modifiedStatus = false;
					if (response.data.stakedate != null) {
						now = new Date(response.data.stakedate);
						now.setMinutes(now.getMinutes() + 30);
						now = new Date(now);
					}

					if (new Date() > now) {
						modifiedStatus = true;
					}

					roleContext.updateContext({ staking: response.data.staking });
					roleContext.updateContext({ stakingTime: modifiedStatus });
					roleContext.updateContext({
						approveStacking: response.data.stakeapprove,
					});

					if (
						response.data.jdbCardNumber1?.length === 16 &&
						response.data.card_activated == 2
					) {
						setDisableForm(false);
					} else {
						setDisableForm(true);
					}

					if (response.data.card_applied == 1) {
						roleContext.updateContext({ card_applied: true });
					}

					if (response.data.kycStatus == '1' || response.data.kycStatus == 1) {
						roleContext.updateContext({ card_purchase: true });
					}
					if (response.data.card_activated == 2) {
						roleContext.updateContext({
							card_activated: response.data.card_activated,
						});
					}
					if (response.data.cardStatus == 'paid') {
						roleContext.updateContext({ card_status: true });
					}
					if (
						response.data.cardStatus === null &&
						response.data.card_type === 0
					) {
						history.push('/cardpurchase');
					}
					if (
						response.data.affiliate &&
						(response.data.kycStatus === '1' ||
							response.data.kycStatus === 1) &&
						response.data.jdbCardNumber1 == null &&
						response.data.cardStatus === 'paid'
					) {
						history.push('/card-process');
						return;
					}
					if (
						response.data.card_applied === 0 &&
						response.data.cardStatus === 'paid'
					) {
						history.push('/card-form');
						return;
					}
					if (
						response.data.jdbCardNumber1 !== null &&
						response.data.card_active_reject === 'Activated' &&
						response.data.user_activated === false
					) {
						history.push('/cardisactivate');
						return;
					}
					if (response.data.card_activated == 2) {
						return;
					}
					if (response.data.card_activated == 1) {
					}
					if (
						(response.data.kycStatus === '1' ||
							response.data.kycStatus === 1) &&
						response.data.jdbCardNumber1 &&
						(response.data.card_active_reject === null ||
							response.data.card_active_reject === 'Rejected')
					) {
						history.push('/card-submit');
						return;
					}
					if (
						response.data.jdbCardNumber1 &&
						response.data.card_active_reject === 'inprogress'
					) {
						history.push('/cardisactivate');
						return;
					}
				}
			})
			.catch(function (error) {});
	};
	const initialize = () => {
		if (ethereum && ethereum.isMetaMask) {
			console.info('Metamask installed!', ethereum);

			setState((state) => ({
				...state,
				isMetaMaskInstall: true,
			}));

			getData(ethereum)
				.then((result) => {
					if (result) {
						const [account, network, symbol] = result;
						localStorage.setItem('metamaskId', account);
						setState((state) => ({
							...state,
							address: account,
							network,
							symbol,
							isConnected: true,
						}));
					} else {
						setState((state) => ({
							...state,
							isConnected: false,
						}));
					}
				})
				.catch((error) => {});

			ethereum.on('chainChanged', (_chainId) => {
				getNetwork(ethereum, _chainId).then((result) => {
					const [network, symbol] = result;
					if (_chainId !== '0x38') {
						toast.error('Connect to bsc chain');
						localStorage.clear();
						setState((state) => ({
							...state,
							isConnected: false,
							address: '',
							network: '',
						}));
						return;
					} else {
						setState((state) => ({
							...state,
							network,
							symbol,
						}));
					}
				});
			});

			ethereum.on('accountsChanged', (accounts) => {
				if (accounts[0]) {
					setState((state) => ({
						...state,
						address: accounts[0],
					}));
				} else {
					setState((state) => ({
						...state,
						address: '',
						network: '',
						isConnected: false,
					}));
				}
			});
		} else {
			toast.error('Metamask not installed!');

			setState((state) => ({
				...state,
				isMetaMaskInstall: false,
			}));
		}
	};

	const connectClickHandler = async () => {
		if (ethereum && ethereum.isMetaMask) {
			const web3 = await getWeb3();
			const chainId = await web3.eth.getChainId();
			if (chainId == '56') {
				setCurrentChain('BSC');
				localStorage.setItem('currentChain', 'BSC');
			} else if (chainId == '3') {
				setCurrentChain('ETH');
				localStorage.setItem('currentChain', 'ETH');
			} else {
				setCurrentChain('XDC');
				localStorage.setItem('currentChain', 'XDC');
			}
			// if (chainId != "56") {
			//   toast.error('Please Connect to bsc test chain');
			//   localStorage.clear()
			//   setState((state) => ({
			//     ...state,
			//     isConnected: false,
			//     address: "",
			//     network: "",
			//   }));
			//   return
			// }
			connect()
				.then((res) => {
					localStorage.setItem('metamaskId', res[0]);
					whiteListHandler();
					loginHandler();
					LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
					LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
						name: res[0],
						email: 'masbank.io',
					});
					setState((state) => ({
						...state,
						address: res[0],
						isConnected: true,
					}));
				})
				.catch((err) => {
					toast.error(err.message);
				});

			// ethereum
			//   .request({ method: "eth_requestAccounts" })
			//   .then((result) => getData(ethereum, result))
			//   .then((result) => {
			//     const [account, network, symbol] = result;

			//     localStorage.setItem("metamaskId", account);
			//     whiteListHandler()
			//     loginHandler()
			//     setState((state) => ({
			//       ...state,
			//       address: account,
			//       network,
			//       symbol,
			//       isConnected: true,
			//     }));
			//   })
			//   .catch((error) => {

			//   });
		} else if (status !== 'unavailable') {
			connect()
				.then((res) => {
					console.log(res, 'res');
					localStorage.setItem('metamaskId', res[0]);
					whiteListHandler();
					loginHandler();
					LogRocket.init('fmzwvp/dt-defi-whitelabels-frontend');
					LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
						name: res[0],
						email: 'masbank.io',
					});
					setState((state) => ({
						...state,
						address: res[0],
						isConnected: true,
					}));
				})
				.catch((err) => {
					console.error(err, 'fsgvb');
					toast.error(err.message);
				});
		} else {
			toast.error('Please install MetaMask!');
			setState((state) => ({ ...state, modal: true }));
		}
	};

	const whiteListHandler = () => {
		if (!getCookie('metamaskId')) {
			// updateAllCoins()
			connectClickHandler();
			return;
		}
		axios
			.get(
				`${URL}/users/whiteList?userAddress=${getCookie(
					'metamaskId'
				)}&email=${getCookie('email')}`,
				{
					headers: {
						Authorization: getCookie('token'),
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					// updateAllCoins()
					setWhiteListCheck(true);
				} else {
					toast.error(response.data.message);
				}
			})
			.catch(function (error) {
				toast.success(error);
			});
	};
	const networks = {
		eth: {
			chainId: '0x1',
			chainName: 'Ethereum',
			nativeCurrency: {
				name: 'Ethereum',
				symbol: 'ETH',
				decimals: 18,
			},
			rpcUrls: ['https://mainnet.infura.io/v3/undefined'],
			blockExplorerUrls: ['https://etherscan.io'],
		},
		bsc: {
			chainId: `0x${Number(56).toString(16)}`,
			chainName: 'Binance Smart Chain Mainnet',
			nativeCurrency: {
				name: 'Binance Chain Native Token',
				symbol: 'BNB',
				decimals: 18,
			},

			rpcUrls: ['https://bsc-dataseed.binance.org'],
			blockExplorerUrls: ['https://bscscan.com'],
		},

		xdc: {
			chainId: `0x${Number(50).toString(16)}`,
			chainName: 'XinFin Network Mainnet',
			nativeCurrency: {
				name: 'XinFin',
				symbol: 'XDC',
				decimals: 18,
			},
			rpcUrls: ['https://rpc.xinfin.network'],
			blockExplorerUrls: ['https://xinfin.org'],
		},
	};
	const changeNetwork = async ({ networkName }) => {
		try {
			if (!window.ethereum) throw new Error('No crypto wallet found');
			await window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [
					{
						...networks[networkName],
					},
				],
			});
			setCurrentChain(networkName);
			localStorage.setItem('currentChain', networkName);
			console.log(networkName, 'networkName');
			window.location.reload();
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(async () => {
		const web3 = await getWeb3();
		const chainId = await web3.eth.getChainId();
		// handleNetworkSwitch("bsc");
		if (chainId == '56') {
			setCurrentChain('BSC');
			localStorage.setItem('currentChain', 'BSC');
		} else if (chainId == '50') {
			setCurrentChain('XDC');
			localStorage.setItem('currentChain', 'XDC');
		} else {
			setCurrentChain('ETH');
			localStorage.setItem('currentChain', 'ETH');
		}
	}, []);

	const handleNetworkSwitch = async (networkName) => {
		if (networkName.toLowerCase() == 'eth') {
			window.ethereum
				.request({
					method: 'wallet_switchEthereumChain',
					params: [{ chainId: '0x1' }],
				})
				.then(() => {
					setCurrentChain(networkName.toUpperCase());
					localStorage.setItem('currentChain', networkName.toUpperCase());
					window.location.reload();
				})
				.catch((e) => {
					toast.error(e);
				});

			return;
		}

		await changeNetwork({ networkName });
	};
	return (
		<nav class='navbar navbar-expand-lg navbar-dark1 pb-0 header-new'>
			<div class='container-fluid'>
				<span class='navbar-brand' onClick={() => history.push('/')}>
					<img src={Banding} alt='' class='img-fluid w-25' />
				</span>

				<div class='navbar-collapse' id='navbarResponsive'>
					<ul class='navbar-nav ms-auto'>
						<li class='nav-item first-nav d-flex justify-content-between'>
							<button
								class='navbar-toggler'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#navbarResponsive'
								aria-controls='navbarResponsive'
								aria-expanded='false'
								aria-label='Toggle navigation'
								onClick={() => setShowMenu(!showMenu)}>
								<span class='navbar-toggler-icon d-flex align-items-center justify-content-center'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='50'
										height='50'
										fill='white'
										class='bi bi-list'
										viewBox='0 0 16 16'>
										<path
											fill-rule='evenodd'
											d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
										/>
									</svg>
								</span>
							</button>
							<div
								className='position-relative'
								style={{ marginRight: '20px' }}>
								{getCookie('metamaskId') && getCookie('token') && (
									<button
										className='chainbutton'
										onClick={() => {
											setClickData(!clickData);
										}}>
										{currentChain == '' ? 'Chain' : currentChain}
									</button>
								)}
								{clickData && (
									<div
										className='position-absolute absolute-container'
										style={{ background: '#3A73C9' }}>
										<div>
											{' '}
											<img
												src={
													'https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png'
												}
												width='20px'
											/>
											<span
												onClick={() => {
													handleNetworkSwitch('bsc');
													setClickData(!clickData);
												}}>
												BSC
											</span>
										</div>
										<div style={{ marginTop: '20px' }}>
											<img src={ETH} width='20px' />{' '}
											<span
												onClick={() => {
													handleNetworkSwitch('eth');
													setClickData(!clickData);
												}}>
												ETH
											</span>
										</div>
										{/* <div style={{marginTop:'20px'}}><img src={XDC} width="20px" /> <span onClick={() => { handleNetworkSwitch("xdc"); 
                 setClickData(!clickData);}}>XDC</span></div> */}
									</div>
								)}
							</div>

							<span
								class=' text-white text-center mobile-styles'
								style={{
									backgroundColor: getCookie('metamaskId')
										? '#3A73C9'
										: '#3A73C9',
									width:
										getCookie('metamaskId') && getCookie('token')
											? 'auto'
											: '190px',
									padding: '10px',
									borderRadius: '5px',
									color: 'white !important',
									cursor: 'pointer',
								}}
								onClick={
									getCookie('metamaskId') && getCookie('token')
										? () => {}
										: connectClickHandler
								}>
								{getCookie('metamaskId') && getCookie('token')
									? getCookie('metamaskId').slice(0, 6) +
									  '...' +
									  getCookie('metamaskId').substr(
											getCookie('metamaskId').length - 4
									  )
									: 'Connect to metamask'}
							</span>
						</li>

						{localStorage.getItem('metamaskId') &&
						localStorage.getItem('token') ? (
							<>
								<li
									class='nav-item ms-3'
									style={{ display: showMenu ? 'block' : 'none' }}>
									<span
										class={
											location.pathname === '/' ||
											location.pathname === '/dashboard'
												? 'nav-link active'
												: 'nav-link'
										}
										onClick={() => {
											history.push('/');
											checktoWhitelist();
										}}>
										Home
									</span>
								</li>

								{disableForm ? null : (
									<li
										class='nav-item '
										style={{ display: showMenu ? 'block' : 'none' }}>
										<span
											class={
												location.pathname === '/card-load-home'
													? 'nav-link active'
													: 'nav-link'
											}
											style={{ width: '100px' }}
											onClick={() => {
												history.push('/card-load-home');
												checktoWhitelist();
											}}>
											Load Card
										</span>
									</li>
								)}

								{roleContext.affiliate_link ? (
									<li
										class='nav-item '
										style={{ display: showMenu ? 'block' : 'none' }}>
										<span
											class={
												location.pathname === '/affiliate/dashboard'
													? 'nav-link active text-center'
													: 'nav-link text-center'
											}
											style={{ width: '100px' }}
											onClick={() => {
												history.push('/affiliate/dashboard');
												checktoWhitelist();
											}}>
											Affiliate
										</span>
									</li>
								) : null}

								{
									<li
										class='nav-item'
										style={{ display: showMenu ? 'block' : 'none' }}>
										<span
											class={
												location.pathname === '/card-form' ||
												location.pathname === '/card-activate' ||
												location.pathname === '/card-submit' ||
												location.pathname === '/card-load' ||
												location.pathname === '/cardisactivate' ||
												location.pathname === '/cardpurchase' ||
												location.pathname === '/card-process'
													? 'nav-link active'
													: 'nav-link'
											}
											onClick={() => {
												console.log(roleContext.card_purchase);
												if (
													roleContext.affiliate &&
													(roleContext.card_purchase === '1' ||
														roleContext.card_purchase === 1) &&
													roleContext.jdbCardNumber1 == null &&
													roleContext.card_status === 'paid'
												) {
													history.push('/card-process');
													return;
												}
												if (
													roleContext.card_applied == 0 &&
													(roleContext.card_status == true ||
														roleContext.card_status == 'paid')
												) {
													history.push('/card-form');
												}
												if (
													roleContext.jdbCardNumber1 != null &&
													roleContext.card_active_reject == 'inprogress'
												) {
													history.push('/cardisactivate');
													return;
												}
												if (roleContext.card_activated == 2) {
													history.push('/card-load');
												} else if (roleContext.card_activated == 1) {
												} else if (
													((roleContext.card_purchase === '1' ||
														roleContext.card_purchase === 1) &&
														roleContext.jdbCardNumber1 != '' &&
														roleContext.jdbCardNumber1 != null) ||
													roleContext.card_active_reject == 'Rejected'
												) {
													history.push('/card-submit');
												} else if (roleContext.card_applied == 2) {
													history.push('/card-load');
												} else if (
													roleContext.card_status == null ||
													roleContext.card_status == false
												) {
													history.push('/cardpurchase');
												}
											}}>
											{roleContext.card_activated == 2 ? 'Transaction' : 'Card'}
										</span>
									</li>
								}
								{console.log(
									roleContext.card_status,
									roleContext.staking,
									roleContext.stakingTime
								)}
								{/* {roleContext.affiliate?null:   roleContext.card_status 
                 ?
                  <>

                    <li class="nav-item" style={{ display: showMenu ? "block" : "none" }}>
                      <span
                        class={
                          location.pathname === "/stake"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => {
                          history.push("/stake");
                          checktoWhitelist();
                        }}
                      >
                        Stake
                      </span>
                    </li>
                  </> : ""} */}
								<li
									class='nav-item'
									style={{ display: showMenu ? 'block' : 'none' }}>
									<span
										class='nav-link'
										onClick={() => {
											//deleteCookie('token');
											deleteAll();
											history.push('/');
											window.location.reload();
										}}>
										Logout
									</span>
								</li>
							</>
						) : (
							''
						)}
						{/*  <li>
             {status === "initializing" ?  <div>Synchronisation with MetaMask ongoing...</div>
   :
   status === "unavailable" ? <div>MetaMask not available :(</div>
   :
  status === "notConnected" ? <button onClick={connect}>Connect to MetaMask</button>
    : status === "connecting" ? <div>Connecting...</div> : account} 
            </li> */}
					</ul>
				</div>
			</div>
		</nav>
	);
}
