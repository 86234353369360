import React, { useEffect, useState } from 'react';
import CardImage from '../../Sass/img/Credit Card_Monochromatic.svg';
import { ClockLoader } from 'react-spinners';
export default function CardActivate2() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const delay = 2000;

		const timer = setTimeout(() => {
			setLoading(false);
		}, delay);

		return () => clearTimeout(timer);
	}, []);
	const override = {
		display: 'block',
		margin: '0 auto',
	};

	return (
		<div className='background-image-test'>
			<div className='container py-4'>
				{loading ? (
					<div className='row' style={{ height: '500px' }}>
						<div className='col-12 text-center my-auto d-flex justify-content-center'>
							<div className='m-auto'>
								<ClockLoader color='white' size={86} override={override} />
							</div>
						</div>
					</div>
				) : (
					<div className='row justify-content-center pt-6'>
						<div className='col-lg-6 mb-lg-0 mb-4'>
							<span className='navbar-brand'>
								<img src={CardImage} alt='' className='img-fluid' />
							</span>
							<p className='text-center activetext pt-4'>
								Your card is being Activated. Refresh this page periodically to
								check your status.
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
