import React, { useContext, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import copy from '../../Sass/img/copy_address.png';
import { toast } from 'react-toastify';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { URL } from '../../Utils/url';
export default function Earnings() {
	const useStyles = makeStyles({
		root: {
			width: '100%',
		},
		container: {
			maxHeight: 500,
		},
		cell_long: {
			fontSize: '10px',
			width: 600,
			minWidth: 1,
			backgroundColor: '#ee82ee',
		},
	});

	const [currentTab, setCurrentTab] = React.useState(1);
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [page2, setPage2] = React.useState(0);
	const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
	const [startDate, setStartDate] = useState(new Date());
	const [earnings, setEarnings] = useState([]);
	const [lastMonth, setLastMonth] = useState();
	const [lastYear, setLastYear] = useState();
	const [selectedDate, setSelectedDate] = useState(new Date());
	const currentUsersAddress = localStorage.getItem('address');

	const myTheme = createMuiTheme({
		// Theme settings
		palette: {
			type: 'dark',
		},
	});
	const handleChangePage2 = (event, newPage) => {
		setPage2(newPage);
	};

	const handleChangeRowsPerPage2 = (event) => {
		setRowsPerPage2(event.target.value);
		setPage2(0);
	};
	const calculateDecimal = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,2})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};
	const copyAddressToClipboard = async (add) => {
		navigator.clipboard.writeText(add);
		toast.success('Address copied to clipboard');
	};
	function convert(n) {
		var sign = +n < 0 ? '-' : '',
			toStr = n.toString();
		if (!/e/i.test(toStr)) {
			return n;
		}
		var [lead, decimal, pow] = n
			.toString()
			.replace(/^-/, '')
			.replace(/^([0-9]+)(e.*)/, '$1.$2')
			.split(/e|\./);
		return +pow < 0
			? sign +
					'0.' +
					'0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
					lead +
					decimal
			: sign +
					lead +
					(+pow >= decimal.length
						? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
						: decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
	}
	const calculateDecimalFor8 = (value) => {
		if (value != undefined) {
			var num = value;
			var with5Decimals = num.toString().match(/^-?\d+(?:\.\d{0,5})?/)[0];
			return with5Decimals;
		}
		return 0;
	};

	const calculateDecimal3 = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,2})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0];

				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};

	const fetchCardLoadData = async () => {
		try {
			if (!lastMonth || !lastYear) {
				const currentDate = new Date();
				const lastMonthDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth()
				);

				// Format the date as per your requirements
				const formattedLastMonth = `${
					lastMonthDate.toLocaleString('default', {
						month: 'long',
					}) === 'January'
						? '0'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'February'
						? '1'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'March'
						? '2'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'April'
						? '3'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'May'
						? '4'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'June'
						? '5'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'July'
						? '6'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'August'
						? '7'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'September'
						? '8'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'October'
						? '9'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'November'
						? '10'
						: lastMonthDate.toLocaleString('default', {
								month: 'long',
						  }) === 'December' && '11'
				} `;
				const formattedLastYear = `${lastMonthDate.getFullYear()}`;

				setLastMonth(formattedLastMonth);
				setLastYear(formattedLastYear);
			}

			if (lastMonth && lastYear) {
				const response = await axios.get(
					`${URL}/backOffice/get/affiliate/card/user/earnings/${currentUsersAddress}/${lastYear}/${lastMonth}`
				);
				await setEarnings(response.data);
				// console.log(response.data);
			}
		} catch (error) {
			console.log(error);
			toast.error(error.response.data);
			if (error.response.data) {
				setEarnings([]);
			}
			// toast.error(error.response.data);
		}
	};

	useEffect(() => {
		// getExcel();
		const currentDate = new Date();
		const lastMonthDate = new Date(currentDate);
		lastMonthDate.setMonth(currentDate.getMonth());
		setSelectedDate(lastMonthDate);
	}, []);

	useEffect(() => {
		fetchCardLoadData();
	}, [lastMonth, lastYear]);

	return (
		<div class='aff-container'>
			<div class='affiliate-table-container'>
				<div id='example_wrapper' class='dataTables_wrapper'>
					{true ? (
						<div>
							<ThemeProvider theme={myTheme}>
								<TableContainer
									// className={classes.container}
									style={{
										background: 'transparent',
										boxShadow: 'none',
									}}
									className={`table-res-mobile ${classes.container}`}>
									<div
										className='d-flex justify-content-between'
										style={{ zIndex: 9999 }}>
										<span
											style={{
												textAlign: 'start',
												color: 'white',
												display: 'flex',
												justifyContent: 'end',
												fontSize: '20px',
												marginBottom: '20px',
											}}>
											<span style={{ width: '150px' }}>Select Date</span>
											<ReactDatePicker
												selected={selectedDate && selectedDate}
												placeholderText='DD/MM/YYYY'
												onChange={(date) => {
													const selectedMonthYear = {
														month: date.getMonth() + 1, // Months are zero-indexed
														year: date.getFullYear(),
													};
													console.log(selectedMonthYear);
													setLastMonth(
														selectedMonthYear.month === 1
															? '0'
															: selectedMonthYear.month === 2
															? '1'
															: selectedMonthYear.month === 3
															? '2'
															: selectedMonthYear.month === 4
															? '3'
															: selectedMonthYear.month === 5
															? '4'
															: selectedMonthYear.month === 6
															? '5'
															: selectedMonthYear.month === 7
															? '6'
															: selectedMonthYear.month === 8
															? '7'
															: selectedMonthYear.month === 9
															? '8'
															: selectedMonthYear.month === 10
															? '9'
															: selectedMonthYear.month === 11
															? '10'
															: selectedMonthYear.month === 12 && '11'
													);
													setLastYear(selectedMonthYear.year);
													setSelectedDate(date);
												}}
												// selectsStart
												//   startDate={startDate}
												//   endDate={endDate}
												className='smallcal'
												// showTimeSelect
												showMonthYearPicker
												dateFormat='MM/yyyy'
											/>
										</span>
										<span
											style={{
												textAlign: 'end',
												color: 'white',
												display: 'flex',
												justifyContent: 'end',
												fontSize: '20px',
												marginBottom: '20px',
											}}>
											Total Earnings : USD{' '}
											{earnings.totalEarnings ? earnings.totalEarnings : 0}
											{/* {calculateDecimal3(
												earnings?.total_earnings?.partnerFee
											) ||
												calculateDecimal3(
													earnings?.pending_earnings?.partnerFee
												)} */}
										</span>
									</div>
									<Table
										stickyHeader
										aria-label='sticky table'
										style={{
											background: 'transparent',
											boxShadow: 'none',
										}}>
										<TableHead className='head'>
											<TableRow
												style={{
													background: 'black !important',
													zIndex: -1,
												}}>
												{/* {columns.map((column) => ( */}
												<TableCell
												// key={column.id}
												// align={column.align}
												>
													S/N
												</TableCell>
												<TableCell>User Address</TableCell>
												<TableCell>Cardload Amount</TableCell>
												<TableCell>Company Percentage</TableCell>
												<TableCell>Fee To Company</TableCell>
												<TableCell>Affiliate CardUser Address</TableCell>
												<TableCell>Affiliate CardUser Percentage</TableCell>
												<TableCell>Fee To Affiliate CardUser</TableCell>
												<TableCell>Updated Date</TableCell>
												<TableCell>Created Date</TableCell>

												{/* ))} */}
											</TableRow>
										</TableHead>
										<TableBody className='tablebody'>
											{earnings.feeRecords ? (
												earnings.feeRecords
													.slice(
														page2 * rowsPerPage2,
														page2 * rowsPerPage2 + rowsPerPage2
													)
													.map((row, index) => (
														<TableRow
															key={index}
															//  className="tableRow"
														>
															<TableCell
																style={{ overflow: 'auto' }}
																//  style={customColumnStyle}
															>
																{index + 1 || 'N/A'}
															</TableCell>
															<TableCell style={{ overflow: 'auto' }}>
																{row.address
																	? row.address?.slice(0, 4) +
																			'...' +
																			row.address.substr(
																				row.address.length - 4
																			) || 'N/A'
																	: null}
																{row.address && (
																	<img
																		className='cursor-pointer'
																		alt='copy'
																		style={{ marginLeft: '10px' }}
																		src={copy}
																		width='20px'
																		title='Copy'
																		onClick={() => {
																			copyAddressToClipboard(row.address);
																		}}
																	/>
																)}
															</TableCell>
															<TableCell style={{ overflow: 'auto' }}>
																{/* {calculateDecimalFor8(row.cardLoadAmount) || "N/A"}
																 */}
																{row.cardLoadAmount}
															</TableCell>

															<TableCell style={{ overflow: 'auto' }}>
																{row.companyPercentage || 'N/A'}
															</TableCell>
															<TableCell>
																{' '}
																{row.feeToCompany || 'N/A'}
															</TableCell>
															<TableCell>
																{' '}
																{row.affiliateCardUserAddress
																	? row.affiliateCardUserAddress?.slice(0, 4) +
																			'...' +
																			row.affiliateCardUserAddress.substr(
																				row.affiliateCardUserAddress.length - 4
																			) || 'N/A'
																	: null}
															</TableCell>
															<TableCell>
																{' '}
																{row.affiliateCardUserPercentage || 'N/A'}
															</TableCell>
															<TableCell>
																{' '}
																{row.feeToAffiliateCardUser || 'N/A'}
															</TableCell>
															{/* <TableCell style={{ textAlign: 'end' ,overflow:'auto'}}>{Number(calculateDecimal(convert(row.cardLoadAmount) / (Math.pow(10, 18)))).toFixed(2) || "N/A"}</TableCell> */}
															<TableCell style={{ overflow: 'auto' }}>
																{moment(row.updatedAt).format('DD/MM/YYYY')}
															</TableCell>
															<TableCell style={{ overflow: 'auto' }}>
																{moment(row.createdAt).format('DD/MM/YYYY')}
															</TableCell>
														</TableRow>
													))
											) : (
												<h5
													style={{
														width: '261px',
														marginLeft: '400px',
														color: 'red',
													}}>
													No Data To Display
												</h5>
											)}
										</TableBody>
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[]}
									component='div'
									count={earnings.feeRecords ? earnings.feeRecords.length : 0}
									rowsPerPage={rowsPerPage2}
									page={page2}
									onPageChange={handleChangePage2}
									onRowsPerPageChange={handleChangeRowsPerPage2}
									// onChangePage={handleChangePage2}
									// onChangeRowsPerPage={handleChangeRowsPerPage2}
								/>
							</ThemeProvider>
						</div>
					) : (
						<center
							style={{
								marginTop: '40px',
								marginLeft: '30vw',
							}}
							className='no-data'>
							No card load history
						</center>
					)}
				</div>
			</div>
		</div>
	);
}
