import { useEffect, React, useState, useContext } from 'react';
import './Home.scss';
import RData from '../../Sass/img/R.png';
import { URL } from '../../Utils/url';
import LogoIcon from '../../Sass/img/Logoicon.svg';
import USDT from '../../Sass/img/USDT.png';
import USDC from '../../Sass/img/USDC.png';
import BTC from '../../Sass/img/BTC.png';
import AUTO from '../../Sass/img/AUTO.png';
import EPS from '../../Sass/img/EPS.png';
import MBOX from '../../Sass/img/MBOX.png';
import XVS from '../../Sass/img/XVS.png';
import CAKE from '../../Sass/img/CAKE.png';
import BUSD from '../../Sass/img/BUSD.png';
import ETH from '../../Sass/img/ETH.png';
import CRV from '../../Sass/img/Curve.png';
import MKR from '../../Sass/img/MKR.png';
import CVX from '../../Sass/img/convex.png';
import LDO from '../../Sass/img/lido.png';
import AAVE from '../../Sass/img/aave.png';
import UNI from '../../Sass/img/uniswap.png';
import COMP from '../../Sass/img/compound.png';
import INST from '../../Sass/img/instadapp.png';
import BAL from '../../Sass/img/balancer.png';
import SUSHI from '../../Sass/img/sushiswap.png';
import YEARN from '../../Sass/img/yearn.png';
import DAI from '../../Sass/img/dai.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../../Utils/cookieHandling';
import HeaderNavigator from '../../Components/Header/HeaderNavigator';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { Modal, Button } from 'react-bootstrap';
import { ClockLoader } from 'react-spinners';

var web3 = require('web3');
export default function CardLoadHome() {
	const roleContext = useContext(UserRoleContext);
	const [partner, setPartner] = useState(null);
	const [wbtc, setWbtc] = useState([]);
	const [eth, setEth] = useState([]);
	const [busd, setBusd] = useState([]);
	const [bnb, setBnb] = useState([]);
	const [bal, setbalance] = useState(0);
	const [balanceData, newBalanceData] = useState(null);
	const [hideModal, sethideModal] = useState(false);
	const [hideModal2, sethideModal2] = useState(false);
	const [priceUSDT, setUSDT] = useState(null);
	const [priceUSDC, setUSDC] = useState(null);
	const [priceBTC, setBTC] = useState(null);
	const [priceAUTO, setAUTO] = useState(null);
	const [priceEPS, setEPS] = useState(null);
	const [priceMBOX, setMBOX] = useState(null);
	const [priceXVS, setXVS] = useState(null);
	const [priceCAKE, setCAKE] = useState(null);
	const [priceBUSD, setBUSD] = useState(null);
	const [priceETH, setETH] = useState(null);
	const [priceMKR, setMKR] = useState(null);
	const [priceCRV, setCRV] = useState(null);
	const [priceCVX, setCVX] = useState(null);
	const [priceLDO, setLDO] = useState(null);
	const [priceAAVE, setAAVE] = useState(null);
	const [priceUNI, setUNI] = useState(null);
	const [priceCOMP, setCOMP] = useState(null);
	const [priceBAL, setBAL] = useState(null);
	const [priceINST, setINST] = useState(null);
	const [priceSUSHI, setSUSHI] = useState(null);
	const [priceYFI, setYFI] = useState(null);
	const [priceDAI, setDAI] = useState(null);
	const [settingsUSDT, setSettingsUSDT] = useState(null);
	const [settingsUSDC, setSettingsUSDC] = useState(null);
	const [settingsBTC, setSettingsBTC] = useState(null);
	const [settingsAUTO, setSettingsAUTO] = useState(null);
	const [settingsEPS, setSettingsEPS] = useState(null);
	const [settingsMBOX, setSettingsMBOX] = useState(null);
	const [settingsXVS, setSettingsXVS] = useState(null);
	const [settingsCAKE, setSettingsCAKE] = useState(null);
	const [settingsBUSD, setSettingsBUSD] = useState(null);
	const [settingsETH, setSettingsETH] = useState(null);
	const [settingsBNB, setSettingsBNB] = useState(null);
	const [settingsMKR, setSettingsMKR] = useState(null);
	const [settingsCRV, setSettingsCRV] = useState(null);
	const [settingsCVX, setSettingsCVX] = useState(null);
	const [settingsLDO, setSettingsLDO] = useState(null);
	const [settingsAAVE, setSettingsAAVE] = useState(null);
	const [settingsUNI, setSettingsUNI] = useState(null);
	const [settingsCOMP, setSettingsCOMP] = useState(null);
	const [settingsBAL, setSettingsBAL] = useState(null);
	const [settingsINST, setSettingsINST] = useState(null);
	const [settingsSUSHI, setSettingsSUSHI] = useState(null);
	const [settingsYFI, setSettingsYFI] = useState(null);
	const [settingsDAI, setSettingsDAI] = useState(null);
	const [cardLoadFee, setCardLoadFee] = useState(null);
	const [wallletBalance, setWalletBalance] = useState(null);
	const [amount, setAmount] = useState(null);
	const [disableForm, setDisableForm] = useState(false);
	const [settings, setSettings] = useState(null);
	const [showModal, setshowModal] = useState(false);
	const [showModal2, setshowModal2] = useState(false);
	const [settingsConstants, setSettingsConstants] = useState(null);
	const [tokenSettingsConstants, setTokenSettingsConstants] = useState(null);
	const [depositMetamask, setDepositMetamask] = useState(true);
	const [amountIssue, setAmountIssue] = useState(0);
	const [enableQr, setEnableQr] = useState(null);
	const [final_amount, setFinalAMount] = useState(null);
	const [sub_final_amount, setSubFinalAMount] = useState(null);
	const [cardLoadStore, setCardLoadStore] = useState(null);
	const [affiliate_otc, setAffiliate_otc] = useState(null);
	const [affiliate_sub_otc, setAffiliate_sub_otc] = useState(null);
	const [teirData, setTierData] = useState(null);
	const [subteirData, setSubTierData] = useState(null);
	const [metamaskBalance, setMetamaskBalance] = useState({
		usdt: '',
		eps: '',
		busd: '',
		usdc: '',
		mbox: '',
		eth: '',
		btc: '',
		xvs: '',
		bnb: '',
		auto: '',
		cake: '',
	});
	const [disableBtn, setDisableBtn] = useState(false);

	const calculateMetamaskBalance = async () => {};
	const calculateDecimal = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,2})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};

	const calculateDecimal4 = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,4})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};

	const calculateDecimal3 = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,2})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0];

				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};
	const calculateHoleDec = (value) => {
		if (value != undefined && value != null) {
			return value.toString().substring(0, 9);
		}
		return 0;
	};
	const calculateDecimalFor8 = (value) => {
		if (value != undefined) {
			var num = value;
			var with5Decimals = num.toString().match(/^-?\d+(?:\.\d{0,8})?/)[0];
			return with5Decimals;
		}
		return 0;
	};
	const calculateDecimalFor5 = (value) => {
		if (
			value != undefined &&
			value != null &&
			isNaN(value) === false &&
			value != Infinity
		) {
			var num = value;

			var with5Decimals = num.toString().match(/^-?\d+(?:\.\d{0,5})?/)[0];
			return with5Decimals;
		}

		return 0;
	};
	const [state, setState] = useState({
		token: '',
		amount: '',
		total_amount: '',
	});
	// API for coingeeko
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Simulate a delay of 2000 milliseconds (2 seconds)
		const delay = 2000;

		const timer = setTimeout(() => {
			// Set isLoading to false after the delay
			setLoading(false);
		}, delay);

		// Clear the timer if the component is unmounted
		return () => clearTimeout(timer);
	}, []);
	const override = {
		display: 'block',
		margin: '0 auto',
	};

	const [faData, set2FAData] = useState(null);
	const [enteredOTP, setenteredOTP] = useState(null);
	const checktoWhitelist2 = () => {
		axios
			.get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('metamaskId')}`)
			.then(function (response) {
				if (response.status === 200) {
					if (
						response.data.ascii_value == null ||
						response.data.ascii_value == ''
					) {
						setEnableQr(true);
						get2FA();
					}
				}
			})
			.catch(function (error) {});
	};

	const getTierData1 = () => {
		axios
			.get(`${URL}/admin/get_tier_affiliate`, {
				headers: {
					Authorization: getCookie('token'),
				},
			})
			.then(function (response) {
				if (response.status === 200) {
				} else {
					toast.error(response.data.message);
				}
			})
			.catch(function (error) {});
	};

	const inter = () => {
		let intervalId = setInterval(function () {
			priceHandler();
			getBalanceHandler();
			getSettings();
			partnerCheck();
		}, 10000);
		return () => {
			clearInterval(intervalId);
		};
	};

	useEffect(() => {
		getTierData1();
		getFinalamount();
		checktoWhitelist2();

		if (localStorage.getItem('metamaskId') && localStorage.getItem('token')) {
			getSettings();
			partnerCheck();
			getBalanceHandler();
			priceHandler();
			checktoWhitelist();
			calculateDecimal(20);
			inter();
		}
	}, []);

	const updateAscii = (value) => {
		axios
			.put(`${URL}/users/updateascii/${getCookie('metamaskId')}`, {
				ascii_value: value,
			})
			.then((res) => {
				if (res.status === 200 || res.status === 201) {
				}
			});
	};
	const get2FA = () => {
		axios
			.get(`${URL}/users/signup_googleAuth/${getCookie('metamaskId')}`)
			.then((res) => {
				if (res.status === 200) {
					// roleContext.updateContext({ ascii_value: res?.data?.secret?.ascii });

					set2FAData(res?.data);
				}
			});
	};
	const update2FA = () => {
		if (roleContext.ascii_value == null || roleContext.ascii_value == '') {
			updateAscii(faData?.secret?.ascii);
		}

		axios
			.post(`${URL}/users/verify_googleAuth`, {
				asci:
					roleContext.ascii_value == null || roleContext.ascii_value == ''
						? faData?.secret?.ascii
						: roleContext.ascii_value,
				otp: Number(enteredOTP),
			})
			.then((res) => {
				if ((res.status === 200 || res.status === 201) && res.data) {
					toast.success('Otp validated');
					setshowModal2(false);
					setshowModal(true);
				} else {
					toast.error('Invalid otp');
				}
			});
	};

	Number.prototype.between = function (a, b) {
		var min = Math.min.apply(Math, [a, b]),
			max = Math.max.apply(Math, [a, b]);
		return this > min && this < max;
	};

	const getTierByFinalAmount = async (
		amount,
		teirData2,
		finalAmountaffiliate
	) => {
		let finalData = {
			tier: 5,
			percentage: 100,
		};
		let log = false;
		teirData2?.map((item) => {
			if (
				item.from <=
					parseInt(Number(amount)) + parseInt(Number(finalAmountaffiliate)) &&
				item.to >=
					parseInt(Number(amount)) + parseInt(Number(finalAmountaffiliate)) &&
				log == false
			) {
				finalData = {
					tier: item.tier,
					percentage: item.percentage,
				};

				log = true;
			}
		});

		return finalData;
	};

	const loadCardSubmit = async () => {
		if (state.token == '') {
			toast.error('Please select the token');
			return;
		}

		if (state.token.toUpperCase() !== 'BUSD') {
			if (state.total_amount < 100) {
				toast.error('Minimum amount is $100');
				return;
			}
		}

		if (state.amount == 0 || state.amount == null || state.amount == '') {
			toast.error("token amount can't be 0");
			return;
		}

		setshowModal2(true);

		//setshowModal2(true)
	};
	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text).then(
			() => toast.success('Text copied to clipboard'),
			(err) => console.error('Could not copy text: ', err)
		);
	};

	const copyAddressToClipboard = () => {
		// axios
		//   .get(`${URL}/users/getDepositAddress`, {
		//     headers: {
		//       Authorization: getCookie("token"),
		//     },
		//   })
		//   .then(function (response) {
		//     if (response.status === 200) {
		//       navigator.clipboard.writeText(response.data.address);
		//       toast.success("Address copied to clipboard");
		//     } else {
		//       toast.error(response.data.message);
		//     }
		//   })
		//   .catch(function (error) {
		//     toast.success(error);
		//   });
	};

	const checktoWhitelist = async () => {
		await axios
			.get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('metamaskId')}`)
			.then(function (response) {
				if (response.status === 200) {
					if (getCookie('token'))
						if (
							response.data.jdbCardNumber1.length === 16 &&
							response.data.card_activated === 2
						) {
							setDisableForm(false);
						} else {
							setDisableForm(true);
						}
				} else {
					//  toast.error(response.data.message);
				}
			})
			.catch(function (error) {
				// toast.success(error);
			});
	};
	//
	useEffect(() => {
		if (sessionStorage.getItem('balance')) {
			setbalance(sessionStorage.getItem('balance'));
		}
	}, [sessionStorage.getItem('balance')]);

	const getFinalamount = async () => {
		axios
			.get(`${URL}/users/get_finalamount/${getCookie('metamaskId')}`, {
				headers: {
					Authorization: getCookie('token'),
				},
			})
			.then(function (response) {
				if (response.status === 200) {
					setTierData(response.data?.mastertierlevel);
					setSubTierData(response.data?.subtier_leve);
					setFinalAMount(response?.data?.totalamount?.finalAmount);
					setSubFinalAMount(response?.data?.subtotalamount?.finalAmount);
					setAffiliate_otc(response?.data?.affiliateOtc?.partner_otc);
					setAffiliate_sub_otc(
						response?.data?.subaffiliateOtc?.sub_affiliate_otc
					);
					setCardLoadStore(response?.data);
				} else {
					toast.error(response.data.message);
				}
			})
			.catch(function (error) {
				toast.error(error);
			});
	};
	const getBalanceHandler = async () => {
		await axios
			.get(`${URL}/users/walletBalance/${getCookie('metamaskId')}`, {
				headers: {
					Authorization: getCookie('token'),
				},
			})
			.then(function (response) {
				if (response.status === 200) {
					newBalanceData(response.data);
				} else {
					toast.error(response.data.message);
				}
			})
			.catch(function (error) {
				toast.success(error);
			});
	};
	const confirmCardLoad = async () => {
		const finalData = await getTierByFinalAmount(
			Pricing(state.token) * amountIssue,
			teirData,
			final_amount
		);
		const finalData2 = await getTierByFinalAmount(
			Pricing(state.token) * amountIssue,
			subteirData,
			sub_final_amount
		);
		let isPartner = partner !== '' && partner !== null;

		let totalAmount = Math.abs(
			isPartner
				? Pricing(state.token) * amountIssue -
						(Pricing(state.token) *
							amountIssue *
							(parseFloat(partner?.partner_otc) / 100) +
							Pricing(state.token) *
								amountIssue *
								(parseFloat(partner?.prv_otc) / 100))
				: Pricing(state.token) * amountIssue -
						Pricing(state.token) * amountIssue * (tokenSettingsConstants / 100)
		);

		let cardAmount =
			totalAmount - (totalAmount * parseFloat(cardLoadFee)) / 100;
		console.log(amountIssue, '&&&&&Amount');
		console.log(totalAmount, '$$$#%$^%&%^&');
		await axios
			.post(`${URL}/users/createCardPayment`, {
				cardLoadAmount: cardAmount * Math.pow(10, 18),
				otcAmount:
					state.token == 'USDT' ||
					state.token == 'USDC' ||
					state.token == 'BUSD'
						? totalAmount * (settingsConstants / 100)
						: totalAmount * (tokenSettingsConstants / 100),
				partnerFee: isPartner
					? Pricing(state.token) *
					  amountIssue *
					  (parseFloat(partner?.partner_otc) / 100)
					: 0,
				assetType:
					state.token.toLowerCase() == 'cake'
						? 'Cake'
						: state.token.toLowerCase() == 'btc'
						? 'btcb'
						: state.token.toLowerCase() == 'busd'
						? 'busd'
						: state.token.toLowerCase() == 'eth'
						? 'eth'
						: state.token,
				prvFee: isPartner
					? Pricing(state.token) *
					  amountIssue *
					  (parseFloat(partner?.prv_otc) / 100)
					: state.token == 'USDT' ||
					  state.token == 'USDC' ||
					  state.token == 'BUSD'
					? Pricing(state.token) * amountIssue * (settingsConstants / 100)
					: Pricing(state.token) * amountIssue * (tokenSettingsConstants / 100),
				userAddress: getCookie('metamaskId'),
				quantity: amountIssue,
				cardLoadFee: totalAmount * (parseFloat(cardLoadFee) / 100),
				finalAmount: Pricing(state.token) * amountIssue,
				convertedAmount: calculateDecimal(totalAmount),
				convertedQuantity: amountIssue * Math.pow(10, 18),
				tier: finalData.tier,
				subtier: finalData2.tier,
				subtier_percentage: finalData2.percentage,
				subaffiliateotc_percentage: affiliate_sub_otc,
				tier_percentage: finalData.percentage,
				affiliateotc_percentage: affiliate_otc,
				affiliate_earning_master:
					cardLoadStore?.affiliateOtc?.affiliate_vip == 1 ||
					cardLoadStore?.affiliateOtc?.affiliate_vip == '1'
						? calculateDecimal3(
								(parseFloat(affiliate_otc) / 100) *
									Number(Pricing(state.token) * amountIssue)
						  )
						: affiliate_otc
						? calculateDecimal3(
								(parseFloat(finalData.percentage) / 100) *
									(parseFloat(affiliate_otc) / 100) *
									Number(Pricing(state.token) * amountIssue)
						  )
						: 0,
				affiliate_earning_sub:
					cardLoadStore?.subaffiliateOtc?.affiliate_vip == 1 ||
					cardLoadStore?.subaffiliateOtc?.affiliate_vip == '1'
						? calculateDecimal3(
								(parseFloat(affiliate_sub_otc) / 100) *
									Number(Pricing(state.token) * amountIssue)
						  )
						: calculateDecimal3(
								(parseFloat(finalData2.percentage) / 100) *
									(parseFloat(affiliate_sub_otc) / 100) *
									Number(Pricing(state.token) * amountIssue)
						  ),
				affiliate_earning_master_name:
					cardLoadStore?.affiliateOtc?.partner_name,
				master_status: 'pending',
				affiliate_earning_sub_name:
					cardLoadStore?.subaffiliateOtc?.partner_name,
				sub_status: cardLoadStore?.subaffiliateOtc?.partner_name
					? 'pending'
					: null,
				userInputAmount: state.total_amount,
			})
			.then(function async(response) {
				if (response.status === 200) {
					if (response.data?.user?.cryptoResponse?.code === -2010) {
						toast.error(response.data?.user?.msg);
					} else if (
						response?.data?.message.includes('Insufficient Wallet Balance')
					) {
						toast.error(response?.data?.message);
					} else {
						try {
							axios
								.post(`${URL}/backOffice/get/cardLoad/feeDistribution`, {
									userAddress: getCookie('metamaskId'),
									amount: Pricing(state.token) * amountIssue,
								})
								.then((res) => {
									if (res.status == 200) {
										toast.success(response?.data?.message);
									} else {
										toast.error(response.data.message);
									}
								});
						} catch (error) {
							toast.error(error.response.data);
						}
					}
					setshowModal(false);
					setEnableQr(false);
					getFinalamount();
				} else {
					toast.error(response.data.message);
					setEnableQr(false);
				}
				setTimeout(() => {
					setDisableBtn(false);
				}, 3000);
			})
			.catch(function (error) {
				setTimeout(() => {
					setDisableBtn(false);
				}, 3000);
				toast.error(error);
				setEnableQr(false);
			});
	};

	const priceHandler = async () => {
		try {
			const response = await fetch(`${URL}/users/binanceprice`, {
				method: 'GET',
				headers: {},
			});
			const data = await response.json();
			if (response.status === 200) {
				data.map((a, b) => {
					if (a.symbol === 'BUSDUSDT') {
						setUSDT(1);
					}
					if (a.symbol === 'USDCUSDT') {
						setUSDC(a);
					}
					if (a.symbol === 'BUSDUSDT') {
						setBUSD(a);
					}
					if (a.symbol === 'BTCUSDT') {
						setBTC(a);
					}
					if (a.symbol === 'AUTOUSDT') {
						setAUTO(a);
					}
					if (a.symbol === 'EPSUSDT') {
						setEPS(a);
					}
					if (a.symbol === 'MBOXUSDT') {
						setMBOX(a);
					}
					if (a.symbol === 'XVSUSDT') {
						setXVS(a);
					}
					if (a.symbol === 'CAKEUSDT') {
						setCAKE(a);
					}

					if (a.symbol === 'ETHUSDT') {
						setETH(a);
					}
					if (a.symbol == 'BNBUSDT') {
						setBnb(a);
						console.log(a.price, 'Bnb ........');
					}
					if (a.symbol == 'MKRUSDT') {
						setMKR(a);
					}
					if (a.symbol == 'CRVUSDT') {
						setCRV(a);
					}
					if (a.symbol == 'CVXUSDT') {
						setCVX(a);
					}
					if (a.symbol == 'INSTUSDT') {
						setINST(a);
					}
					if (a.symbol == 'LDOUSDT') {
						setLDO(a);
					}
					if (a.symbol == 'AAVEUSDT') {
						setAAVE(a);
					}
					if (a.symbol == 'UNIUSDT') {
						setUNI(a);
					}
					if (a.symbol == 'COMPUSDT') {
						setCOMP(a);
					}
					if (a.symbol == 'BALUSDT') {
						setBAL(a);
					}
					if (a.symbol == 'SUSHIUSDT') {
						setSUSHI(a);
					}
					if (a.symbol == 'YFIUSDT') {
						setYFI(a);
					}
					if (a.symbol == 'DAIUSDT') {
						setDAI(a);
					}
				});
			}
		} catch (err) {
		} finally {
		}
	};

	const getSettings = async () => {
		await axios
			.get(`${URL}/users/getBSCOne`, {
				headers: {
					Authorization: getCookie('token'),
				},
			})
			.then(function (response) {
				setCardLoadFee(response.data.card_load_fee);
				setTokenSettingsConstants(response.data.bsc_token_otc_percentage);
				setSettingsConstants(response.data.bsc_stables_otc_per);

				response.data.bsc_token_otc_options.map((a, b) => {
					if (a.label === 'USDT') {
						setSettingsUSDT(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'USDC') {
						setSettingsUSDC(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'BTC') {
						setSettingsBTC(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'AUTO') {
						setSettingsAUTO(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'EPS') {
						setSettingsEPS(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'MBOX') {
						setSettingsMBOX(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'XVS') {
						setSettingsXVS(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'CAKE') {
						setSettingsCAKE(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'BUSD') {
						setSettingsBUSD(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'ETH') {
						setSettingsETH(response.data.bsc_token_otc_percentage);
					}
					if (a.label == 'BNB') {
						setSettingsBNB(response.data.bsc_token_otc_percentage);
					}
				});
				response.data.bsc_stables_otc_options.map((a, b) => {
					if (a.label === 'USDT') {
						setSettingsUSDT(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'USDC') {
						setSettingsUSDC(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'BTC') {
						setSettingsBTC(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'AUTO') {
						setSettingsAUTO(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'EPS') {
						setSettingsEPS(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'MBOX') {
						setSettingsMBOX(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'XVS') {
						setSettingsXVS(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'CAKE') {
						setSettingsCAKE(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'BUSD') {
						setSettingsBUSD(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'ETH') {
						setSettingsETH(response.data.bsc_stables_otc_per);
					}
				});

				JSON.parse(response.data.erc_token_otc_options).map((a, b) => {
					if (a.label === 'MKR') {
						setSettingsMKR(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'CRV') {
						setSettingsCRV(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'CVX') {
						setSettingsCVX(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'LDO') {
						setSettingsLDO(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'AAVE') {
						setSettingsAAVE(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'UNI') {
						setSettingsUNI(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'COMP') {
						setSettingsCOMP(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'BAL') {
						setSettingsBAL(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'INST') {
						setSettingsINST(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'SUSHI') {
						setSettingsSUSHI(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'YFI') {
						setSettingsYFI(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'DAI') {
						setSettingsDAI(response.data.erc_token_otc_percentage);
					}
				});

				JSON.parse(response.data.erc_stables_otc_options).map((a, b) => {
					if (a.label === 'MKR') {
						setSettingsMKR(response.data.erc_stables_otc_per);
					}
					if (a.label === 'CRV') {
						setSettingsCRV(response.data.erc_stables_otc_per);
					}
					if (a.label === 'CVX') {
						setSettingsCVX(response.data.erc_stables_otc_per);
					}
					if (a.label === 'LDO') {
						setSettingsLDO(response.data.erc_stables_otc_per);
					}
					if (a.label === 'AAVE') {
						setSettingsAAVE(response.data.erc_stables_otc_per);
					}
					if (a.label === 'UNI') {
						setSettingsUNI(response.data.erc_stables_otc_per);
					}
					if (a.label === 'COMP') {
						setSettingsCOMP(response.data.erc_stables_otc_per);
					}
					if (a.label === 'BAL') {
						setSettingsBAL(response.data.erc_stables_otc_per);
					}
					if (a.label === 'INST') {
						setSettingsINST(response.data.erc_stables_otc_per);
					}
					if (a.label === 'SUSHI') {
						setSettingsSUSHI(response.data.erc_stables_otc_per);
					}
					if (a.label === 'YFI') {
						setSettingsYFI(response.data.erc_stables_otc_per);
					}
					if (a.label === 'DAI') {
						setSettingsDAI(response.data.erc_stables_otc_per);
					}
				});
			})
			.catch(function (error) {});
	};

	//partner check
	const partnerCheck = async () => {
		const partner =
			getCookie('partnerName') === 'masbank.io' ||
			getCookie('partnerName').includes('backoffice')
				? 'masbank.io'
				: getCookie('partnerName').split('/')[1];

		var config = {
			method: 'get',
			url: `${URL}/users/partner/` + partner,
			headers: {},
		};

		await axios(config)
			.then(function (response) {
				console.log(response.status);
				if (response.status === 200) {
					console.log('called1');
					setPartner(response.data);
				}
				if (response.status === 404) {
					console.log('called2');
					setPartner('');
				}
			})
			.catch(function (error) {
				console.log(error, 'partner');
				if (error.toString().includes('404')) {
					setPartner('');
				}
			});
	};

	//layer 1 pricing    ////    price-
	const valueCalculator = (price, otc, prv) => {
		return price - ((otc * price) / 100 + (prv * price) / 100);
	};

	const layerPricing = (value1) => {
		if (value1 === 'USDT') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(priceUSDT, partner?.partner_otc, partner?.prv_otc)
				);
			} else if (settingsUSDT && settingsUSDT !== 0) {
				return calculateDecimal4(priceUSDT - priceUSDT * (settingsUSDT / 100));
			} else {
				return priceUSDT;
			}
		} else if (value1 === 'USDC') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceUSDC?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsUSDC && settingsUSDC !== 0) {
				return calculateDecimal4(
					priceUSDC?.price - priceUSDC?.price * (settingsUSDC / 100)
				);
			} else {
				return priceUSDC?.price;
			}
		} else if (value1 === 'BTC') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceBTC?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsBTC !== 0) {
				return calculateDecimal4(
					priceBTC?.price - priceBTC?.price * (settingsBTC / 100)
				);
			} else {
				return priceBTC?.price;
			}
		} else if (value1 === 'AUTO') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceAUTO?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsAUTO !== 0) {
				return calculateDecimal4(
					priceAUTO?.price - priceAUTO?.price * (settingsAUTO / 100)
				);
			} else {
				return priceAUTO?.price;
			}
		} else if (value1 === 'MBOX') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceMBOX?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsMBOX !== 0) {
				return calculateDecimal4(
					priceMBOX?.price - priceMBOX?.price * (settingsMBOX / 100)
				);
			} else {
				return priceMBOX?.price;
			}
		} else if (value1 === 'CAKE') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceCAKE?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsCAKE !== 0) {
				return calculateDecimal4(
					priceCAKE?.price - priceCAKE?.price * (settingsCAKE / 100)
				);
			} else {
				return priceCAKE?.price;
			}
		} else if (value1 === 'XVS') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceXVS?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsXVS !== 0) {
				return calculateDecimal4(
					priceXVS?.price - priceXVS?.price * (settingsXVS / 100)
				);
			} else {
				return priceXVS?.price;
			}
		} else if (value1 === 'BUSD') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceBUSD?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsBUSD !== 0) {
				return calculateDecimal4(
					priceBUSD?.price - priceBUSD?.price * (settingsBUSD / 100)
				);
			} else {
				return priceBUSD?.price;
			}
		} else if (value1 === 'ETH') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceETH?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsETH !== 0) {
				return calculateDecimal4(
					priceETH?.price - priceETH?.price * (settingsETH / 100)
				);
			} else {
				return priceETH?.price;
			}
		} else if (value1 === 'BNB') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(bnb?.price, partner?.partner_otc, partner?.prv_otc)
				);
			} else if (settingsBNB !== 0) {
				return calculateDecimal4(bnb?.price - bnb?.price * (settingsBNB / 100));
			} else {
				return bnb?.price;
			}
		} else if (value1 === 'MKR') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceMKR?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsMKR !== 0) {
				return calculateDecimal4(
					priceMKR?.price - priceMKR?.price * (settingsMKR / 100)
				);
			} else {
				return priceMKR?.price;
			}
		} else if (value1 === 'CRV') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceCRV?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsCRV !== 0) {
				return calculateDecimal4(
					priceCRV?.price - priceCRV?.price * (settingsCRV / 100)
				);
			} else {
				return priceMKR?.price;
			}
		} else if (value1 === 'CVX') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceCVX?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsCVX !== 0) {
				return calculateDecimal4(
					priceCVX?.price - priceCVX?.price * (settingsCVX / 100)
				);
			} else {
				return priceCVX?.price;
			}
		} else if (value1 === 'LDO') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceLDO?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsLDO !== 0) {
				return calculateDecimal4(
					priceLDO?.price - priceLDO?.price * (settingsLDO / 100)
				);
			} else {
				return priceLDO?.price;
			}
		} else if (value1 === 'AAVE') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceAAVE?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsAAVE !== 0) {
				return calculateDecimal4(
					priceAAVE?.price - priceAAVE?.price * (settingsAAVE / 100)
				);
			} else {
				return priceAAVE?.price;
			}
		} else if (value1 === 'UNI') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceUNI?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsUNI !== 0) {
				return calculateDecimal4(
					priceUNI?.price - priceUNI?.price * (settingsUNI / 100)
				);
			} else {
				return priceUNI?.price;
			}
		} else if (value1 === 'COMP') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceCOMP?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsCOMP !== 0) {
				return calculateDecimal4(
					priceCOMP?.price - priceCOMP?.price * (settingsCOMP / 100)
				);
			} else {
				return priceCOMP?.price;
			}
		} else if (value1 === 'BAL') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceBAL?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsBAL !== 0) {
				return calculateDecimal4(
					priceBAL?.price - priceBAL?.price * (settingsBAL / 100)
				);
			} else {
				return priceBAL?.price;
			}
		} else if (value1 === 'SUSHI') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceSUSHI?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsSUSHI !== 0) {
				return calculateDecimal4(
					priceSUSHI?.price - priceSUSHI?.price * (settingsSUSHI / 100)
				);
			} else {
				return priceSUSHI?.price;
			}
		} else if (value1 === 'YFI') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceYFI?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsYFI !== 0) {
				return calculateDecimal4(
					priceYFI?.price - priceYFI?.price * (settingsYFI / 100)
				);
			} else {
				return priceYFI?.price;
			}
		} else if (value1 === 'DAI') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceDAI?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsDAI !== 0) {
				return calculateDecimal4(
					priceDAI?.price - priceDAI?.price * (settingsDAI / 100)
				);
			} else {
				return priceDAI?.price;
			}
		} else if (value1 === 'BTCB') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceBTC?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsBTC !== 0) {
				return calculateDecimal4(
					priceBTC?.price - priceBTC?.price * (settingsBTC / 100)
				);
			} else {
				return priceBTC?.price;
			}
		} else if (value1 === 'WBTC') {
			if (parseFloat(partner?.partner_otc) && parseFloat(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceBTC?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsBTC !== 0) {
				return calculateDecimal4(
					priceBTC?.price - priceBTC?.price * (settingsBTC / 100)
				);
			} else {
				return priceBTC?.price;
			}
		} else {
			return 0;
		}
	};

	const Pricing = (value1) => {
		if (value1 === 'USDT') {
			return priceUSDT;
		} else if (value1 === 'USDC') {
			return priceUSDC?.price;
		} else if (value1 === 'BTC') {
			return priceBTC?.price;
		} else if (value1 === 'AUTO') {
			return priceAUTO?.price;
		} else if (value1 === 'MBOX') {
			return priceMBOX?.price;
		} else if (value1 === 'CAKE') {
			return priceCAKE?.price;
		} else if (value1 === 'XVS') {
			return priceXVS?.price;
		} else if (value1 === 'BUSD') {
			return priceBUSD?.price;
		} else if (value1 === 'ETH') {
			return priceETH?.price;
		} else if (value1 === 'BNB') {
			return bnb?.price;
		} else if (value1 === 'MKR') {
			return priceMKR?.price;
		} else if (value1 === 'CRV') {
			return priceMKR?.price;
		} else if (value1 === 'CVX') {
			return priceCVX?.price;
		} else if (value1 === 'LDO') {
			return priceLDO?.price;
		} else if (value1 === 'AAVE') {
			return priceAAVE?.price;
		} else if (value1 === 'UNI') {
			return priceUNI?.price;
		} else if (value1 === 'COMP') {
			return priceCOMP?.price;
		} else if (value1 === 'BAL') {
			return priceBAL?.price;
		} else if (value1 === 'SUSHI') {
			return priceSUSHI?.price;
		} else if (value1 === 'YFI') {
			return priceYFI?.price;
		} else if (value1 === 'DAI') {
			return priceDAI?.price;
		} else if (value1 === 'BTCB') {
			return priceBTC?.price;
		} else if (value1 === 'WBTC') {
			return priceBTC?.price;
		} else {
			return 0;
		}
	};

	function convert(n) {
		var sign = +n < 0 ? '-' : '',
			toStr = n.toString();
		if (!/e/i.test(toStr)) {
			return n;
		}
		var [lead, decimal, pow] = n
			.toString()
			.replace(/^-/, '')
			.replace(/^([0-9]+)(e.*)/, '$1.$2')
			.split(/e|\./);
		return +pow < 0
			? sign +
					'0.' +
					'0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
					lead +
					decimal
			: sign +
					lead +
					(+pow >= decimal.length
						? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
						: decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
	}

	useEffect(() => {
		setAmountIssue(convert(state.total_amount / layerPricing(state.token)));
		setState({
			...state,
			amount: calculateDecimalFor5(
				convert(state.total_amount / layerPricing(state.token))
			),
		});
	}, [layerPricing(state.token)]);

	const handleOnChange = (e, from = false) => {
		if (e.target.name == 'amount') {
			if (e.target.value.toString().split('.')[1]?.length > 5) {
				return;
			}
			setState({
				...state,
				total_amount: calculateDecimal(
					convert(layerPricing(state.token) * e.target.value)
				),
				amount: e.target.value,
			});
		} else {
			if (e.target.value.toString().split('.')[1]?.length > 2 && !from) {
				return;
			}
			setAmountIssue(convert(e.target.value / layerPricing(state.token)));
			setState({
				...state,
				amount: calculateDecimalFor5(
					convert(e.target.value / layerPricing(state.token))
				),
				total_amount: e.target.value,
			});
		}
	};

	const handleMaxButtonClick = () => {
		if (state.token == '') {
			toast.error('Please select token');
			return;
		}

		handleOnChange(
			{
				target: {
					name: 'total_amount',
					value: calculateDecimal(
						(Number(
							balanceData[
								`${
									state.token == 'ETH'
										? 'eth'
										: state.token == 'CAKE'
										? 'Cake'
										: state.token == 'BTCB'
										? 'btcb'
										: state.token == 'BNB'
										? 'bnb'
										: state.token == 'BUSD'
										? 'busd'
										: state.token
								}`
							] || 0
						) /
							10 ** 18) *
							layerPricing(state.token)
					),
				},
			},
			true
		);
	};

	return (
		<>
			{localStorage.getItem('metamaskId') && localStorage.getItem('token') ? (
				''
			) : (
				<HeaderNavigator />
			)}
			<div className=' dashboard'>
				<div className='container py-4' style={{ maxWidth: '1500px' }}>
					{loading ? (
						<div className='row' style={{ height: '500px' }}>
							<div className='col-12 text-center my-auto d-flex justify-content-center'>
								<div className='m-auto'>
									<ClockLoader color='white' size={86} override={override} />
								</div>
							</div>
						</div>
					) : (
						<div className='row '>
							<div className='col-lg-7 mb-lg-0 mb-4'>
								<div className='card z-index-2'>
									<div className='card-body p-6 pt-4'>
										<h6 className='ms-2 mb-0 text-center h-white headtext mb-3'>
											MAScap Wallet Balance
										</h6>
										<div className='row'>
											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img src={USDT} width='40px' />
													</div>
													<p style={{ color: 'white' }}>USDT </p>
													<h4 className='price'>
														{calculateHoleDec(
															web3.utils.fromWei(
																convert(balanceData?.USDT || 0).toString(),
																'ether'
															)
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.USDT
																			? convert(balanceData?.USDT / 10 ** 18)
																			: 0) * layerPricing('USDT')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>
											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img src={USDC} width='40px' />
													</div>
													<p style={{ color: 'white' }}>USDC</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateHoleDec(
																	web3.utils.fromWei(
																		convert(balanceData?.USDC || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.USDC?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.USDC?.toFixed(2)
																			? web3.utils.fromWei(
																					convert(
																						balanceData?.USDC || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('USDC')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>
											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img src={BTC} width='40px' />
													</div>
													<p style={{ color: 'white' }}>BTCB </p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateHoleDec(
																	web3.utils.fromWei(
																		parseInt(balanceData?.btcb || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.btcb?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.btcb?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.btcb || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('BTC')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img src={BTC} width='40px' />
													</div>
													<p style={{ color: 'white' }}>WBTC </p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateHoleDec(
																	web3.utils.fromWei(
																		parseInt(balanceData?.WBTC || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.btcb?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.WBTC?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.WBTC || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('WBTC')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>
											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img src={AUTO} width='40px' />
													</div>
													<p style={{ color: 'white' }}>AUTO </p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateHoleDec(
																	web3.utils.fromWei(
																		parseInt(balanceData?.AUTO || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.AUTO?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.AUTO?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.AUTO || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('AUTO')
																	)
																).toString() + ' USD' || '0 USD'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											{/* <div
                          className={
                            localStorage.getItem('metamaskId') &&
                            localStorage.getItem('token')
                              ? 'col-md-3 p-2 pt-4'
                              : 'col-md-3 p-2 pt-4 reduce_opacity'
                          }
                        >
                          <div className='wbtc wbtc-w'>
                            <div className='brificon'>
                              <img src={EPS} width='40px' />
                            </div>
                            <p>EPS </p>
                            <h4 className='price'>
                              {localStorage.getItem('metamaskId') &&
                              localStorage.getItem('token') ? (
                                <>
                                  {calculateHoleDec(
                                    web3.utils.fromWei(
                                      parseInt(balanceData?.EPS || 0).toString(),
                                      'ether'
                                    )
                                  )} */}
											{/* {balanceData?.EPS?.toFixed(2) || "0"} */}
											{/* </>
                              ) : (
                                0
                              )}
                            </h4>
                            <h4
                              className='price'
                              style={{ color: 'white', fontSize: '18px' }}
                            >
                              {localStorage.getItem('metamaskId') &&
                              localStorage.getItem('token') ? (
                                <>
                                  {calculateDecimal(
                                    convert(
                                      (balanceData?.EPS?.toFixed(2)
                                        ? web3.utils.fromWei(
                                            parseInt(
                                              balanceData?.EPS || 0
                                            ).toString(),
                                            'ether'
                                          )
                                        : 0) * layerPricing('EPS')
                                    )
                                  ).toString() + ' USD' || '0'}
                                </>
                              ) : (
                                0
                              )}
                            </h4>
                          </div>
                        </div> */}

											{/* <div className={(localStorage.getItem("metamaskId") && localStorage.getItem("token")) ? 'col-md-3 p-2 pt-4' : "col-md-3 p-2 pt-4 reduce_opacity"}>
                        <div className="wbtc wbtc-w">
                          <div className="brificon">
                            <img src={MBOX} width="40px" />
                          </div>
                          <p>MBOX </p>
                          <h4 className="price">
                            
                            {(localStorage.getItem("metamaskId") && localStorage.getItem("token")) ?
                              <>
                                {calculateHoleDec(web3.utils.fromWei(parseInt(balanceData?.MBOX || 0).toString(), 'ether'))}
                               
                              </>
                              : 0}
                          </h4>
                          <h4
                            className="price"
                            style={{ color: "white", fontSize: "18px" }}
                          >
                            {(localStorage.getItem("metamaskId") && localStorage.getItem("token")) ?
                              <>
                                {calculateDecimal(convert(
                                  (balanceData?.MBOX
                                    ? (web3.utils.fromWei(parseInt(balanceData?.MBOX || 0).toString(), 'ether'))
                                    : 0) *
                                  layerPricing("MBOX")
                                )).toString() + " USD" || "0"}</> : 0}
                          </h4>
                        </div>
                      </div> */}

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img src={XVS} width='40px' />
													</div>
													<p style={{ color: 'white' }}>XVS </p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateHoleDec(
																	web3.utils.fromWei(
																		parseInt(balanceData?.XVS || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.XVS?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.XVS
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.XVS || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('XVS')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>
											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img src={CAKE} width='40px' />
													</div>
													<p style={{ color: 'white' }}>CAKE </p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateHoleDec(
																	web3.utils.fromWei(
																		parseInt(balanceData?.Cake || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.Cake?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.Cake
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.Cake || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('CAKE')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img src={BUSD} width='40px' />
													</div>
													<p style={{ color: 'white' }}>BUSD </p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateHoleDec(
																	web3.utils.fromWei(
																		convert(balanceData?.busd || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.busd?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.busd
																			? web3.utils.fromWei(
																					convert(
																						balanceData?.busd || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('BUSD')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img src={ETH} width='40px' />
													</div>
													<p style={{ color: 'white' }}>ETH </p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateHoleDec(
																	web3.utils.fromWei(
																		parseInt(balanceData?.eth || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.eth?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.eth || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('ETH')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>
											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w'>
													<div className='brificon'>
														<img
															src={
																'https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png'
															}
															width='40px'
														/>
													</div>
													<p style={{ color: 'white' }}>BNB </p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateHoleDec(
																	web3.utils.fromWei(
																		parseInt(balanceData?.bnb || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.bnb?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.bnb || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('BNB')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>
											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img
															src={
																'https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png'
															}
															width='40px'
														/>
													</div>
													<p style={{ color: 'white' }}>
														MKR{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.MKR || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(Number(balanceData?.MKR)?.toFixed(2)
																			? web3.utils.fromWei(
																					convert(
																						Number(balanceData?.MKR) || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('MKR')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img
															src={
																'https://s2.coinmarketcap.com/static/img/coins/64x64/6538.png'
															}
															width='40px'
														/>
													</div>
													<p style={{ color: 'white' }}>
														CRV{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.CRV || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.CRV?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.CRV || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('CRV')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img src={CVX} width='40px' />
													</div>
													<p style={{ color: 'white' }}>
														CVX{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.CVX || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.CVX?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.CVX || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('CVX')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img src={LDO} width='40px' />
													</div>
													<p style={{ color: 'white' }}>
														LDO{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.LDO || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.LDO?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.LDO || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('LDO')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img src={AAVE} width='40px' />
													</div>
													<p style={{ color: 'white' }}>
														AAVE{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.AAVE || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.AAVE?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.AAVE || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('AAVE')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img src={UNI} width='40px' />
													</div>
													<p style={{ color: 'white' }}>
														UNI{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.UNI || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.UNI?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.UNI || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('UNI')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img src={COMP} width='40px' />
													</div>
													<p style={{ color: 'white' }}>
														COMP{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.COMP || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.COMP?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.COMP || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('COMP')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img src={BAL} width='40px' />
													</div>
													<p style={{ color: 'white' }}>
														BAL{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.BAL || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.BAL?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.BAL || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('BAL')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											{/* <div
                          className={
                            localStorage.getItem('metamaskId') &&
                            localStorage.getItem('token')
                              ? 'col-md-3 p-2 pt-4'
                              : 'col-md-3 p-2 pt-4 reduce_opacity'
                          }
                        >
                          <div className='wbtc wbtc-w cursor-pointer'>
                            <div className='brificon'>
                              <img src={INST} width='40px' />
                            </div>
                            <p>
                              INST{' '}
                              {/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
											{/* </p>
                            <h4 className='price'>
                              {localStorage.getItem('metamaskId') &&
                              localStorage.getItem('token') ? (
                                <>
                                  {calculateDecimalFor8(
                                    web3.utils.fromWei(
                                      parseInt(balanceData?.INST || 0).toString(),
                                      'ether'
                                    )
                                  )} */}
											{/* {balanceData?.eth?.toFixed(2) || "0"} */}
											{/* </>
                              ) : (
                                0
                              )}
                            </h4>
                            <h4
                              className='price'
                              style={{ color: 'white', fontSize: '18px' }}
                            >
                              {localStorage.getItem('metamaskId') &&
                              localStorage.getItem('token') ? (
                                <>
                                  {calculateDecimal(
                                    convert(
                                      (balanceData?.INST?.toFixed(2)
                                        ? web3.utils.fromWei(
                                            parseInt(
                                              balanceData?.INST || 0
                                            ).toString(),
                                            'ether'
                                          )
                                        : 0) * layerPricing('INST')
                                    )
                                  ).toString() + ' USD' || '0'}
                                </>
                              ) : (
                                0
                              )}
                            </h4>
                          </div>
                        </div>  */}

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img src={SUSHI} width='40px' />
													</div>
													<p style={{ color: 'white' }}>
														SUSHI{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(
																			balanceData?.SUSHI || 0
																		).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.SUSHI?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.SUSHI || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('SUSHI')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img
															src={
																'https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png'
															}
															width='40px'
														/>
													</div>
													<p style={{ color: 'white' }}>
														YFI{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.YFI || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.YFI?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.YFI || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('YFI')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>

											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-3 p-2 pt-4'
														: 'col-md-3 p-2 pt-4 reduce_opacity'
												}>
												<div className='wbtc wbtc-w cursor-pointer'>
													<div className='brificon'>
														<img src={DAI} width='40px' />
													</div>
													<p style={{ color: 'white' }}>
														DAI{' '}
														{/* <i
                              className="fa fa-arrow-down icon1 icon-red"
                              aria-hidden="true"
                            ></i> */}
													</p>
													<h4 className='price'>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimalFor8(
																	web3.utils.fromWei(
																		parseInt(balanceData?.DAI || 0).toString(),
																		'ether'
																	)
																)}
																{/* {balanceData?.eth?.toFixed(2) || "0"} */}
															</>
														) : (
															0
														)}
													</h4>
													<h4
														className='price'
														style={{ color: 'white', fontSize: '18px' }}>
														{localStorage.getItem('metamaskId') &&
														localStorage.getItem('token') ? (
															<>
																{calculateDecimal(
																	convert(
																		(balanceData?.DAI?.toFixed(2)
																			? web3.utils.fromWei(
																					parseInt(
																						balanceData?.DAI || 0
																					).toString(),
																					'ether'
																			  )
																			: 0) * layerPricing('DAI')
																	)
																).toString() + ' USD' || '0'}
															</>
														) : (
															0
														)}
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-5 mb-lg-0 mb-4 '>
								<div className='card z-index-2'>
									<div className='card-body  pt-4 pb-0 mobile-card'>
										<div
											id={
												localStorage.getItem('metamaskId') &&
												localStorage.getItem('token')
													? ''
													: 'overlay'
											}></div>
										<h6 className='ms-2 mb-0 text-center h-white headtext mb-3'>
											Load Card
										</h6>
										<div className='row justify-content-center '>
											<div
												className={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? 'col-md-12  p-4 pt-3 mb-0'
														: 'col-md-12  p-4 pt-3 mb-0 reduce_opacity'
												}>
												<div className='  ' style={{ marginTop: '60px' }}>
													<form>
														<div
															className={
																disableForm === true
																	? 'wbtc gray row pb-3 mb-4 disableform1'
																	: 'wbtc gray row pb-3 mb-4 '
															}
															// style={{ backgroundColor: "#3C4043" }}
														>
															<div className='col'>
																<label
																	for='exampleFormControlInput1'
																	className={
																		disableForm === true ? '' : 'disableform'
																	}>
																	Token Asset
																</label>
																<select
																	className='form-control'
																	value={state.token}
																	id='exampleFormControlSelect1'
																	placeholder='Choose'
																	disabled={disableForm === true ? true : false}
																	onChange={(e) => {
																		if (e.target.value === 'Choose') {
																			setState({ ...state, token: '' });
																			return;
																		}
																		setState({
																			...state,
																			token: e.target.value,
																			amount: '',
																			total_amount: '',
																		});
																	}}>
																	{/* {
                                      localStorage.getItem("currentChain")?.toLowerCase() == "eth" ? <> */}

																	<option>Choose</option>
																	<option>USDT</option>
																	<option>USDC</option>
																	<option>BUSD</option>

																	<option>MKR</option>
																	<option>CVX</option>
																	<option>LDO</option>
																	<option>AAVE</option>
																	<option>UNI</option>
																	<option>COMP</option>
																	{/* <option>INST</option> */}
																	<option>BAL</option>
																	<option>SUSHI</option>
																	<option>YFI</option>
																	<option>DAI</option>
																	<option>WBTC</option>
																	<option>CRV</option>
																	<option>BTCB</option>
																	<option>AUTO</option>
																	{/* <option>EPS</option> */}
																	<option>XVS</option>
																	<option>CAKE</option>

																	<option>ETH</option>
																	<option>BNB</option>

																	{/* </> :
                                        <>
                                          <option>Choose</option>
                                          <option>USDT</option>
                                          <option>USDC</option>
                                         
                                        </>} */}
																</select>
															</div>
															<div className='change-width'></div>
															<div className='col'>
																<label
																	for='exampleFormControlInput2'
																	className={
																		disableForm === true ? '' : 'disableform'
																	}>
																	Token Amount
																</label>
																<input
																	type='number'
																	value={state.amount}
																	disabled={true}
																	className='form-control'
																	id='amount'
																	placeholder='Amount'
																	name='amount'
																	onChange={handleOnChange}
																/>
															</div>
															<div className='w-100'></div>
															<div className='col pt-1 pb-0'>
																<label
																	for='Name'
																	className={
																		disableForm === true ? '' : 'disableform'
																	}>
																	Total Amount (USD)
																</label>
																<div className='d-flex'>
																	<input
																		type='number'
																		value={state.total_amount}
																		className='form-control'
																		disabled={
																			disableForm === true ? true : false
																		}
																		name='total_amount'
																		id='Name'
																		placeholder='Minimum load is 100 USD'
																		onChange={handleOnChange}
																	/>
																	<button
																		type='button'
																		className='bg-white'
																		style={{
																			borderRadius: '9px',
																			backgroundColor: 'white !important',
																			border: 'none',
																			marginLeft: '10px',
																		}}
																		onClick={handleMaxButtonClick}
																		disabled={
																			disableForm === true ? true : false
																		}>
																		Max
																	</button>
																</div>
															</div>
														</div>
														<div className=' justify-content-center text-center pt-2 pb-0'>
															<button
																type='button'
																className='btn btns'
																disabled={disableForm === true ? true : false}
																onClick={loadCardSubmit}>
																CONFIRM
															</button>
														</div>
														<div style={{ marginTop: '70px' }}></div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<Modal show={showModal} onHide={hideModal}>
				<Modal.Header>
					<Modal.Title>
						Confirm card load with selected crypto asset type and amount
					</Modal.Title>
					{/* <Button
            variant="danger"
            onClick={() => {
              setshowModal(false);
            }}
          >
            <i class="fas fa-times"></i>
          </Button> */}
				</Modal.Header>
				<Modal.Body>
					<div className='alert d-flex flex-column'>
						{' '}
						<span>Token Amount : {state.amount}</span>
						<span>Applicable card load fee applies.</span>
					</div>
				</Modal.Body>
				{!disableBtn && (
					<Modal.Footer>
						<Button
							variant='danger'
							onClick={() => {
								setshowModal(false);
								setEnableQr(false);
							}}>
							Cancel
						</Button>
						<Button
							variant='success'
							onClick={() => {
								setDisableBtn(true);
								confirmCardLoad();
							}}>
							Confirm
						</Button>
					</Modal.Footer>
				)}
			</Modal>

			<Modal show={showModal2} onHide={hideModal2}>
				<Modal.Header>
					<Modal.Title>Verify</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='alert d-flex flex-column align-items-center justify-content-center'>
						{!enableQr && (
							<span>
								Enter the 6-Digit code given by the Google Authenticator
								Application.
							</span>
						)}
						{enableQr && (
							<div className='d-flex flex-column'>
								<span
									className='font-bold'
									style={{ fontWeight: 'bold', fontSize: '20px' }}>
									Setup Two Factor Authentication
								</span>
								<span>
									1. Install the Google Authenticator application on your
									Mobile.
								</span>
								<span>
									{window.innerWidth <= 600
										? 'Enter the secret in '
										: 'Scan this QR Code via '}
									the Google Authenticator App.
								</span>
								<span>
									3. Enter the 6-Digit code given by the Google Authenticator
									Application.
								</span>
							</div>
						)}
						<div style={{ margin: '30px 50px' }}>
							{enableQr ? (
								window.innerWidth <= 600 ? (
									<span
										style={{
											color: 'black',
											fontSize: '12px',
										}}>
										{faData?.secret?.base32}
									</span>
								) : (
									<img src={faData?.qrcodes} style={{ width: '200px' }} />
								)
							) : null}
						</div>
						{enableQr && window.innerWidth <= 600 && (
							<button
								onClick={() => copyToClipboard(faData?.secret?.base32)}
								style={{
									border: 'none',
									backgroundColor: '#4CAF50',
									color: 'white',
									padding: '5px 20px',
									fontSize: '12px',
									borderRadius: '5px',
									marginTop: '10px',
								}}>
								Copy
							</button>
						)}
						{/* <span>OR</span> */}
						<input
							placeholder='Enter otp'
							type={'number'}
							onChange={(e) => {
								setenteredOTP(e.target.value);
							}}
							style={{ marginTop: '20px' }}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='danger'
						onClick={() => {
							setshowModal2(false);
						}}>
						Cancel
					</Button>
					<Button
						variant='success'
						onClick={() => {
							update2FA();
						}}>
						Confirm
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
