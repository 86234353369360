import { useEffect, React, useState, useContext } from 'react';
import './Home.scss';
import RData from '../../Sass/img/R.png';
import { URL } from '../../Utils/url';
import LogoIcon from '../../Sass/img/Logoicon.svg';
import USDT from '../../Sass/img/USDT.png';
import USDC from '../../Sass/img/USDC.png';
import BTC from '../../Sass/img/BTC.png';
import AUTO from '../../Sass/img/AUTO.png';
import ABI from '../../abi.json';
import ABIEther from '../../abiether.json';
import ABIEther2 from '../../abiether2.json';
import ABIXDC from '../../abixdc.json';
import WBTCJ from '../../WBTC.json';
import getWeb3 from '../../Utils/getWeb3';
import EPS from '../../Sass/img/EPS.png';
import MBOX from '../../Sass/img/MBOX.png';
import XVS from '../../Sass/img/XVS.png';
import CAKE from '../../Sass/img/CAKE.png';
import BUSD from '../../Sass/img/BUSD.png';
import ETH from '../../Sass/img/ETH.png';
import CRV from '../../Sass/img/Curve.png';
import MKR from '../../Sass/img/MKR.png';
import CVX from '../../Sass/img/convex.png';
import LDO from '../../Sass/img/lido.png';
import AAVE from '../../Sass/img/aave.png';
import UNI from '../../Sass/img/uniswap.png';
import COMP from '../../Sass/img/compound.png';
import INST from '../../Sass/img/instadapp.png';
import BAL from '../../Sass/img/balancer.png';
import SUSHI from '../../Sass/img/sushiswap.png';
import YEARN from '../../Sass/img/yearn.png';
import DAI from '../../Sass/img/dai.png';
import AVAX from '../../Sass/img/avalanche.png';
import WBTC from '../../Sass/img/wrapedbtc.png';
import WETH from '../../Sass/img/wrappedeth.png';
import GBEX from '../../Sass/img/globiance.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../../Utils/cookieHandling';
import HeaderNavigator from '../../Components/Header/HeaderNavigator';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ethers } from 'ethers';
import { BigNumber as BN } from 'bignumber.js';
import BSCMetamaskBalance from './BSCMetamaskBalance';

export default function EtherMetamaskBalance() {
	const depositAddress = '0xd515860b0cb092bebc57927a14c0f50616e3c2d7';
	const [currentBalance, setCurrentBalance] = useState('');
	const history = useHistory();
	const roleContext = useContext(UserRoleContext);
	const [partner, setPartner] = useState(null);
	const [wbtc, setWbtc] = useState([]);
	const [eth, setEth] = useState([]);
	const [busd, setBusd] = useState([]);
	const [bnb, setBnb] = useState(null);
	const [bal, setbalance] = useState(0);
	const [balanceData, newBalanceData] = useState(null);
	const [hideModal, sethideModal] = useState(false);
	const [priceUSDT, setUSDT] = useState(null);
	const [priceUSDC, setUSDC] = useState(null);
	const [priceBTC, setBTC] = useState(null);
	const [priceAUTO, setAUTO] = useState(null);
	const [priceEPS, setEPS] = useState(null);
	const [priceMBOX, setMBOX] = useState(null);
	const [priceXVS, setXVS] = useState(null);
	const [priceCAKE, setCAKE] = useState(null);
	const [priceBUSD, setBUSD] = useState(null);
	const [priceETH, setETH] = useState(null);
	const [priceMKR, setMKR] = useState(null);
	const [priceCRV, setCRV] = useState(null);
	const [priceCVX, setCVX] = useState(null);
	const [priceLDO, setLDO] = useState(null);
	const [priceAAVE, setAAVE] = useState(null);
	const [priceUNI, setUNI] = useState(null);
	const [priceCOMP, setCOMP] = useState(null);
	const [priceBAL, setBAL] = useState(null);
	const [priceINST, setINST] = useState(null);
	const [priceSUSHI, setSUSHI] = useState(null);
	const [priceYFI, setYFI] = useState(null);
	const [priceDAI, setDAI] = useState(null);
	const [cardLoadFee, setCardLoadFee] = useState(null);
	const [wallletBalance, setWalletBalance] = useState(null);
	const [amount, setAmount] = useState(null);

	const [web3n, setWeb3] = useState('');
	const networks = {
		bsc: {
			chainId: `0x${Number(56).toString(16)}`,
			chainName: 'Binance Smart Chain Mainnet',
			nativeCurrency: {
				name: 'Binance Chain Native Token',
				symbol: 'BNB',
				decimals: 18,
			},
			rpcUrls: ['https://bsc-dataseed1.binance.org'],
			blockExplorerUrls: ['https://bscscan.com'],
		},
	};
	const changeNetwork = async ({ networkName }) => {
		try {
			if (!window.ethereum) throw new Error('No crypto wallet found');
			await window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [
					{
						...networks[networkName],
					},
				],
			});
		} catch (err) {}
	};
	const handleNetworkSwitch = async (networkName) => {
		await changeNetwork({ networkName });
	};
	const checktoWhitelist2 = () => {
		axios
			.get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('metamaskId')}`)
			.then(function (response) {
				if (response.status === 200) {
					roleContext.updateContext({ affiliate: response.data.affiliate });
					roleContext.updateContext({ card_type: response.data.card_type });
					roleContext.updateContext({
						jdbCardNumber1: response.data.jdbCardNumber1,
					});
					roleContext.updateContext({
						card_activated: response.data.card_activated,
					});
					var now;
					let modifiedStatus = false;
					if (response.data.stakedate != null) {
						now = new Date(response.data.stakedate);
						now.setMinutes(now.getMinutes() + 30);
						now = new Date(now);
					}

					if (new Date() > now) {
						modifiedStatus = true;
					}

					roleContext.updateContext({ staking: response.data.staking });
					roleContext.updateContext({ stakingTime: modifiedStatus });
					roleContext.updateContext({
						approveStacking: response.data.stakeapprove,
					});

					if (response.data.card_applied == 1) {
						roleContext.updateContext({ card_applied: true });
					}

					if (response.data.kycStatus == '1' || response.data.kycStatus == 1) {
						roleContext.updateContext({ card_purchase: true });
					}
					if (response.data.card_activated == 2) {
						roleContext.updateContext({
							card_activated: response.data.card_activated,
						});
					}
					if (response.data.cardStatus == 'paid') {
						roleContext.updateContext({ card_status: true });
					}

					if (!roleContext.isLoggedIn) {
						return;
					}
					if (response.data.card_applied) {
						return;
					}

					if (
						response.data.cardStatus == null ||
						response.data.cardStatus != 'paid'
					) {
						history.push('/cardpurchase');
					} else {
						history.push('/card-form');
					}
				}
			})
			.catch(function (error) {});
	};
	useEffect(() => {
		// alert(roleContext.card_applied)
		checktoWhitelist2();
	}, []);
	const [contract, setContract] = useState('');
	const [tokenArray, setTokenArray] = useState([
		'USDT',
		'EPS',
		'BUSD',
		'USDC',
		'MBOX',
		'ETH',
		'BTCB',
		'XVS',
		'BNB',
		'AUTO',
		'CAKE',
	]);

	const [metamaskBalance, setMetamaskBalance] = useState([]);
	const calculateMetamaskBalance = async () => {
		let tempArr = [];
		tokenArray.map(async (a, b) => {
			const index = initialData.findIndex((data) => data.token === a);
			let contractAddress = '';
			if (index != -1) {
				contractAddress = addressesArray[index].address;
			}

			const web3 = await getWeb3();
			let instance;
			if (a.toUpperCase() == 'ETH') {
				instance = new web3.eth.Contract(ABIEther, contractAddress);
			} else if (a.toUpperCase() == 'BNB') {
				web3.eth.getBalance(getCookie('metamaskId')).then((balance) => {
					tempArr.push({
						token: a,
						balance: balance / Math.pow(10, 18),
					});
					// setMetamaskBalance({ ...metamaskBalance, "BNB": web3.utils.fromWei(parseInt(balance || 0).toString(), 'ether') });
				});
			} else {
				instance = new web3.eth.Contract(ABI, contractAddress);
			}
			if (a.toUpperCase() != 'BNB') {
				const balanceConst = await instance.methods
					.balanceOf(getCookie('metamaskId'))
					.call();
				tempArr.push({
					token: a,
					balance: balanceConst / Math.pow(10, 18),
				});
				// setMetamaskBalance({ ...metamaskBalance, a: web3.utils.fromWei(parseInt(balanceConst || 0).toString(), 'ether')});
			}
		});

		setMetamaskBalance(tempArr);
	};

	const initialData = [
		{
			address: '0x55d398326f99059ff775485246999027b3197955',
			token: 'USDT',
		},
		{
			address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
			token: 'USDC',
		},
		{
			address: '0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
			token: 'MBOX',
		},
		{
			address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
			token: 'CAKE',
		},

		{
			address: '0xa184088a740c695e156f91f5cc086a06bb78b827',
			token: 'AUTO',
		},
		{
			address: '0xa7f552078dcc247c2684336020c03648500c6d9f',
			token: 'EPS',
		},
		{
			address: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
			token: 'XVS',
		},
		{
			address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
			token: 'BTCB',
		},
		{
			address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
			token: 'ETH',
		},
		{
			address: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
			token: 'BNB',
		},
	];

	const addressesArrayData = [
		{
			address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
			token: 'USDT',
		},
		{
			address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
			token: 'USDC',
		},

		{
			address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
			token: 'WBTC',
			image: BTC,
		},

		{
			address: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
			token: 'MKR',
		},
		{
			address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
			token: 'CRV',
		},
		{
			address: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
			token: 'CVX',
		},
		{
			address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
			token: 'LDO',
		},
		{
			address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
			token: 'AAVE',
		},

		{
			address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
			token: 'UNI',
		},

		{
			address: '0xc00e94cb662c3520282e6f5717214004a7f26888',
			token: 'COMP',
		},

		// {
		//   address: '0x6f40d4A6237C257fff2dB00FA0510DeEECd303eb',
		//   token: 'INST'
		// },

		{
			address: '0xba100000625a3754423978a60c9317c58a424e3D',
			token: 'BAL',
		},

		{
			address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
			token: 'SUSHI',
		},
		{
			address: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
			token: 'YFI',
		},
		{
			address: '0x6b175474e89094c44da98b954eedeac495271d0f',
			token: 'DAI',
		},
	];
	const [addressesArray, setAddressesArray] = useState(initialData);
	const convertToBigNumber = (val) => {
		return ethers.utils.parseEther(val.toString()).toString();
	};
	const convertFromBigNumber = (val) => {
		return ethers.utils.formatEther(val.toString()).toString();
	};
	const calculateDecimal = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,2})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};

	const calculateDecimal4 = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,4})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};

	const calculateDecimalFor8 = (value) => {
		if (value != undefined && value != null) {
			var num = value;
			var with5Decimals = num.toString().match(/^-?\d+(?:\.\d{0,8})?/)[0];
			return with5Decimals;
		}
		return 0;
	};

	const calculateDecimalFor3 = (value) => {
		if (value != undefined && value != null) {
			var num = value;
			var with5Decimals = num.toString().match(/^-?\d+(?:\.\d{0,})?/)[0];
			return with5Decimals;
		}
		return 0;
	};
	const [state, setState] = useState({
		token: '',
		amount: '',
		total_amount: '',
	});
	// API for coingeeko
	useEffect(() => {
		if (localStorage.getItem('metamaskId') && localStorage.getItem('token')) {
			getBalanceHandler();
			priceHandler();
			calculateDecimal(20);
			setInterval(function () {
				priceHandler();
				getBalanceHandler();
			}, 3000);
		}
	}, []);

	const contractCall = async () => {
		let amountConst = state.amount;

		const web3 = await getWeb3();
		let contractAddress;
		let value;

		const index = (
			localStorage.getItem('currentChain').toLowerCase() == 'eth'
				? addressesArrayData
				: addressesArray
		).findIndex((data) => data.token === state.token);

		if (index !== -1) {
			contractAddress =
				localStorage.getItem('currentChain').toLowerCase() == 'eth'
					? addressesArrayData[index].address
					: addressesArray[index].address;
		}

		if (
			(state.token === 'USDT' || state.token === 'USDC') &&
			localStorage.getItem('currentChain').toLowerCase() === 'eth'
		) {
			value = amountConst * Math.pow(10, 6);
		} else if (
			state.token === 'WBTC' &&
			localStorage.getItem('currentChain').toLowerCase() === 'eth'
		) {
			value = (amountConst / Math.pow(10, 8)) * Math.pow(10, 16);
		} else {
			value = web3.utils.toWei(amountConst.toString(), 'ether');
		}

		let instance;
		if (state.token == 'ETH') {
			console.log('contractAddress', contractAddress);
			instance = new web3.eth.Contract(ABIEther, contractAddress);
			console.log(instance);
		} else {
			instance = new web3.eth.Contract(ABI, contractAddress);
		}
		setWeb3(web3);
		if (state.token == 'BNB') {
			value = web3.utils.toWei(amountConst.toString(), 'ether');
			axios
				.post(
					`${URL}/users/initiateCardPayment`,
					{
						amount: value,
						userAddress: getCookie('metamaskId'),
						chainId: localStorage.getItem('currentChain').toUpperCase(),
						assetType: state.token,
					},
					{
						headers: {
							Authorization: getCookie('token'),
						},
					}
				)
				.then(async (res) => {
					if (res.status === 200 || res.status === 201) {
						const txData = {
							from: getCookie('metamaskId'),
							to: depositAddress,
							value: web3.utils.toHex(value),
						};
						window.ethereum
							.request({
								method: 'eth_sendTransaction',
								params: [txData],
							})
							.then((txHash) => {
								// updateUserBalanceHandler(state.token, amountConst)
								calculateMetamaskBalance();
							})
							.catch((error) => {
								toast.error(error?.message);
							});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else if (
			state.token === 'ETH' &&
			localStorage.getItem('currentChain').toLowerCase() === 'eth'
		) {
			value = web3.utils.toWei(amountConst.toString(), 'ether');
			axios
				.post(
					`${URL}/users/initiateCardPayment`,
					{
						amount: value,
						userAddress: getCookie('metamaskId'),
						chainId: localStorage.getItem('currentChain').toUpperCase(),
						assetType: state.token,
					},
					{
						headers: {
							Authorization: getCookie('token'),
						},
					}
				)
				.then(async (res) => {
					if (res.status == 200 || res.status == 201) {
						const txData = {
							from: getCookie('metamaskId'),
							to: depositAddress,
							value: web3.utils.toHex(value),
						};
						window.ethereum
							.request({
								method: 'eth_sendTransaction',
								params: [txData],
							})
							.then((txHash) => {
								// updateUserBalanceHandler(state.token, amountConst)
								calculateMetamaskBalance();
							})
							.catch((error) => {
								toast.error(error?.message);
							});
					}
				})
				.catch((err) => {
					console.log(err);
				});
			return;
		} else {
			axios
				.post(
					`${URL}/users/initiateCardPayment`,
					{
						amount: value,
						userAddress: getCookie('metamaskId'),
						chainId: localStorage.getItem('currentChain').toUpperCase(),
						assetType: state.token,
					},
					{
						headers: {
							Authorization: getCookie('token'),
						},
					}
				)
				.then(async (res) => {
					if (res.status == 200 || res.status == 201) {
						if (localStorage.getItem('currentChain').toLowerCase() == 'eth') {
							instance = new web3.eth.Contract(ABIEther2, contractAddress);
						}

						await instance.methods
							.transfer(depositAddress, value)
							.send({ from: getCookie('metamaskId') })
							.then((res) => {
								//updateUserBalanceHandler(state.token, amountConst)
								calculateMetamaskBalance();
							})
							.catch((error) => {
								toast.error(error.message);
							});
					}
				})
				.catch((err) => {
					console.log(err);
				});
			return;
		}
	};
	const copyAddressToClipboard = () => {
		// axios
		//   .get(`${URL}/users/getDepositAddress`, {
		//     headers: {
		//       Authorization: getCookie("token"),
		//     },
		//   })
		//   .then(function (response) {
		//     if (response.status === 200) {
		//       navigator.clipboard.writeText(response.data.address);
		//       toast.success("Address copied to clipboard");
		//     } else {
		//       toast.error(response.data.message);
		//     }
		//   })
		//   .catch(function (error) {
		//     toast.success(error);
		//   });
	};

	//
	useEffect(() => {
		if (sessionStorage.getItem('balance')) {
			setbalance(sessionStorage.getItem('balance'));
		}
	}, [sessionStorage.getItem('balance')]);

	const getBalanceHandler = async () => {
		axios
			.get(`${URL}/users/walletBalance/${getCookie('metamaskId')}`, {
				headers: {
					Authorization: getCookie('token'),
				},
			})
			.then(function (response) {
				if (response.status === 200) {
					newBalanceData(response.data);
				} else {
					toast.error(response.data.message);
				}
			})
			.catch(function (error) {
				toast.success(error);
			});
	};

	const priceHandler = async () => {
		try {
			const response = await fetch(`${URL}/users/binanceprice`, {
				method: 'GET',
				headers: {},
			});
			const data = await response.json();
			if (response.status === 200) {
				data.map((a, b) => {
					if (a.symbol === 'BUSDUSDT') {
						setUSDT(1);
					}
					if (a.symbol === 'USDCUSDT') {
						setUSDC(a);
					}
					if (a.symbol === 'BTCUSDT') {
						setBTC(a);
					}
					if (a.symbol === 'AUTOUSDT') {
						setAUTO(a);
					}
					if (a.symbol === 'EPSUSDT') {
						setEPS(a);
					}
					if (a.symbol === 'MBOXUSDT') {
						setMBOX(a);
					}
					if (a.symbol === 'XVSUSDT') {
						setXVS(a);
					}
					if (a.symbol === 'CAKEUSDT') {
						setCAKE(a);
					}
					if (a.symbol === 'BUSDUSDT') {
						setBUSD(a);
					}
					if (a.symbol === 'ETHUSDT') {
						setETH(a);
					}
					if (a.symbol == 'BNBUSDT') {
						setBnb(a);
					}
					if (a.symbol == 'MKRUSDT') {
						setMKR(a);
					}
					if (a.symbol == 'CRVUSDT') {
						setCRV(a);
					}
					if (a.symbol == 'CVXUSDT') {
						setCVX(a);
					}
					if (a.symbol == 'INSTUSDT') {
						setINST(a);
					}
					if (a.symbol == 'LDOUSDT') {
						setLDO(a);
					}
					if (a.symbol == 'AAVEUSDT') {
						setAAVE(a);
					}
					if (a.symbol == 'UNIUSDT') {
						setUNI(a);
					}
					if (a.symbol == 'COMPUSDT') {
						setCOMP(a);
					}
					if (a.symbol == 'BALUSDT') {
						setBAL(a);
					}
					if (a.symbol == 'SUSHIUSDT') {
						setSUSHI(a);
					}
					if (a.symbol == 'YFIUSDT') {
						setYFI(a);
					}
					if (a.symbol == 'DAIUSDT') {
						setDAI(a);
					}
				});
			}
		} catch (err) {
		} finally {
		}
	};

	const layerPricing = (value1) => {
		if (value1 === 'USDT') {
			return 1;
		} else if (value1 === 'USDC') {
			return priceUSDC?.price;
		} else if (value1 === 'BTC') {
			return priceBTC?.price;
		} else if (value1 === 'AUTO') {
			return priceAUTO?.price;
		} else if (value1 === 'MBOX') {
			return priceMBOX?.price;
		} else if (value1 === 'CAKE') {
			return priceCAKE?.price;
		} else if (value1 === 'XVS') {
			return priceXVS?.price;
		} else if (value1 === 'BUSD') {
			return priceBUSD?.price;
		} else if (value1 === 'ETH') {
			return priceETH?.price;
		} else if (value1 === 'EPS') {
			return priceEPS?.price;
		} else if (value1 === 'BNB') {
			return bnb?.price;
		} else if (value1 === 'MKR') {
			return priceMKR?.price;
		} else if (value1 === 'CRV') {
			return priceCRV?.price;
		} else if (value1 === 'CVX') {
			return priceCVX?.price;
		} else if (value1 === 'LDO') {
			return priceLDO?.price;
		} else if (value1 === 'AAVE') {
			return priceAAVE?.price;
		} else if (value1 === 'UNI') {
			return priceUNI?.price;
		} else if (value1 === 'COMP') {
			return priceCOMP?.price;
		} else if (value1 === 'BAL') {
			return priceBAL?.price;
		} else if (value1 === 'INST') {
			return priceINST?.price;
		} else if (value1 === 'SUSHI') {
			return priceSUSHI?.price;
		} else if (value1 === 'YFI') {
			return priceYFI?.price;
		} else if (value1 === 'WBTC') {
			return priceBTC?.price;
		} else if (value1 === 'DAI') {
			return priceDAI?.price;
		} else {
			return 0;
		}
	};

	function convert(n) {
		var sign = +n < 0 ? '-' : '',
			toStr = n.toString();
		if (!/e/i.test(toStr)) {
			return n;
		}
		var [lead, decimal, pow] = n
			.toString()
			.replace(/^-/, '')
			.replace(/^([0-9]+)(e.*)/, '$1.$2')
			.split(/e|\./);
		return +pow < 0
			? sign +
					'0.' +
					'0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
					lead +
					decimal
			: sign +
					lead +
					(+pow >= decimal.length
						? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
						: decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
	}

	const handleMaxButtonClick = async () => {
		if (state.token == '') {
			toast.error('Please select token');
			return;
		}
		let tokenValue = state.token;
		const web3 = await getWeb3();
		var accs = await web3.eth.getAccounts();
		// alert(e.target.value)
		if (localStorage.getItem('currentChain')?.toLowerCase() == 'eth') {
			if (
				localStorage.getItem('currentChain')?.toLowerCase() === 'eth' &&
				tokenValue === 'ETH'
			) {
				web3.eth.getBalance(getCookie('metamaskId')).then((balance) => {
					setCurrentBalance(balance / Math.pow(10, 18));
					setState({
						...state,
						amount: calculateDecimal4(balance / Math.pow(10, 18)),
					});
				});

				return;
			}

			const newAccounts = await Promise.all(
				accs.map(async (address) => {
					const tokenInst = new web3.eth.Contract(
						ABIEther2,
						addressesArrayData[
							addressesArrayData.findIndex((item) => item.token === tokenValue)
						].address
					);

					const balance = await tokenInst.methods.balanceOf(address).call();
					// alert(balance)
					return {
						balance,
					};
				})
			);

			if (
				localStorage.getItem('currentChain')?.toLowerCase() === 'eth' &&
				(tokenValue === 'USDT' || tokenValue === 'USDC')
			) {
				setCurrentBalance(newAccounts[0].balance / Math.pow(10, 6));
				setState({
					...state,
					amount: calculateDecimal4(newAccounts[0].balance / Math.pow(10, 6)),
				});
				return;
			}
			if (
				localStorage.getItem('currentChain')?.toLowerCase() === 'eth' &&
				tokenValue === 'WBTC'
			) {
				setCurrentBalance(newAccounts[0].balance / Math.pow(10, 8));
				setState({
					...state,
					amount: calculateDecimal4(newAccounts[0].balance / Math.pow(10, 8)),
				});
				return;
			}

			setCurrentBalance(
				web3.utils.fromWei(
					parseInt(newAccounts[0].balance || 0).toString(),
					'ether'
				)
			);
			setState({
				...state,
				amount: calculateDecimal4(
					web3.utils.fromWei(
						parseInt(newAccounts[0].balance || 0).toString(),
						'ether'
					)
				),
			});

			return;
		}
		const index = initialData.findIndex((data) => data.token === tokenValue);

		let contractAddress = '';
		if (index != -1) {
			contractAddress = addressesArray[index].address;
		}

		setState({ ...state, token: tokenValue });

		let instance;
		if (tokenValue == 'ETH') {
			instance = new web3.eth.Contract(ABIEther, contractAddress);
		} else if (tokenValue == 'BNB') {
			web3.eth.getBalance(getCookie('metamaskId')).then((balance) => {
				setCurrentBalance(
					web3.utils.fromWei(parseInt(balance || 0).toString(), 'ether')
				);
				setState({
					...state,
					amount: calculateDecimal4(
						web3.utils.fromWei(parseInt(balance || 0).toString(), 'ether')
					),
				});
				return;
			});
			return;
		} else if (tokenValue == 'GBEX') {
			instance = new web3.eth.Contract(ABIXDC, contractAddress);
		} else {
			instance = new web3.eth.Contract(ABIEther, contractAddress);
		}
		const balanceConst = await instance.methods
			.balanceOf(getCookie('metamaskId'))
			.call();

		setCurrentBalance(
			web3.utils.fromWei(
				convert(parseInt(balanceConst || 0)).toString(),
				'ether'
			)
		);
		setState({
			...state,
			amount: calculateDecimal4(
				web3.utils.fromWei(
					convert(parseInt(balanceConst || 0)).toString(),
					'ether'
				)
			),
		});
	};

	const [balances, setbalances] = useState([]);
	const [ethBalance, setEthbalance] = useState(null);

	useEffect(async () => {
		setInterval(async () => {
			const web3 = await getWeb3();

			web3.eth.getBalance(getCookie('metamaskId')).then((balance) => {
				setEthbalance(balance / Math.pow(10, 18));
			});

			var accs = await web3.eth.getAccounts();

			const newAccounts = await Promise.all(
				accs.map(async (address) => {
					const balance = await web3.eth.getBalance(address);

					const tokenBalances = await Promise.all(
						[
							{
								address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
								token: 'USDC',
								image: USDC,
							},
							{
								address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
								token: 'USDT',
								image: USDT,
							},
							{
								address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
								token: 'WBTC',
								image: BTC,
							},

							{
								address: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
								token: 'MKR',
								image: MKR,
							},
							{
								address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
								token: 'CRV',
								image: CRV,
							},
							{
								address: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
								token: 'CVX',
								image: CVX,
							},
							{
								address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
								token: 'LDO',
								image: LDO,
							},
							{
								address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
								token: 'AAVE',
								image: AAVE,
							},

							{
								address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
								token: 'UNI',
								image: UNI,
							},

							{
								address: '0xc00e94cb662c3520282e6f5717214004a7f26888',
								token: 'COMP',
								image: COMP,
							},

							// {
							//     address: '0x6f40d4A6237C257fff2dB00FA0510DeEECd303eb',
							//     token: 'INST',
							//     image: INST
							// },

							{
								address: '0xba100000625a3754423978a60c9317c58a424e3D',
								token: 'BAL',
								image: BAL,
							},

							{
								address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
								token: 'SUSHI',
								image: SUSHI,
							},
							{
								address: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
								token: 'YFI',
								image:
									'https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png',
							},
							{
								address: '0x6b175474e89094c44da98b954eedeac495271d0f',
								token: 'DAI',
								image: DAI,
							},
						].map(async (token) => {
							const tokenInst = new web3.eth.Contract(
								token.token == 'WBTC' ? WBTCJ : ABIEther2,
								token.address
							);

							const balance = await tokenInst.methods.balanceOf(address).call();

							return {
								token: token.token,
								balance:
									token.token === 'USDT' || token.token === 'USDC'
										? balance / Math.pow(10, 6)
										: token.token === 'WBTC'
										? balance / Math.pow(10, 8)
										: web3.utils.fromWei(
												parseInt(balance || 0).toString(),
												'ether'
										  ),
								image: token.image,
							};
						})
					);

					return tokenBalances;
				})
			);
			setbalances(newAccounts[0]);
			console.log(newAccounts[0]);
		}, 3000);
	}, []);
	return (
		<div className='row'>
			{balances?.map((balance, index) => {
				return (
					<div
						className={
							localStorage.getItem('metamaskId') &&
							localStorage.getItem('token')
								? 'col-md-3 p-2 pt-4'
								: 'col-md-3 p-2 pt-4 reduce_opacity'
						}>
						<div className='wbtc wbtc-w'>
							<div className='brificon'>
								<img src={balance.image} width='40px' />
							</div>
							<p>{balance.token} </p>
							<h4 className='price' style={{ fontSize: '15px' }}>
								<>{calculateDecimalFor8(balance.balance)}</>
							</h4>
							<h4 className='price'>
								$
								{layerPricing(balance?.token) ? (
									<>
										{calculateDecimal(
											convert(
												parseFloat(layerPricing(balance?.token)) *
													balance.balance
											)
										)}
									</>
								) : (
									0
								)}
							</h4>
						</div>
					</div>
				);
			})}

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={ETH} width='40px' />
					</div>
					<p>{'ETH'} </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						{calculateDecimalFor8(ethBalance)}

						<>
							{/* { calculateDecimalFor8((
                                    
                  )) } */}
						</>
					</h4>
					<h4 className='price'>
						$
						{layerPricing('ETH') ? (
							<>
								{calculateDecimal(
									convert(parseFloat(layerPricing('ETH')) * ethBalance)
								)}
							</>
						) : (
							0
						)}
					</h4>
				</div>
			</div>
		</div>
	);
}
