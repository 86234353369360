import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import copy from '../../Sass/img/copy_address.png';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { UserRoleContext } from '../../Utils/UserAuthorization';
export default function AffiliateTable({ listData }) {
	const roleContext = useContext(UserRoleContext);
	const location = useLocation();
	const useStyles = makeStyles({
		root: {
			width: '100%',
		},
		container: {
			maxHeight: 500,
		},
		cell_long: {
			fontSize: '10px',
			width: 700,
			minWidth: 1,
			backgroundColor: '#ee82ee',
		},
	});
	const [currentTab, setCurrentTab] = React.useState(1);
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [page2, setPage2] = React.useState(0);
	const [rowsPerPage2, setRowsPerPage2] = React.useState(10);

	const myTheme = createMuiTheme({
		// Theme settings
		palette: {
			type: 'dark',
		},
	});
	const handleChangePage2 = (event, newPage) => {
		setPage2(newPage);
	};

	const handleChangeRowsPerPage2 = (event) => {
		setRowsPerPage2(+event.target.value);
		setPage2(0);
	};
	const calculateDecimal = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,2})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};
	const copyAddressToClipboard = async (add) => {
		navigator.clipboard.writeText(add);
		toast.success('Address copied to clipboard');
	};
	function convert(n) {
		var sign = +n < 0 ? '-' : '',
			toStr = n.toString();
		if (!/e/i.test(toStr)) {
			return n;
		}
		var [lead, decimal, pow] = n
			.toString()
			.replace(/^-/, '')
			.replace(/^([0-9]+)(e.*)/, '$1.$2')
			.split(/e|\./);
		return +pow < 0
			? sign +
					'0.' +
					'0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
					lead +
					decimal
			: sign +
					lead +
					(+pow >= decimal.length
						? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
						: decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
	}
	const calculateDecimalFor8 = (value) => {
		if (value != undefined) {
			var num = value;
			var with5Decimals = num.toString().match(/^-?\d+(?:\.\d{0,5})?/)[0];
			return with5Decimals;
		}
		return 0;
	};

	console.log(window.location.href, 'nsdvjn');
	return (
		<div class='aff-container'>
			<div class='affiliate-table-container'>
				<div id='example_wrapper' class='dataTables_wrapper'>
					{true ? (
						<div>
							<ThemeProvider theme={myTheme}>
								<TableContainer
									className={`table-res-mobile ${classes.container}`}
									style={{
										background: 'transparent',
										boxShadow: 'none',
									}}>
									<Table
										stickyHeader
										aria-label='sticky table'
										style={{
											background: 'transparent',
											boxShadow: 'none',
										}}>
										<TableHead className='head'>
											<TableRow
												style={{
													background: 'black !important',
												}}>
												{/* {columns.map((column) => ( */}
												<TableCell

												// key={column.id}
												// align={column.align}
												>
													S/N
												</TableCell>
												<TableCell>Name</TableCell>
												<TableCell>Email</TableCell>
												<TableCell>User Address</TableCell>
												{/* <TableCell>
                                            Partner Fee
                                          </TableCell> */}
												<TableCell>UpperLine Referral Link</TableCell>
												<TableCell>upperLineLevel</TableCell>

												<TableCell>referralLink</TableCell>

												<TableCell>createdAt</TableCell>

												{/* ))} */}
											</TableRow>
										</TableHead>
										<TableBody className='tablebody'>
											{listData ? (
												listData
													.slice(
														page2 * rowsPerPage2,
														page2 * rowsPerPage2 + rowsPerPage2
													)
													.map((row, index) => (
														<TableRow
															key={index}
															//  className="tableRow"
														>
															<TableCell
																style={{ overflow: 'auto' }}
																//  style={customColumnStyle}
															>
																{index + 1 || 'N/A'}
															</TableCell>
															<TableCell style={{ overflow: 'auto' }}>
																{row.name}
															</TableCell>
															<TableCell style={{ overflow: 'hidden' }}>
																{row.email || 'N/A'}
															</TableCell>

															<TableCell style={{ overflow: 'auto' }}>
																{row.userAddress.slice(0, 4) +
																	'...' +
																	row.userAddress.substr(
																		row.userAddress.length - 4
																	) || 'N/A'}
																<img
																	className='cursor-pointer'
																	style={{ marginLeft: '10px' }}
																	src={copy}
																	width='20px'
																	alt='copy'
																	title='Copy'
																	onClick={() => {
																		copyAddressToClipboard(row.userAddress);
																	}}
																/>
															</TableCell>

															<TableCell
																style={{
																	textAlign: 'start',
																	overflow: 'auto',
																}}>
																{row.upperLineReferralLink || 'N/A'}
															</TableCell>

															<TableCell
																style={{ textAlign: 'end', overflow: 'auto' }}>
																{row.upperLineLevel || 'N/A'}
															</TableCell>
															<TableCell
																style={{ textAlign: 'end', overflow: 'auto' }}>
																{row.referralLink || 'N/A'}
															</TableCell>
															<TableCell style={{ overflow: 'auto' }}>
																{moment(row.createdAt).format('DD/MM/YYYY')}
															</TableCell>
														</TableRow>
													))
											) : (
												<h5
													style={{
														width: '100%',
														marginLeft: '200%',
														color: 'red',
													}}>
													No Data To Display
												</h5>
											)}
										</TableBody>
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[]}
									component='div'
									count={listData ? listData.length : 0}
									rowsPerPage={rowsPerPage}
									page={page2}
									onChangePage={handleChangePage2}
									onChangeRowsPerPage={handleChangeRowsPerPage2}
								/>
							</ThemeProvider>
						</div>
					) : (
						<center
							style={{
								marginTop: '40px',
								marginLeft: '30vw',
							}}
							className='no-data'>
							No card load history
						</center>
					)}
				</div>
			</div>
		</div>
	);
}
