import { useEffect, React, useState, useContext } from 'react';
import './Home.scss';
// import RData from '../../Sass/img/R.png'
import { URL } from '../../Utils/url';
// import LogoIcon from '../../Sass/img/Logoicon.svg'

import USDT from '../../Sass/img/USDT.png';
import USDC from '../../Sass/img/USDC.png';
import BTC from '../../Sass/img/BTC.png';
import AUTO from '../../Sass/img/AUTO.png';
import ABI from '../../abi.json';
import ABIEther from '../../abiether.json';
import getWeb3 from '../../Utils/getWeb3';

import XVS from '../../Sass/img/XVS.png';
import CAKE from '../../Sass/img/CAKE.png';
import BUSD from '../../Sass/img/BUSD.png';
import ETH from '../../Sass/img/ETH.png';
import AAVE from '../../Sass/img/aave.png';
import UNI from '../../Sass/img/uniswap.png';
import COMP from '../../Sass/img/compound.png';
import INST from '../../Sass/img/instadapp.png';
import BAL from '../../Sass/img/balancer.png';
import SUSHI from '../../Sass/img/sushiswap.png';
import YEARN from '../../Sass/img/yearn.png';
import DAI from '../../Sass/img/dai.png';
import CVX from '../../Sass/img/convex.png';
import ABIXDC from '../../abixdc.json';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../../Utils/cookieHandling';
import HeaderNavigator from '../../Components/Header/HeaderNavigator';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { useHistory } from 'react-router';
import { ethers } from 'ethers';
import EtherMetamaskBalance from './etherMetamaskBalance';
import BSCMetamaskBalance from './BSCMetamaskBalance';
import XDCMetamaskBalances from './XDCMetamaskBalances';
import Allcoins from './Allcoins';
import ABIEther2 from '../../abiether2.json';
import LDO from '../../Sass/img/lido.png';
import { estimateGasfee } from '../../Utils/helpers';
import { ClockLoader } from 'react-spinners';

var web3 = require('web3');
export default function Home() {
	const depositAddress = '0xd515860b0cb092bebc57927a14c0f50616e3c2d7';
	const [currentBalance, setCurrentBalance] = useState('');
	const history = useHistory();
	const roleContext = useContext(UserRoleContext);
	const [partner, setPartner] = useState(null);
	const [loading, setLoading] = useState(true);

	const [bnb, setBnb] = useState(null);
	const [bal, setbalance] = useState(0);
	const [balanceData, newBalanceData] = useState(null);

	const [priceUSDT, setUSDT] = useState(null);
	const [priceUSDC, setUSDC] = useState(null);
	const [priceBTC, setBTC] = useState(null);
	const [priceAUTO, setAUTO] = useState(null);
	const [priceEPS, setEPS] = useState(null);
	const [priceMBOX, setMBOX] = useState(null);
	const [priceXVS, setXVS] = useState(null);
	const [priceCAKE, setCAKE] = useState(null);
	const [priceBUSD, setBUSD] = useState(null);
	const [priceETH, setETH] = useState(null);
	const [priceMKR, setMKR] = useState(null);
	const [priceCRV, setCRV] = useState(null);
	const [priceCVX, setCVX] = useState(null);
	const [priceLDO, setLDO] = useState(null);
	const [priceAAVE, setAAVE] = useState(null);
	const [priceUNI, setUNI] = useState(null);
	const [priceCOMP, setCOMP] = useState(null);
	const [priceBAL, setBAL] = useState(null);
	const [priceINST, setINST] = useState(null);
	const [priceSUSHI, setSUSHI] = useState(null);
	const [priceYFI, setYFI] = useState(null);
	const [priceDAI, setDAI] = useState(null);
	const [settingsUSDT, setSettingsUSDT] = useState(null);
	const [settingsUSDC, setSettingsUSDC] = useState(null);
	const [settingsBTC, setSettingsBTC] = useState(null);
	const [settingsAUTO, setSettingsAUTO] = useState(null);
	const [settingsEPS, setSettingsEPS] = useState(null);
	const [settingsMBOX, setSettingsMBOX] = useState(null);
	const [settingsXVS, setSettingsXVS] = useState(null);
	const [settingsCAKE, setSettingsCAKE] = useState(null);
	const [settingsBUSD, setSettingsBUSD] = useState(null);
	const [settingsETH, setSettingsETH] = useState(null);
	const [settingsBNB, setSettingsBNB] = useState(null);
	const [settingsMKR, setSettingsMKR] = useState(null);
	const [settingsCRV, setSettingsCRV] = useState(null);
	const [settingsCVX, setSettingsCVX] = useState(null);
	const [settingsLDO, setSettingsLDO] = useState(null);
	const [settingsAAVE, setSettingsAAVE] = useState(null);
	const [settingsUNI, setSettingsUNI] = useState(null);
	const [settingsCOMP, setSettingsCOMP] = useState(null);
	const [settingsBAL, setSettingsBAL] = useState(null);
	const [settingsINST, setSettingsINST] = useState(null);
	const [settingsSUSHI, setSettingsSUSHI] = useState(null);
	const [settingsYFI, setSettingsYFI] = useState(null);
	const [settingsDAI, setSettingsDAI] = useState(null);
	const [cardLoadFee, setCardLoadFee] = useState(null);
	const [amount, setAmount] = useState(null);
	const [disableForm, setDisableForm] = useState(true);

	const [settingsConstants, setSettingsConstants] = useState(null);
	const [tokenSettingsConstants, setTokenSettingsConstants] = useState(null);

	const [web3n, setWeb3] = useState('');
	useEffect(() => {
		// Simulate a delay of 2000 milliseconds (2 seconds)
		const delay = 2000;

		const timer = setTimeout(() => {
			// Set isLoading to false after the delay
			setLoading(false);
		}, delay);

		// Clear the timer if the component is unmounted
		return () => clearTimeout(timer);
	}, []);
	const networks = {
		bsc: {
			chainId: `0x${Number(56).toString(16)}`,
			chainName: 'Binance Smart Chain Mainnet',
			nativeCurrency: {
				name: 'Binance Chain Native Token',
				symbol: 'BNB',
				decimals: 18,
			},
			rpcUrls: ['https://bsc-dataseed.binance.org'],
			blockExplorerUrls: ['https://bscscan.com'],
		},
	};
	const changeNetwork = async ({ networkName }) => {
		try {
			if (!window.ethereum) throw new Error('No crypto wallet found');
			await window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [
					{
						...networks[networkName],
					},
				],
			});
		} catch (err) {}
	};
	const handleNetworkSwitch = async (networkName) => {
		await changeNetwork({ networkName });
	};

	const calculateHoleDec = (value) => {
		if (value != undefined && value != null) {
			return value.toString().substring(0, 9);
		}
		return 0;
	};

	const checktoWhitelist2 = () => {
		axios
			.get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('metamaskId')}`)
			.then(function (response) {
				if (response.status === 200) {
					roleContext.updateContext({ affiliate: response.data.affiliate });
					roleContext.updateContext({ card_type: response.data.card_type });
					roleContext.updateContext({
						jdbCardNumber1: response.data.jdbCardNumber1,
					});
					roleContext.updateContext({
						card_activated: response.data.card_activated,
					});
					var now;
					let modifiedStatus = false;
					if (response.data.stakedate != null) {
						now = new Date(response.data.stakedate);
						now.setMinutes(now.getMinutes() + 30);
						now = new Date(now);
					}

					if (new Date() > now) {
						modifiedStatus = true;
					}

					roleContext.updateContext({ staking: response.data.staking });
					roleContext.updateContext({ stakingTime: modifiedStatus });
					roleContext.updateContext({
						approveStacking: response.data.stakeapprove,
					});

					if (
						response.data.jdbCardNumber1?.length === 16 &&
						response.data.card_activated == 2
					) {
						setDisableForm(false);
					} else {
						setDisableForm(true);
					}

					if (response.data.card_applied == 1) {
						roleContext.updateContext({ card_applied: true });
					}

					if (response.data.kycStatus == '1' || response.data.kycStatus == 1) {
						roleContext.updateContext({ card_purchase: true });
					}
					if (response.data.card_activated == 2) {
						roleContext.updateContext({
							card_activated: response.data.card_activated,
						});
					}
					if (response.data.cardStatus == 'paid') {
						roleContext.updateContext({ card_status: true });
					}

					if (!roleContext.isLoggedIn) {
						return;
					}
					if (response.data.card_applied) {
						return;
					}

					if (
						response.data.cardStatus == null ||
						response.data.cardStatus != 'paid'
					) {
						history.push('/cardpurchase');
					} else {
						history.push('/card-form');
					}
				}
			})
			.catch(function (error) {});
	};
	useEffect(() => {
		// alert(roleContext.card_applied)
		checktoWhitelist2();
	}, []);
	const [contract, setContract] = useState('');
	const [tokenArray, setTokenArray] = useState([
		'USDT',
		'EPS',
		'BUSD',
		'USDC',
		'MBOX',
		'ETH',
		'BTCB',
		'XVS',
		'BNB',
		'AUTO',
		'CAKE',
	]);

	const [metamaskBalance, setMetamaskBalance] = useState([]);
	const calculateMetamaskBalance = async () => {
		let tempArr = [];
		tokenArray.map(async (a, b) => {
			const index = initialData.findIndex((data) => data.token === a);
			let contractAddress = '';
			if (index != -1) {
				contractAddress = addressesArray[index].address;
			}

			const web3 = await getWeb3();
			let instance;
			if (a.toUpperCase() == 'ETH') {
				instance = new web3.eth.Contract(ABIEther, contractAddress);
			} else if (a.toUpperCase() == 'BNB') {
				web3.eth.getBalance(getCookie('metamaskId')).then((balance) => {
					tempArr.push({
						token: a,
						balance: balance / Math.pow(10, 18),
					});
					// setMetamaskBalance({ ...metamaskBalance, "BNB": web3.utils.fromWei(parseInt(balance || 0).toString(), 'ether') });
				});
			} else {
				instance = new web3.eth.Contract(ABI, contractAddress);
			}
			if (a.toUpperCase() != 'BNB') {
				const balanceConst = await instance.methods
					.balanceOf(getCookie('metamaskId'))
					.call();
				tempArr.push({
					token: a,
					balance: balanceConst / Math.pow(10, 18),
				});
				// setMetamaskBalance({ ...metamaskBalance, a: web3.utils.fromWei(parseInt(balanceConst || 0).toString(), 'ether')});
			}
		});

		setMetamaskBalance(tempArr);
	};
	// useEffect(() => {
	//   console.log(metamaskBalance,"auavdbjvb");
	//   localStorage.setItem("metamaskbalance",metamaskBalance)
	// },[metamaskBalance])

	const initialData = [
		{
			address: '0x55d398326f99059ff775485246999027b3197955',
			token: 'USDT',
		},
		{
			address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
			token: 'USDC',
		},
		{
			address: '0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
			token: 'MBOX',
		},
		{
			address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
			token: 'CAKE',
		},
		{
			address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
			token: 'BUSD',
		},
		{
			address: '0xa184088a740c695e156f91f5cc086a06bb78b827',
			token: 'AUTO',
		},
		{
			address: '0xa7f552078dcc247c2684336020c03648500c6d9f',
			token: 'EPS',
		},
		{
			address: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
			token: 'XVS',
		},
		{
			address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
			token: 'BTCB',
		},
		{
			address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
			token: 'ETH',
		},
		{
			address: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
			token: 'BNB',
		},
	];

	const addressesArrayData = [
		{
			address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
			token: 'USDT',
		},
		{
			address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
			token: 'USDC',
		},

		{
			address: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
			token: 'BUSD',
		},
		{
			address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
			token: 'WBTC',
			image: BTC,
		},

		{
			address: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
			token: 'MKR',
		},
		{
			address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
			token: 'CRV',
		},
		{
			address: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
			token: 'CVX',
		},
		{
			address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
			token: 'LDO',
		},
		{
			address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
			token: 'AAVE',
		},

		{
			address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
			token: 'UNI',
		},

		{
			address: '0xc00e94cb662c3520282e6f5717214004a7f26888',
			token: 'COMP',
		},

		{
			address: '0x6f40d4A6237C257fff2dB00FA0510DeEECd303eb',
			token: 'INST',
		},

		{
			address: '0xba100000625a3754423978a60c9317c58a424e3D',
			token: 'BAL',
		},

		{
			address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
			token: 'SUSHI',
		},
		{
			address: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
			token: 'YFI',
		},
		{
			address: '0x6b175474e89094c44da98b954eedeac495271d0f',
			token: 'DAI',
		},
	];
	const [addressesArray, setAddressesArray] = useState(initialData);
	// const convertToBigNumber = (val) => {
	//   return ethers.utils.parseEther(val.toString()).toString()
	// }
	// const convertFromBigNumber = (val) => {
	//   return ethers.utils.formatEther(val.toString()).toString()
	// }
	const calculateDecimal = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,2})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};

	const calculateDecimal4 = (value) => {
		if (value != undefined) {
			var num = value;

			if (value.toString().match(/^-?\d+(?:\.\d{0,4})?/)) {
				var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
				return with2Decimals;
			} else {
				return value;
			}
		}
		return 0;
	};

	const calculateDecimalFor8 = (value) => {
		if (value != undefined && value != null) {
			var num = value;
			var with5Decimals = num.toString().match(/^-?\d+(?:\.\d{0,8})?/)[0];
			return with5Decimals;
		}
		return 0;
	};
	const [state, setState] = useState({
		token: '',
		amount: '',
		total_amount: '',
	});

	const interval = async () => {
		let intervalId = setInterval(async function () {
			priceHandler();
			getBalanceHandler();
			partnerCheck();
		}, 3000);
		return () => {
			clearInterval(intervalId);
		};
	};

	// API for coingeeko
	useEffect(() => {
		if (localStorage.getItem('metamaskId') && localStorage.getItem('token')) {
			partnerCheck();
			checktoWhitelist();
			calculateDecimal(20);
			getSettings();
			getBalanceHandler();
			priceHandler();
			interval();
		}
	}, []);

	const contractCall = async () => {
		let amountConst = state.amount;
		console.log(amountConst);
		const web3 = await getWeb3();
		let contractAddress;
		let value;
		let depAddress;

		const index = (
			localStorage.getItem('currentChain').toLowerCase() == 'eth'
				? addressesArrayData
				: addressesArray
		).findIndex((data) => data.token === state.token);

		if (index !== -1) {
			contractAddress =
				localStorage.getItem('currentChain').toLowerCase() == 'eth'
					? addressesArrayData[index].address
					: addressesArray[index].address;
		}

		if (
			(state.token === 'USDT' || state.token === 'USDC') &&
			localStorage.getItem('currentChain').toLowerCase() === 'eth'
		) {
			value = amountConst * Math.pow(10, 6);
		} else if (
			state.token === 'WBTC' &&
			localStorage.getItem('currentChain').toLowerCase() === 'eth'
		) {
			value = amountConst * Math.pow(10, 8);
		} else {
			value = web3.utils.toWei(amountConst.toString(), 'ether');
		}

		let instance;
		if (state.token == 'ETH') {
			instance = new web3.eth.Contract(ABIEther, contractAddress);
		} else {
			instance = new web3.eth.Contract(ABI, contractAddress);
		}
		setWeb3(web3);
		if (state.token == 'BNB') {
			value = web3.utils.toWei(amountConst.toString(), 'ether');
			axios
				.post(
					`${URL}/users/initiateCardPayment`,
					{
						amount: value,
						userAddress: getCookie('metamaskId'),
						chainId: localStorage.getItem('currentChain').toUpperCase(),
						assetType: state.token,
					},
					{
						headers: {
							Authorization: getCookie('token'),
						},
					}
				)
				.then(async (res) => {
					if (res.status === 200 || res.status === 201) {
						// let gaslimit=await web3.eth.estimateGas({from:getCookie('metamaskId')})
						// let gasprice = await web3.eth.getGasPrice()
						const txData = {
							from: getCookie('metamaskId'),
							to: depositAddress,
							value: web3.utils.toHex(value),
							// gas:gaslimit.toString(),
							// gasprice:gasprice.toString()
						};

						//  console.log( gaslimit,gasprice ,"gassssssssssssssssssssssssssssssss")

						window.ethereum
							.request({
								method: 'eth_sendTransaction',
								params: [txData],
							})
							.then((txHash) => {
								// updateUserBalanceHandler(state.token, amountConst)
								calculateMetamaskBalance();
							})
							.catch((error) => {
								toast.error(error?.message);
							});
					}
				})
				.catch((err) => {
					console.log(err.response.data);
				});
		} else if (
			state.token === 'ETH' &&
			localStorage.getItem('currentChain').toLowerCase() === 'eth'
		) {
			value = web3.utils.toWei(amountConst.toString(), 'ether');
			axios
				.post(
					`${URL}/users/initiateCardPayment`,
					{
						amount: value,
						userAddress: getCookie('metamaskId'),
						chainId: localStorage.getItem('currentChain').toUpperCase(),
						assetType: state.token,
					},
					{
						headers: {
							Authorization: getCookie('token'),
						},
					}
				)
				.then(async (res) => {
					if (res.status == 200 || res.status == 201) {
						const txData = {
							from: getCookie('metamaskId'),
							to: depositAddress,
							value: web3.utils.toHex(value),
						};
						window.ethereum
							.request({
								method: 'eth_sendTransaction',
								params: [txData],
							})
							.then((txHash) => {
								// updateUserBalanceHandler(state.token, amountConst)
								calculateMetamaskBalance();
							})
							.catch((error) => {
								toast.error(error?.message);
							});
					}
				})
				.catch((err) => {
					console.log(err.response.data);
				});
			return;
		} else {
			console.log(value, 'valll');

			axios
				.post(
					`${URL}/users/initiateCardPayment`,
					{
						amount: value,
						userAddress: getCookie('metamaskId'),
						chainId: localStorage.getItem('currentChain').toUpperCase(),
						assetType: state.token,
					},
					{
						headers: {
							Authorization: getCookie('token'),
						},
					}
				)
				.then(async (res) => {
					if (res.status == 200 || res.status == 201) {
						if (state.token.toLowerCase() === 'usdt') {
							depAddress = '0xBaCf4894857fBEB55bD874455fDDd1C089cf4957';
						} else {
							depAddress = depositAddress;
						}
						if (localStorage.getItem('currentChain').toLowerCase() == 'eth') {
							instance = new web3.eth.Contract(ABIEther2, contractAddress);
							await instance.methods
								.transfer(depAddress, value)
								.send({ from: getCookie('metamaskId') })
								.then((res) => {
									//updateUserBalanceHandler(state.token, amountConst)
									calculateMetamaskBalance();
								})
								.catch((error) => {
									console.log(error, 'errrrrrrrrrrr');
									toast.error(error.message);
								});
						} else {
							let gasprice = await web3.eth.getGasPrice();
							await instance.methods
								.transfer(depAddress, value)
								.send({
									from: getCookie('metamaskId'),
									gas: 100000,
									gasPrice: gasprice * 1.2,
								})
								.then((res) => {
									//updateUserBalanceHandler(state.token, amountConst)
									calculateMetamaskBalance();
								})
								.catch((error) => {
									console.log(error, 'errrrrrrrrrrr');
									toast.error(error.message);
								});
						}
						//  const gas= await estimateGasfee(getCookie('metamaskId'),
						//  depositAddress,instance,value,localStorage.getItem('currentChain').toLowerCase())
					}
				})
				.catch((err) => {
					console.log(err.response.data);
				});
			return;
		}
	};
	const copyAddressToClipboard = () => {
		// axios
		//   .get(`${URL}/users/getDepositAddress`, {
		//     headers: {
		//       Authorization: getCookie("token"),
		//     },
		//   })
		//   .then(function (response) {
		//     if (response.status === 200) {
		//       navigator.clipboard.writeText(response.data.address);
		//       toast.success("Address copied to clipboard");
		//     } else {
		//       toast.error(response.data.message);
		//     }
		//   })
		//   .catch(function (error) {
		//     toast.success(error);
		//   });
	};

	const checktoWhitelist = async () => {
		await axios
			.get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('metamaskId')}`)
			.then(function (response) {
				if (response.status === 200) {
					if (getCookie('token'))
						if (
							response.data.jdbCardNumber1.length === 16 &&
							response.data.card_activated === 2
						) {
							setDisableForm(false);
						} else {
							setDisableForm(true);
						}
				} else {
					//  toast.error(response.data.message);
				}
			})
			.catch(function (error) {
				// toast.success(error);
			});
	};
	//
	useEffect(() => {
		if (sessionStorage.getItem('balance')) {
			setbalance(sessionStorage.getItem('balance'));
		}
	}, [sessionStorage.getItem('balance')]);
	const getBalanceHandler = async () => {
		await axios
			.get(`${URL}/users/walletBalance/${getCookie('metamaskId')}`, {
				headers: {
					Authorization: getCookie('token'),
				},
			})
			.then(function (response) {
				if (response.status === 200) {
					newBalanceData(response.data);
				} else {
					toast.error(response.data.message);
				}
			})
			.catch(function (error) {
				toast.success(error);
			});
	};

	const priceHandler = async () => {
		try {
			const response = await fetch(`${URL}/users/binanceprice`, {
				method: 'GET',
				headers: {},
			});
			const data = await response.json();
			if (response.status === 200) {
				data.map((a, b) => {
					if (a.symbol === 'BUSDUSDT') {
						setUSDT(1);
					}
					if (a.symbol === 'USDCUSDT') {
						setUSDC(a);
					}
					if (a.symbol === 'BUSDUSDT') {
						setBUSD(a);
					}
					if (a.symbol === 'BTCUSDT') {
						setBTC(a);
					}
					if (a.symbol === 'AUTOUSDT') {
						setAUTO(a);
					}
					if (a.symbol === 'EPSUSDT') {
						setEPS(a);
					}
					if (a.symbol === 'MBOXUSDT') {
						setMBOX(a);
					}
					if (a.symbol === 'XVSUSDT') {
						setXVS(a);
					}
					if (a.symbol === 'CAKEUSDT') {
						setCAKE(a);
					}

					if (a.symbol === 'ETHUSDT') {
						setETH(a);
					}
					if (a.symbol == 'BNBUSDT') {
						setBnb(a);
					}
					if (a.symbol == 'MKRUSDT') {
						setMKR(a);
					}
					if (a.symbol == 'CRVUSDT') {
						setCRV(a);
					}
					if (a.symbol == 'CVXUSDT') {
						setCVX(a);
					}
					if (a.symbol == 'INSTUSDT') {
						setINST(a);
					}
					if (a.symbol == 'LDOUSDT') {
						setLDO(a);
					}
					if (a.symbol == 'AAVEUSDT') {
						setAAVE(a);
					}
					if (a.symbol == 'UNIUSDT') {
						setUNI(a);
					}
					if (a.symbol == 'COMPUSDT') {
						setCOMP(a);
					}
					if (a.symbol == 'BALUSDT') {
						setBAL(a);
					}
					if (a.symbol == 'SUSHIUSDT') {
						setSUSHI(a);
					}
					if (a.symbol == 'YFIUSDT') {
						setYFI(a);
					}
					if (a.symbol == 'DAIUSDT') {
						setDAI(a);
					}
				});
			}
		} catch (err) {
		} finally {
		}
	};
	const getSettings = async () => {
		await axios
			.get(`${URL}/users/getBSCOne`, {
				headers: {
					Authorization: getCookie('token'),
				},
			})
			.then(function (response) {
				setCardLoadFee(response.data.card_load_fee);
				setTokenSettingsConstants(response.data.bsc_token_otc_percentage);
				setSettingsConstants(response.data.bsc_stables_otc_per);
				response.data.bsc_token_otc_options.map((a, b) => {
					if (a.label === 'USDT') {
						setSettingsUSDT(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'USDC') {
						setSettingsUSDC(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'BTC') {
						setSettingsBTC(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'AUTO') {
						setSettingsAUTO(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'EPS') {
						setSettingsEPS(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'MBOX') {
						setSettingsMBOX(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'XVS') {
						setSettingsXVS(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'CAKE') {
						setSettingsCAKE(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'BUSD') {
						setSettingsBUSD(response.data.bsc_token_otc_percentage);
					}
					if (a.label === 'ETH') {
						setSettingsETH(response.data.bsc_token_otc_percentage);
					}
					if (a.label == 'BNB') {
						setSettingsBNB(response.data.bsc_token_otc_percentage);
					}
				});
				response.data.bsc_stables_otc_options.map((a, b) => {
					if (a.label === 'USDT') {
						setSettingsUSDT(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'USDC') {
						setSettingsUSDC(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'BTC') {
						setSettingsBTC(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'AUTO') {
						setSettingsAUTO(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'EPS') {
						setSettingsEPS(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'MBOX') {
						setSettingsMBOX(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'XVS') {
						setSettingsXVS(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'CAKE') {
						setSettingsCAKE(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'BUSD') {
						setSettingsBUSD(response.data.bsc_stables_otc_per);
					}
					if (a.label === 'ETH') {
						setSettingsETH(response.data.bsc_stables_otc_per);
					}
				});

				JSON.parse(response.data.erc_token_otc_options).map((a, b) => {
					if (a.label === 'MKR') {
						setSettingsMKR(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'CRV') {
						setSettingsCRV(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'CVX') {
						setSettingsCVX(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'LDO') {
						setSettingsLDO(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'AAVE') {
						setSettingsAAVE(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'UNI') {
						setSettingsUNI(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'COMP') {
						setSettingsCOMP(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'BAL') {
						setSettingsBAL(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'INST') {
						setSettingsINST(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'SUSHI') {
						setSettingsSUSHI(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'YFI') {
						setSettingsYFI(response.data.erc_token_otc_percentage);
					}
					if (a.label === 'DAI') {
						setSettingsDAI(response.data.erc_token_otc_percentage);
					}
				});

				JSON.parse(response.data.erc_stables_otc_options).map((a, b) => {
					if (a.label === 'MKR') {
						setSettingsMKR(response.data.erc_stables_otc_per);
					}
					if (a.label === 'CRV') {
						setSettingsCRV(response.data.erc_stables_otc_per);
					}
					if (a.label === 'CVX') {
						setSettingsCVX(response.data.erc_stables_otc_per);
					}
					if (a.label === 'LDO') {
						setSettingsLDO(response.data.erc_stables_otc_per);
					}
					if (a.label === 'AAVE') {
						setSettingsAAVE(response.data.erc_stables_otc_per);
					}
					if (a.label === 'UNI') {
						setSettingsUNI(response.data.erc_stables_otc_per);
					}
					if (a.label === 'COMP') {
						setSettingsCOMP(response.data.erc_stables_otc_per);
					}
					if (a.label === 'BAL') {
						setSettingsBAL(response.data.erc_stables_otc_per);
					}
					if (a.label === 'INST') {
						setSettingsINST(response.data.erc_stables_otc_per);
					}
					if (a.label === 'SUSHI') {
						setSettingsSUSHI(response.data.erc_stables_otc_per);
					}
					if (a.label === 'YFI') {
						setSettingsYFI(response.data.erc_stables_otc_per);
					}
					if (a.label === 'DAI') {
						setSettingsDAI(response.data.erc_stables_otc_per);
					}
				});
			})
			.catch(function (error) {});
	};

	//partner check
	const partnerCheck = async () => {
		const partner =
			getCookie('partnerName') === 'masbank.io' ||
			getCookie('partnerName').includes('backoffice')
				? 'masbank.io'
				: getCookie('partnerName').split('/')[1];

		var config = {
			method: 'get',
			url: URL + '/users/partner/' + partner,
			headers: {},
		};

		await axios(config)
			.then(function (response) {
				if (response.status === 200) {
					setPartner(response.data);
				}
			})
			.catch(function (error) {});
	};
	//layer 1 pricing    ////    price-
	const valueCalculator = (price, otc, prv) => {
		return price - ((otc * price) / 100 + (prv * price) / 100);
	};

	const layerPricing = (value1) => {
		if (value1 === 'USDT') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(priceUSDT, partner?.partner_otc, partner?.prv_otc)
				);
			} else if (settingsUSDT && settingsUSDT !== 0) {
				return calculateDecimal4(priceUSDT - priceUSDT * (settingsUSDT / 100));
			} else {
				return priceUSDT;
			}
		} else if (value1 === 'USDC') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceUSDC?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsUSDC && settingsUSDC !== 0) {
				return calculateDecimal4(
					priceUSDC?.price - priceUSDC?.price * (settingsUSDC / 100)
				);
			} else {
				return priceUSDC?.price;
			}
		} else if (value1 === 'BTC') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceBTC?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsBTC !== 0) {
				return calculateDecimal4(
					priceBTC?.price - priceBTC?.price * (settingsBTC / 100)
				);
			} else {
				return priceBTC?.price;
			}
		} else if (value1 === 'AUTO') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceAUTO?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsAUTO !== 0) {
				return calculateDecimal4(
					priceAUTO?.price - priceAUTO?.price * (settingsAUTO / 100)
				);
			} else {
				return priceAUTO?.price;
			}
		} else if (value1 === 'MBOX') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceMBOX?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsMBOX !== 0) {
				return calculateDecimal4(
					priceMBOX?.price - priceMBOX?.price * (settingsMBOX / 100)
				);
			} else {
				return priceMBOX?.price;
			}
		} else if (value1 === 'CAKE') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceCAKE?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsCAKE !== 0) {
				return calculateDecimal4(
					priceCAKE?.price - priceCAKE?.price * (settingsCAKE / 100)
				);
			} else {
				return priceCAKE?.price;
			}
		} else if (value1 === 'XVS') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceXVS?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsXVS !== 0) {
				return calculateDecimal4(
					priceXVS?.price - priceXVS?.price * (settingsXVS / 100)
				);
			} else {
				return priceXVS?.price;
			}
		} else if (value1 === 'BUSD') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceBUSD?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsBUSD !== 0) {
				return calculateDecimal4(
					priceBUSD?.price - priceBUSD?.price * (settingsBUSD / 100)
				);
			} else {
				return priceBUSD?.price;
			}
		} else if (value1 === 'ETH') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceETH?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsETH !== 0) {
				return calculateDecimal4(
					priceETH?.price - priceETH?.price * (settingsETH / 100)
				);
			} else {
				return priceETH?.price;
			}
		} else if (value1 === 'EPS') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceEPS?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsEPS !== 0) {
				return calculateDecimal4(
					priceEPS?.price - priceEPS?.price * (settingsEPS / 100)
				);
			} else {
				return priceEPS?.price;
			}
		} else if (value1 === 'BNB') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(bnb?.price, partner?.partner_otc, partner?.prv_otc)
				);
			} else if (settingsEPS !== 0) {
				return calculateDecimal4(bnb?.price - bnb?.price * (settingsBNB / 100));
			} else {
				return bnb?.price;
			}
		} else if (value1 === 'MKR') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceMKR?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsMKR !== 0) {
				return calculateDecimal4(
					priceMKR?.price - priceMKR?.price * (settingsMKR / 100)
				);
			} else {
				return priceMKR?.price;
			}
		} else if (value1 === 'CRV') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceCRV?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsCRV !== 0) {
				return calculateDecimal4(
					priceCRV?.price - priceCRV?.price * (settingsCRV / 100)
				);
			} else {
				return priceMKR?.price;
			}
		} else if (value1 === 'CVX') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceCVX?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsCVX !== 0) {
				return calculateDecimal4(
					priceCVX?.price - priceCVX?.price * (settingsCVX / 100)
				);
			} else {
				return priceCVX?.price;
			}
		} else if (value1 === 'LDO') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceLDO?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsLDO !== 0) {
				return calculateDecimal4(
					priceLDO?.price - priceLDO?.price * (settingsLDO / 100)
				);
			} else {
				return priceLDO?.price;
			}
		} else if (value1 === 'AAVE') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceAAVE?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsAAVE !== 0) {
				return calculateDecimal4(
					priceAAVE?.price - priceAAVE?.price * (settingsAAVE / 100)
				);
			} else {
				return priceAAVE?.price;
			}
		} else if (value1 === 'UNI') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceUNI?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsUNI !== 0) {
				return calculateDecimal4(
					priceUNI?.price - priceUNI?.price * (settingsUNI / 100)
				);
			} else {
				return priceUNI?.price;
			}
		} else if (value1 === 'COMP') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceCOMP?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsCOMP !== 0) {
				return calculateDecimal4(
					priceCOMP?.price - priceCOMP?.price * (settingsCOMP / 100)
				);
			} else {
				return priceCOMP?.price;
			}
		} else if (value1 === 'BAL') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceBAL?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsBAL !== 0) {
				return calculateDecimal4(
					priceBAL?.price - priceBAL?.price * (settingsBAL / 100)
				);
			} else {
				return priceBAL?.price;
			}
		} else if (value1 === 'INST') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceINST?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsINST !== 0) {
				return calculateDecimal4(
					priceINST?.price - priceINST?.price * (settingsINST / 100)
				);
			} else {
				return priceINST?.price;
			}
		} else if (value1 === 'SUSHI') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceSUSHI?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsSUSHI !== 0) {
				return calculateDecimal4(
					priceSUSHI?.price - priceSUSHI?.price * (settingsSUSHI / 100)
				);
			} else {
				return priceSUSHI?.price;
			}
		} else if (value1 === 'YFI') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceYFI?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsYFI !== 0) {
				return calculateDecimal4(
					priceYFI?.price - priceYFI?.price * (settingsYFI / 100)
				);
			} else {
				return priceYFI?.price;
			}
		} else if (value1 === 'WBTC') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceBTC?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsBTC !== 0) {
				return calculateDecimal4(
					priceBTC?.price - priceBTC?.price * (settingsBTC / 100)
				);
			} else {
				return priceBTC?.price;
			}
		} else if (value1 === 'DAI') {
			if (parseInt(partner?.partner_otc) && parseInt(partner?.prv_otc)) {
				return calculateDecimal4(
					valueCalculator(
						priceDAI?.price,
						partner?.partner_otc,
						partner?.prv_otc
					)
				);
			} else if (settingsDAI !== 0) {
				return calculateDecimal4(
					priceDAI?.price - priceDAI?.price * (settingsDAI / 100)
				);
			} else {
				return priceDAI?.price;
			}
		} else {
			return 0;
		}
	};

	function convert(n) {
		var sign = +n < 0 ? '-' : '',
			toStr = n.toString();
		if (!/e/i.test(toStr)) {
			return n;
		}
		var [lead, decimal, pow] = n
			.toString()
			.replace(/^-/, '')
			.replace(/^([0-9]+)(e.*)/, '$1.$2')
			.split(/e|\./);
		return +pow < 0
			? sign +
					'0.' +
					'0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
					lead +
					decimal
			: sign +
					lead +
					(+pow >= decimal.length
						? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
						: decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
	}

	const handleMaxButtonClick = async () => {
		if (state.token == '') {
			toast.error('Please select token');
			return;
		}
		let tokenValue = state.token;
		const web3 = await getWeb3();
		var accs = await web3.eth.getAccounts();
		// alert(e.target.value)
		if (localStorage.getItem('currentChain')?.toLowerCase() == 'eth') {
			if (
				localStorage.getItem('currentChain')?.toLowerCase() === 'eth' &&
				tokenValue === 'ETH'
			) {
				web3.eth.getBalance(getCookie('metamaskId')).then((balance) => {
					setCurrentBalance(balance / Math.pow(10, 18));
					setState({
						...state,
						amount: calculateDecimal4(balance / Math.pow(10, 18)),
					});
				});

				return;
			}

			const newAccounts = await Promise.all(
				accs.map(async (address) => {
					const tokenInst = new web3.eth.Contract(
						ABIEther2,
						addressesArrayData[
							addressesArrayData.findIndex((item) => item.token === tokenValue)
						].address
					);

					const balance = await tokenInst.methods.balanceOf(address).call();
					// alert(balance)
					return {
						balance,
					};
				})
			);

			if (
				localStorage.getItem('currentChain')?.toLowerCase() === 'eth' &&
				(tokenValue === 'USDT' || tokenValue === 'USDC')
			) {
				setCurrentBalance(newAccounts[0].balance / Math.pow(10, 6));
				setState({
					...state,
					amount: calculateDecimal4(newAccounts[0].balance / Math.pow(10, 6)),
				});
				return;
			}
			if (
				localStorage.getItem('currentChain')?.toLowerCase() === 'eth' &&
				tokenValue === 'WBTC'
			) {
				setCurrentBalance(newAccounts[0].balance / Math.pow(10, 8));
				setState({
					...state,
					amount: calculateDecimal4(newAccounts[0].balance / Math.pow(10, 8)),
				});
				return;
			}

			setCurrentBalance(
				web3.utils.fromWei(
					parseInt(newAccounts[0].balance || 0).toString(),
					'ether'
				)
			);
			setState({
				...state,
				amount: web3.utils.fromWei(
					parseInt(newAccounts[0].balance || 0).toString(),
					'ether'
				),
			});

			return;
		}
		const index = initialData.findIndex((data) => data.token === tokenValue);

		let contractAddress = '';
		if (index != -1) {
			contractAddress = addressesArray[index].address;
		}

		setState({ ...state, token: tokenValue });

		let instance;
		if (tokenValue == 'ETH') {
			instance = new web3.eth.Contract(ABIEther, contractAddress);
		} else if (tokenValue == 'BNB') {
			web3.eth.getBalance(getCookie('metamaskId')).then((balance) => {
				setCurrentBalance(
					web3.utils.fromWei(parseInt(balance || 0).toString(), 'ether')
				);
				setState({
					...state,
					amount: calculateDecimal4(
						web3.utils.fromWei(parseInt(balance || 0).toString(), 'ether')
					),
				});
				return;
			});
			return;
		} else if (tokenValue == 'GBEX') {
			instance = new web3.eth.Contract(ABIXDC, contractAddress);
		} else {
			instance = new web3.eth.Contract(ABIEther, contractAddress);
		}
		const balanceConst = await instance.methods
			.balanceOf(getCookie('metamaskId'))
			.call();

		setCurrentBalance(
			web3.utils.fromWei(
				convert(parseInt(balanceConst || 0)).toString(),
				'ether'
			)
		);
		setState({
			...state,
			amount: calculateDecimal4(
				web3.utils.fromWei(
					convert(parseInt(balanceConst || 0)).toString(),
					'ether'
				)
			),
		});
	};
	const override = {
		display: 'block',
		margin: '0 auto',
	};

	return (
		<>
			{localStorage.getItem('metamaskId') && localStorage.getItem('token') ? (
				''
			) : (
				<HeaderNavigator />
			)}

			<div className='background-image-test dashboard'>
				<div className='container py-4' style={{ maxWidth: '1500px' }}>
					{loading ? (
						<div className='row' style={{ height: '500px' }}>
							<div className='col-12 text-center my-auto d-flex justify-content-center'>
								<div className='m-auto'>
									<ClockLoader color='white' size={86} override={override} />
								</div>
							</div>
						</div>
					) : (
						<>
							<div className='row '>
								<div className='col-lg-7 mb-lg-0 mb-4'>
									<div
										className='card z-index-2 mobile-bottom'
										style={{ borderRadius: '30px 30px 0px 0px' }}>
										<div className='card-body p-6 pt-4'>
											<h6 className='ms-2 mb-0 text-black text-center h-white headtext mb-3'>
												MetaMask Wallet balance
											</h6>
											{localStorage.getItem('metamaskId') &&
											localStorage.getItem('token') &&
											localStorage.getItem('currentChain') ? (
												localStorage.getItem('currentChain').toLowerCase() ==
												'eth' ? (
													<EtherMetamaskBalance />
												) : localStorage
														.getItem('currentChain')
														.toLowerCase() == 'bsc' ? (
													<BSCMetamaskBalance />
												) : (
													<XDCMetamaskBalances />
												)
											) : (
												<Allcoins />
											)}
										</div>
									</div>
								</div>
								<div className='col-lg-5 mb-lg-0 mb-4 mobile-hide'>
									<div className='card z-index-2'>
										<div className='card-body pt-4 pb-0 mobile-card'>
											<div
												id={
													localStorage.getItem('metamaskId') &&
													localStorage.getItem('token')
														? ''
														: 'overlay'
												}></div>
											<h6 className='ms-2 mb-0 text-center text-black h-white headtext mb-3'>
												Deposit from metamask
											</h6>
											<div className='row justify-content-center '>
												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-12  p-4 pt-3 mb-0'
															: 'col-md-12  p-4 pt-3 mb-0 reduce_opacity'
													}>
													<div className='  ' style={{ marginTop: '60px' }}>
														<form>
															<div
																className='wbtc gray row pb-3 mb-4'

																// style={{ backgroundColor: "#3C4043" }}
															>
																<div className='col'>
																	<label
																		for='exampleFormControlInput1'
																		className={'disableform'}>
																		Token
																	</label>
																	<select
																		className='form-control'
																		value={state.token}
																		id='exampleFormControlSelect1'
																		placeholder='Choose'
																		// disabled={disableForm === true ? true : false}
																		onChange={async (e) => {
																			try {
																				if (e.target.value == 'Choose') {
																					setState({
																						...state,
																						token: '',
																						amount: '',
																					});
																					return;
																				}
																				setState({
																					...state,
																					token: e.target.value,
																					amount: '',
																				});
																				let tokenValue = e.target.value;
																				const web3 = await getWeb3();
																				var accs = await web3.eth.getAccounts();
																				// alert(e.target.value)
																				if (
																					localStorage
																						.getItem('currentChain')
																						?.toLowerCase() == 'eth'
																				) {
																					if (
																						localStorage
																							.getItem('currentChain')
																							?.toLowerCase() === 'eth' &&
																						tokenValue === 'ETH'
																					) {
																						web3.eth
																							.getBalance(
																								getCookie('metamaskId')
																							)
																							.then((balance) => {
																								setCurrentBalance(
																									balance / Math.pow(10, 18)
																								);
																							});

																						return;
																					}

																					const newAccounts = await Promise.all(
																						accs.map(async (address) => {
																							const tokenInst =
																								new web3.eth.Contract(
																									ABIEther2,
																									addressesArrayData[
																										addressesArrayData.findIndex(
																											(item) =>
																												item.token ===
																												tokenValue
																										)
																									].address
																								);

																							const balance =
																								await tokenInst.methods
																									.balanceOf(address)
																									.call();
																							// alert(balance)
																							return {
																								balance,
																							};
																						})
																					);

																					if (
																						localStorage
																							.getItem('currentChain')
																							?.toLowerCase() === 'eth' &&
																						(tokenValue === 'USDT' ||
																							tokenValue === 'USDC')
																					) {
																						setCurrentBalance(
																							newAccounts[0].balance /
																								Math.pow(10, 6)
																						);
																						return;
																					}
																					if (
																						localStorage
																							.getItem('currentChain')
																							?.toLowerCase() === 'eth' &&
																						tokenValue === 'WBTC'
																					) {
																						setCurrentBalance(
																							newAccounts[0].balance /
																								Math.pow(10, 8)
																						);
																						return;
																					}

																					setCurrentBalance(
																						web3.utils.fromWei(
																							parseInt(
																								newAccounts[0].balance || 0
																							).toString(),
																							'ether'
																						)
																					);

																					return;
																				}
																				const index = initialData.findIndex(
																					(data) => data.token === tokenValue
																				);

																				let contractAddress = '';
																				if (index != -1) {
																					contractAddress =
																						addressesArray[index].address;
																				}

																				setState({
																					...state,
																					token: tokenValue,
																				});

																				let instance;
																				if (tokenValue == 'ETH') {
																					instance = new web3.eth.Contract(
																						ABIEther,
																						contractAddress
																					);
																				} else if (tokenValue == 'BNB') {
																					web3.eth
																						.getBalance(getCookie('metamaskId'))
																						.then((balance) => {
																							setCurrentBalance(
																								web3.utils.fromWei(
																									parseInt(
																										balance || 0
																									).toString(),
																									'ether'
																								)
																							);
																							return;
																						});
																					return;
																				} else if (tokenValue == 'GBEX') {
																					instance = new web3.eth.Contract(
																						ABIXDC,
																						contractAddress
																					);
																				} else {
																					instance = new web3.eth.Contract(
																						ABIEther,
																						contractAddress
																					);
																				}
																				const balanceConst =
																					await instance.methods
																						.balanceOf(getCookie('metamaskId'))
																						.call();

																				setCurrentBalance(
																					web3.utils.fromWei(
																						convert(
																							parseInt(balanceConst || 0)
																						).toString(),
																						'ether'
																					)
																				);
																			} catch (e) {
																				console.log(e);
																			}
																		}}>
																		{localStorage
																			.getItem('currentChain')
																			?.toLowerCase() == 'eth' ? (
																			<>
																				<option>Choose</option>
																				<option>USDT</option>
																				<option>USDC</option>

																				<option>MKR</option>
																				<option>CVX</option>
																				<option>CRV</option>
																				<option>LDO</option>
																				<option>AAVE</option>
																				<option>UNI</option>
																				<option>COMP</option>
																				<option>BAL</option>
																				<option>SUSHI</option>
																				<option>WBTC</option>
																				<option>YFI</option>
																				<option>DAI</option>
																				<option>ETH</option>
																			</>
																		) : (
																			<>
																				<option>Choose</option>
																				<option>USDT</option>
																				<option>USDC</option>
																				<option>BTCB</option>
																				<option>AUTO</option>

																				{/* <option>MBOX</option> */}
																				<option>XVS</option>
																				<option>CAKE</option>
																				<option>BUSD</option>
																				<option>ETH</option>
																				<option>BNB</option>
																			</>
																		)}

																		{/* <option>MKR</option>
                                <option>CRV</option>
                                <option>CVX</option>
                                <option>LDO</option>
                                <option>AAVE</option>
                                <option>UNI</option>
                                <option>COMP</option>
                                <option>INST</option>
                                <option>BAL</option>
                                <option>SUSHI</option>
                                <option>YFI</option>
                                <option>DAI</option>
                                <option>AVAX</option>
                                <option>WBTC</option>
                                <option>WETH</option>
                                <option>GBEX</option> */}
																	</select>
																</div>
																<div className='change-width'></div>
																<div className='col'>
																	<label
																		for='exampleFormControlInput2'
																		className={'disableform'}>
																		Available Balance
																	</label>
																	<input
																		type='number'
																		value={currentBalance}
																		disabled={true}
																		className='form-control'
																		id='amount'
																		placeholder='Balance'
																	/>
																</div>
																<div className='w-100'></div>
																<div className='col pt-1 pb-0'>
																	<label
																		for='Name'
																		className={
																			disableForm === true ? '' : 'disableform'
																		}>
																		Amount to Deposit
																	</label>
																	<div className='d-flex'>
																		<input
																			type='number'
																			className='form-control'
																			// disabled={disableForm === true ? true : false}
																			id='Name'
																			placeholder='0.12345'
																			value={state.amount}
																			onChange={(e) => {
																				setState({
																					...state,
																					amount: e.target.value,
																				});
																			}}
																		/>
																		<button
																			type='button'
																			className='bg-white'
																			onClick={handleMaxButtonClick}
																			style={{
																				borderRadius: '9px',
																				backgroundColor: 'white',
																				border: 'none',
																				color: 'black',
																				marginLeft: '10px',
																			}}>
																			Max
																		</button>
																	</div>
																</div>
															</div>
															<div className=' justify-content-center text-center pt-2 pb-0'>
																<button
																	type='button'
																	className='btn btns mobile-style'
																	// disabled={disableForm === true ? true : false}
																	onClick={contractCall}>
																	CONFIRM
																</button>
															</div>
															<div style={{ marginTop: '70px' }}></div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='row my-4'>
								<div className='col-lg-12 mb-lg-0 mb-4'>
									<div className='card z-index-2'>
										<div className='card-body p-6 new-card-style'>
											<div className='d-flex justify-content-between align-items-center flex-wrap'>
												<h6 className='ms-2 text-black mb-0 h-white headtext mb-3'>
													MASPay Wallet Balance
												</h6>
												<button
													type='button'
													className='btn btns'
													disabled={disableForm === true ? true : false}
													onClick={() => {
														history.push('/card-load-home');
													}}>
													Load Card
												</button>
											</div>
											<div className='row justify-content-left'>
												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div
														className='wbtc wbtc-w cursor-pointer'
														onClick={
															localStorage.getItem('metamaskId') &&
															localStorage.getItem('token')
																? copyAddressToClipboard
																: ''
														}>
														<div className='brificon'>
															<img src={USDT} width='40px' />
														</div>
														<p>
															USDT{' '}
															{/* <i
                          className="fa fa-arrow-up icon1 icon-green"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(
																				balanceData?.USDT || 0
																			).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.USDT?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.USDT
																				? convert(balanceData?.USDT / 10 ** 18)
																				: 0) * layerPricing('USDT')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div
														className='wbtc wbtc-w cursor-pointer'
														onClick={
															localStorage.getItem('metamaskId') &&
															localStorage.getItem('token')
																? copyAddressToClipboard
																: ''
														}>
														<div className='brificon'>
															<img src={USDC} width='40px' />
														</div>
														<p>
															USDC{' '}
															{/* <i
                          className="fa fa-arrow-up icon1 icon-green"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		convert(balanceData?.USDC / 10 ** 18 || 0)
																	)}
																	{/* {balanceData?.USDC?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.USDC
																				? convert(balanceData?.USDC / 10 ** 18)
																				: 0) * layerPricing('USDC')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div
														className='wbtc wbtc-w cursor-pointer'
														onClick={
															localStorage.getItem('metamaskId') &&
															localStorage.getItem('token')
																? copyAddressToClipboard
																: ''
														}>
														<div className='brificon'>
															<img src={BTC} width='40px' />
														</div>
														<p>
															BTCB{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>

														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(
																				balanceData?.btcb || 0
																			).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.btcb?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.btcb?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.btcb || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('BTC')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div
														className='wbtc wbtc-w cursor-pointer'
														onClick={
															localStorage.getItem('metamaskId') &&
															localStorage.getItem('token')
																? copyAddressToClipboard
																: ''
														}>
														<div className='brificon'>
															<img src={AUTO} width='40px' />
														</div>
														<p>
															AUTO{' '}
															{/* <i
                          className="fa fa-arrow-up icon1 icon-green"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(
																				balanceData?.AUTO || 0
																			).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.AUTO?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.AUTO?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.AUTO || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('AUTO')
																		)
																	).toString() + ' USD' || '0 USD'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												{/* <div className={(localStorage.getItem("metamaskId") && localStorage.getItem("token")) ? 'col-md-2 p-2 pt-3' : "col-md-2 p-2 pt-3 reduce_opacity"}>
                      <div
                        className="wbtc wbtc-w cursor-pointer"
                        onClick={(localStorage.getItem("metamaskId") && localStorage.getItem("token")) ? copyAddressToClipboard : ""}
                      >
                        <div className="brificon">
                          <img src={MBOX} width="40px" />
                        </div>
                        <p>
                          MBOX{" "}
                         
                        </p>
                        <h4 className="price">
                          {(localStorage.getItem("metamaskId") && localStorage.getItem("token")) ?
                            <>
                              {calculateDecimalFor8(web3.utils.fromWei(parseInt(balanceData?.MBOX || 0).toString(), 'ether'))}
                              
                            </>
                            : 0}

                        </h4>
                        <h4
                          className="price"
                          style={{ color: "white", fontSize: "18px" }}
                        >
                          {(localStorage.getItem("metamaskId") && localStorage.getItem("token")) ?
                            <>
                              {calculateDecimal(convert(
                                (balanceData?.MBOX
                                  ? (web3.utils.fromWei(parseInt(balanceData?.MBOX || 0).toString(), 'ether'))
                                  : 0) *
                                layerPricing("MBOX")
                              )).toString() + " USD" || "0"}</> : 0}
                        </h4>
                      </div>
                    </div> */}

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div
														className='wbtc wbtc-w cursor-pointer'
														onClick={
															localStorage.getItem('metamaskId') &&
															localStorage.getItem('token')
																? copyAddressToClipboard
																: ''
														}>
														<div className='brificon'>
															<img src={XVS} width='40px' />
														</div>
														<p>
															XVS{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.XVS || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.XVS?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.XVS
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.XVS || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('XVS')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div
														className='wbtc wbtc-w cursor-pointer'
														onClick={
															localStorage.getItem('metamaskId') &&
															localStorage.getItem('token')
																? copyAddressToClipboard
																: ''
														}>
														<div className='brificon'>
															<img src={CAKE} width='40px' />
														</div>
														<p>
															CAKE{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(
																				balanceData?.Cake || 0
																			).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.Cake?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.Cake
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.Cake || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('CAKE')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>
												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div
														className='wbtc wbtc-w cursor-pointer'
														onClick={
															localStorage.getItem('metamaskId') &&
															localStorage.getItem('token')
																? copyAddressToClipboard
																: ''
														}>
														<div className='brificon'>
															<img src={BUSD} width='40px' />
														</div>
														<p>
															BUSD{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(
																				balanceData?.busd || 0
																			).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.busd?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.busd
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.busd || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('BUSD')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div
														className='wbtc wbtc-w cursor-pointer'
														onClick={
															localStorage.getItem('metamaskId') &&
															localStorage.getItem('token')
																? copyAddressToClipboard
																: ''
														}>
														<div className='brificon'>
															<img src={ETH} width='40px' />
														</div>
														<p>
															ETH{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.eth || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.eth?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.eth || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('ETH')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>
												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img
																src={
																	'https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png'
																}
																width='40px'
															/>
														</div>
														<p>
															BNB{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.bnb || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.bnb?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.bnb || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('BNB')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>
												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img
																src={
																	'https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png'
																}
																width='40px'
															/>
														</div>
														<p>
															MKR{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.MKR || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.MKR?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.MKR || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('MKR')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img
																src={
																	'https://s2.coinmarketcap.com/static/img/coins/64x64/6538.png'
																}
																width='40px'
															/>
														</div>
														<p>
															CRV{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.CRV || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.CRV?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.CRV || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('CRV')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img src={CVX} width='40px' />
														</div>
														<p>
															CVX{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.CVX || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.CVX?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.CVX || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('CVX')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img src={LDO} width='40px' />
														</div>
														<p>
															LDO{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.LDO || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.LDO?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.LDO || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('LDO')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img src={AAVE} width='40px' />
														</div>
														<p>
															AAVE{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(
																				balanceData?.AAVE || 0
																			).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.AAVE?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.AAVE || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('AAVE')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img src={UNI} width='40px' />
														</div>
														<p>
															UNI{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.UNI || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.UNI?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.UNI || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('UNI')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img src={COMP} width='40px' />
														</div>
														<p>
															COMP{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(
																				balanceData?.COMP || 0
																			).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.COMP?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.COMP || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('COMP')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img src={BAL} width='40px' />
														</div>
														<p>
															BAL{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.BAL || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.BAL?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.BAL || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('BAL')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img src={SUSHI} width='40px' />
														</div>
														<p>
															SUSHI{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(
																				balanceData?.SUSHI || 0
																			).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.SUSHI?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.SUSHI || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('SUSHI')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img
																src={
																	'https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png'
																}
																width='40px'
															/>
														</div>
														<p>
															YFI{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.YFI || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.YFI?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.YFI || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('YFI')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img src={DAI} width='40px' />
														</div>
														<p>
															DAI{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimalFor8(
																		web3.utils.fromWei(
																			convert(balanceData?.DAI || 0).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.eth?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.DAI?.toFixed(2)
																				? web3.utils.fromWei(
																						convert(
																							balanceData?.DAI || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('DAI')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>

												<div
													className={
														localStorage.getItem('metamaskId') &&
														localStorage.getItem('token')
															? 'col-md-2 p-2 pt-3'
															: 'col-md-2 p-2 pt-3 reduce_opacity'
													}>
													<div className='wbtc wbtc-w cursor-pointer'>
														<div className='brificon'>
															<img src={BTC} width='40px' />
														</div>
														<p>
															WBTC{' '}
															{/* <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i> */}
														</p>
														<h4 className='price'>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateHoleDec(
																		web3.utils.fromWei(
																			parseInt(
																				balanceData?.WBTC || 0
																			).toString(),
																			'ether'
																		)
																	)}
																	{/* {balanceData?.btcb?.toFixed(2) || "0"} */}
																</>
															) : (
																0
															)}
														</h4>
														<h4
															className='price'
															style={{ color: 'white', fontSize: '18px' }}>
															{localStorage.getItem('metamaskId') &&
															localStorage.getItem('token') ? (
																<>
																	{calculateDecimal(
																		convert(
																			(balanceData?.WBTC?.toFixed(2)
																				? web3.utils.fromWei(
																						parseInt(
																							balanceData?.WBTC || 0
																						).toString(),
																						'ether'
																				  )
																				: 0) * layerPricing('WBTC')
																		)
																	).toString() + ' USD' || '0'}
																</>
															) : (
																0
															)}
														</h4>
													</div>
												</div>
												{/* <div className="col-md-2 p-2 pt-3">
                    <div className="wbtc wbtc-w">
                      <div className="brificon">
                        <img src={LogoIcon} width="40px" />
                      </div>
                      <p>
                        PRV{" "}
                        <i
                          className="fa fa-arrow-up icon1 icon-green"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <h4 className="price">0</h4>
                      <h4 className="price" style={{color:'white',fontSize:'18px'}}>0 USD</h4>
                    </div>
                  </div> */}
												{/* <div className="col-md-2 p-2 pt-3">
                    <div className="wbtc wbtc-w">
                      <div className="brificon">
                        <img src={RData} width="40px" />
                      </div>
                      <p>
                        WBTC{" "}
                        <i
                          className="fa fa-arrow-down icon1 icon-red"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <h4 className="price">$3124.69</h4>
                    </div>
                  </div> */}
												<div>
													<span className='d-flex text-dark justify-content-center span-txt txt--white mb--20'>
														MASPay wallet balances cannot be withdrawn and can
														only be used to load MASPay Card.
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
