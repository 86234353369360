import { React, useEffect } from 'react';
import HeaderNavigator from '../../Components/Header/HeaderNavigator';
import './Dopple.scss';
import axios from 'axios';

import { useHistory, useLocation } from 'react-router';
import { NavLink, useParams } from 'react-router-dom';
import Home from '../Home/Home';
import { URL } from '../../Utils/url';
import { toast } from 'react-toastify';
function Dopple() {
	const history = useHistory();
	useEffect(() => {
		partnerCheck();
	}, []);
	const { id } = useParams();
	const partner_name = id ? id : '';

	console.log(partner_name, 'name');
	//partner check
	const partnerCheck = async () => {
		//alert(partner_name)
		var config = {
			method: 'get',
			url: URL + '/users/partner/' + partner_name,
			headers: {},
		};

		axios(config)
			.then(function (response) {
				if (response.status === 200) {
				} else {
					history.push('/login');
				}
			})
			.catch(function (error) {
				toast.error('Referral link doesnt exist');
				history.push('/login');
			});
	};
	return (
		<>
			<Home />
		</>
	);
}
export default Dopple;
