import React, { Component } from 'react';
import cripto from '../../Sass/img/cripto-debitcard.png';
import premium from '../../Sass/img/black.jpeg';
import shield from '../../Sass/img/shield1.png';
import silver from '../../Sass/img/silver.jpeg';
import golden from '../../Sass/img/golden.jpeg';
import line from '../../Sass/img/line.png';
import './Landing.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import HeaderNavigator from '../../Components/Header/HeaderNavigator';
import LandingPageHeader from '../../Components/Header/LandingPageHeader';
import { Modal, Button } from 'react-bootstrap';

class Landing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: true,
		};
	}

	componentDidMount() {
		$('ul').on('click', 'li', function () {
			var pos = $(this).index() + 2;
			$('tr').find('td:not(:eq(0))').hide();
			$('td:nth-child(' + pos + ')').css('display', 'table-cell');
			$('tr').find('th:not(:eq(0))').hide();
			$('li').removeClass('active');
			$(this).addClass('active');
		});

		// Initialize the media query
		var mediaQuery = window.matchMedia('(min-width: 640px)');

		// Add a listen event
		mediaQuery.addListener(doSomething);

		// Function to do something with the media query
		function doSomething(mediaQuery) {
			if (mediaQuery.matches) {
				$('.sep').attr('colspan', 5);
			} else {
				$('.sep').attr('colspan', 2);
				$('.clk').trigger('click');
			}
		}

		// On load
		doSomething(mediaQuery);
	}
	render() {
		return (
			<div class='landing'>
				<LandingPageHeader />
				<main class='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '>
					<div class='container-fluid m-0 p-0'>
						<div class='container py-4'>
							<div class='row pt-6 justify-content-center'>
								<div class='col-lg-5 col-md-6 mb-lg-0 mb-4'>
									<div class='z-index-2'>
										<h3 class='mb-0  h-white headtext mb-3 color-head-landing'>
											<span class='color-y'>MASPay</span> Crypto Debit Card
										</h3>
										<p class='h-white text-black color-head-landing-pt'>
											We have started to offer our MASPay Crypto Debit Card
											globally. Using the card, you can easily access fiat with
											ATM withdrawals and pay for online and offline purchases.
										</p>
										<div>
											<button
												type='button'
												class='btn  btns-w pr-2 btn-sign-landing'
												style={{ background: '#3A73C9 !important' }}
												onClick={() => this.props.history.push('/affiliate')}>
												Login
											</button>

											<button
												type='button'
												class='btn  btns-w pr-2 btn-sign-landing'>
												Partnerships
											</button>
										</div>
										{/* <button type="button" class="btn  btns-w btn-sign-landing1">
                  Learn More{" "}
                </button> */}
									</div>
								</div>
								<div class='col-lg-4 col-md-6 mb-lg-0 mb-4'>
									<div class=' z-index-2'>
										<img
											src={premium}
											alt='cripto-debitcard'
											class='img-fluid'
										/>
									</div>
								</div>
							</div>
						</div>

						<div class='container-fluid m-0 mt-5 p-0 pt-4'>
							<div class='h-img'>
								<div class='container justify-content-center text-center'>
									<h3
										class='mb-0 p-0  h-white headtext color-head-landing justify-content-center'
										style={{ textTransform: 'uppercase' }}>
										{' '}
										Beta launch <span class='color-y'>card</span> Details
									</h3>
								</div>
							</div>
							<div class='container pt-5 mt-5'>
								<div class='row justify-content-center'>
									<div class='col-lg-5 col-md-6 mb-lg-0 mb-4'>
										<div class='z-index-2'>
											<div class=' z-index-2'>
												<img
													src={premium}
													alt='premium-black'
													class='img-fluid bd-right'
												/>
											</div>
										</div>
									</div>
									<div class='col-lg-6 col-md-4 mb-lg-0 mb-4 txt-right-landing'>
										<div class=' z-index-2'>
											<h3 class='mb-0  h-white headtext mb-3 color-head-landing'>
												<span class='color-y '>MASPay Card</span>
											</h3>
											<p class='h-white color-head-landing-pt hd-fnt p-0 m-0'>
												{/* <img
                          src={shield}
                          alt='premium-black'
                          class='img-fluid'
                        /> */}
												{/* <span class='pr-5  hd-color'>Staking Rewards: </span> 2%
                        APR */}
											</p>
											<p class='h-white color-head-landing-pt hd-fnt'>
												{/* <img
                          src={shield}
                          alt='premium-black'
                          class='img-fluid'
                        />
                        <span class='pr-5 hd hd-color'> Stake: </span> $200 USD */}
											</p>
											<p class='h-white color-head-landing-pt hd-fnt'>
												<span class='pr-5 hd-color'> Card Cost: </span> $300
												USDT
												{/* + <span class='hd-color'>Stake </span>$200 worth PYDEX */}
											</p>
											<p class='h-white color-head-landing-pt hd-fnt'>
												<span class='pr-5 hd-color'>
													{' '}
													Monthly Top-up Limit:{' '}
												</span>{' '}
												No Limit
											</p>
										</div>
									</div>
								</div>
							</div>

							<div class='h-img2 m-0 mt-5 p-0 pt-4'>
								<div class='container  mt-5 text-center'>
									{/* <h3 class="h-white  mb-4 txt-right-landing-h">
										<span class="color-y txt-transporm">
											POS AND ONLINE PURCHASES
										</span>
									</h3> */}
									{/* <p class=" h-white  mb-4 txt-right-landing-h">
										Bonus! <span class="color-y ">$10 Initial Deposit</span>
									</p>
									<h3 class="mb-0 p-0  h-white txt-right-landing-h justify-content-center">
										<span class="color-y">1st Year Annual Fee</span> Waived!
									</h3>
									<p class="txt-right-landing-p mb-4">
										<i>Annual Fee: $60</i>
									</p> */}
									{/* : */}
									<h3
										class='txt-right-landing-h justify-content-center'
										style={{
											fontSize: '45px',
											fontWeight: 'bolder',
											color: '',
										}}>
										<strong>EXPERIENCE MASPay CARD</strong>
									</h3>
									<p class='txt-right-landing-p mb-5 '>
										<i>like never before</i>
									</p>
									<div class='mt-5 pb-2 pb-5'>
										{' '}
										<button
											type='button'
											class='btn btn-sign-landing12 mt-4'
											onClick={() => this.props.history.push('/affiliate')}>
											Sign Up
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>

				<div>
					<Modal
						show={this.state.showModal}
						onHide={() => {
							this.setState({ showModal: false });
						}}>
						<Modal.Header>
							<Modal.Title>
								By using MASBank Platform, I agree to the Following Terms:
							</Modal.Title>
							{/* <Button
            variant="danger"
            onClick={() => {
              setshowModal(false);
            }}
          >
            <i class="fas fa-times"></i>
          </Button> */}
						</Modal.Header>
						<Modal.Body>
							<div className='alert d-flex flex-column'>
								By using MASBank Platform, I agree to the Following Terms:
								<span>
									- I acknowledge that MASBank is a technical platform and is
									NOT a licensed financial institution in any jurisdiction;
								</span>
								<span>
									- I acknowledge that MASBank is operated by MASB of America
									(BVI) Inc., an independent company to HBS Inc.
								</span>
								<span>
									- I am not a person or entity who resides in, is citizen of,
									is incorporated in, or have a registered office in any
									Prohibited Jurisdictions;
								</span>
								<span>
									- I will not in the future access this site or use MASBank
									Platform while located within any Prohibited Jurisdictions;
								</span>
								<span>
									- I am lawfully permitted to access this site and use MASBank
									Platform under the laws of the jurisdiction on which I reside
									and am located;
								</span>
								<span>
									- I understand the risks associated with entering into and
									using MASBank Platform
								</span>
							</div>
						</Modal.Body>
						<Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								variant='success'
								onClick={() => {
									this.setState({ showModal: false });
								}}>
								Agree And Proceed
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			</div>
		);
	}
}
export default Landing;
