import { useEffect, React, useState, useContext } from 'react';
import './Home.scss';
import RData from '../../Sass/img/R.png';
import { URL } from '../../Utils/url';
import LogoIcon from '../../Sass/img/Logoicon.svg';
import USDT from '../../Sass/img/USDT.png';
import USDC from '../../Sass/img/USDC.png';
import BTC from '../../Sass/img/BTC.png';
import AUTO from '../../Sass/img/AUTO.png';
import ABI from '../../abi.json';
import ABIEther from '../../abiether.json';
import ABIEther2 from '../../abiether2.json';
import ABIXDC from '../../abixdc.json';
import getWeb3 from '../../Utils/getWeb3';
import EPS from '../../Sass/img/EPS.png';
import MBOX from '../../Sass/img/MBOX.png';
import XVS from '../../Sass/img/XVS.png';
import CAKE from '../../Sass/img/CAKE.png';
import BUSD from '../../Sass/img/BUSD.png';
import ETH from '../../Sass/img/ETH.png';
import CRV from '../../Sass/img/Curve.png';
import MKR from '../../Sass/img/MKR.png';
import CVX from '../../Sass/img/convex.png';
import LDO from '../../Sass/img/lido.png';
import AAVE from '../../Sass/img/aave.png';
import UNI from '../../Sass/img/uniswap.png';
import COMP from '../../Sass/img/compound.png';
import INST from '../../Sass/img/instadapp.png';
import BAL from '../../Sass/img/balancer.png';
import SUSHI from '../../Sass/img/sushiswap.png';
import YEARN from '../../Sass/img/yearn.png';
import DAI from '../../Sass/img/dai.png';
import AVAX from '../../Sass/img/avalanche.png';
import WBTC from '../../Sass/img/wrapedbtc.png';
import WETH from '../../Sass/img/wrappedeth.png';
import GBEX from '../../Sass/img/globiance.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../../Utils/cookieHandling';
import HeaderNavigator from '../../Components/Header/HeaderNavigator';
import { UserRoleContext } from '../../Utils/UserAuthorization';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ethers } from 'ethers';
import { BigNumber as BN } from 'bignumber.js';
import BSCMetamaskBalance from './BSCMetamaskBalance';

export default function Allcoins() {
	return (
		<div className='row'>
			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={USDT} width='40px' />
					</div>
					<p>USDT </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>
			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={USDC} width='40px' />
					</div>
					<p>USDC </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>
			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={BTC} width='40px' />
					</div>
					<p>BTCB </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={AUTO} width='40px' />
					</div>
					<p>AUTO </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={EPS} width='40px' />
					</div>
					<p>EPS </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={XVS} width='40px' />
					</div>
					<p>XVS </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>
			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={CAKE} width='40px' />
					</div>
					<p>CAKE </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={BUSD} width='40px' />
					</div>
					<p>BUSD </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={ETH} width='40px' />
					</div>
					<p>ETH </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>
			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img
							src={
								'https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png'
							}
							width='40px'
						/>
					</div>
					<p>BNB </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={MKR} width='40px' />
					</div>
					<p>MKR</p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>

			<div
				className={
					localStorage.getItem('metamaskId') && localStorage.getItem('token')
						? 'col-md-3 p-2 pt-4'
						: 'col-md-3 p-2 pt-4 reduce_opacity'
				}>
				<div className='wbtc wbtc-w'>
					<div className='brificon'>
						<img src={CRV} style={{ width: '40px' }} />
					</div>
					<p>CRV </p>
					<h4 className='price' style={{ fontSize: '15px' }}>
						<>0</>
					</h4>
					<h4 className='price'>$ 0</h4>
				</div>
			</div>
		</div>
	);
}
