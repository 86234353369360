import React, { Fragment, useState, useEffect, useContext } from 'react';
import LogoIcon from '../../Sass/img/Logoicon.svg';
import { getCookie } from '../../Utils/cookieHandling';
import axios from 'axios';
import ABI from '../../abi.json';
import { toast } from 'react-toastify';
import { URL } from '../../Utils/url';
import copy from '../../Sass/img/copy_address.png';
import premium from '../../Sass/img/black.jpeg';
import silver from '../../Sass/img/silver.jpeg';
import golden from '../../Sass/img/golden.jpeg';
import { useHistory, useLocation } from 'react-router';
import { getData, getNetwork, getBalance } from '../../Utils/helpers';
import './CardPurchase.scss';
import getWeb3 from '../../Utils/getWeb3';
import ABIEther2 from '../../abiether2.json';
import { UserRoleContext } from '../../Utils/UserAuthorization';
function CardPurchase(props) {
	const roleContext = useContext(UserRoleContext);
	const history = useHistory();
	const { ethereum } = window;
	const [radioClick, setRadioCheck] = useState('0');
	const [cardType, setCardType] = useState('');
	const [whiteListCheck, setWhiteListCheck] = useState(true);

	const currentChain = localStorage.getItem('currentChain');

	const [state, setState] = useState({
		modal: false,
		address: '',
		balance: '',
		network: '',
		symbol: '',
		isMetaMaskInstall: false,
		isConnected: false,
	});
	const [buttonName, setButtonName] = useState(null);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);

	const handleResize = () => {
		setIsSmallScreen(window.innerWidth < 500);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	useEffect(() => {
		// if (getCookie("metamaskId")) {
		//   setWhiteListCheck(true);
		// }
	}, [getCookie('metamaskId')]);
	useEffect(() => {
		initialize();
		checktoWhitelist();
		// updateAllCoins()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	useEffect(() => {
		sessionStorage.setItem('balance', state.balance);
	}, [state.balance]);

	useEffect(() => {
		if (state.address !== '') {
			getBalance(ethereum, state.address).then((result) => {
				setState((state) => ({
					...state,
					balance: result,
				}));
			});
		} else {
			setState((state) => ({
				...state,
				balance: '',
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.address, state.network]);

	const initialize = () => {
		if (ethereum && ethereum.isMetaMask) {
			console.info('Metamask installed!');

			setState((state) => ({
				...state,
				isMetaMaskInstall: true,
			}));

			getData(ethereum)
				.then((result) => {
					if (result) {
						const [account, network, symbol] = result;

						setState((state) => ({
							...state,
							address: account,
							network,
							symbol,
							isConnected: true,
						}));
					} else {
						setState((state) => ({
							...state,
							isConnected: false,
						}));
					}
				})
				.catch((error) => {});

			ethereum.on('chainChanged', (_chainId) => {
				getNetwork(ethereum, _chainId).then((result) => {
					const [network, symbol] = result;

					setState((state) => ({
						...state,
						network,
						symbol,
					}));
				});
			});

			ethereum.on('accountsChanged', (accounts) => {
				if (accounts[0]) {
					setState((state) => ({
						...state,
						address: accounts[0],
					}));
				} else {
					setState((state) => ({
						...state,
						address: '',
						network: '',
						isConnected: false,
					}));
				}
			});
		} else {
			console.warn('Metamask not installed!');

			setState((state) => ({
				...state,
				isMetaMaskInstall: false,
			}));
		}
	};

	const checktoWhitelist = () => {
		axios
			.get(`${URL}/admin/cardapplyAndWhitelist/${getCookie('metamaskId')}`)
			.then(function (response) {
				if (response.status === 200) {
					if (response.data.address != null) {
						setWhiteListCheck(true);
					}
				} else {
					//  toast.error(response.data.message);
				}
			})
			.catch(function (error) {
				toast.success(error);
			});
	};

	const copyAddressToClipboard = async () => {
		// navigator.clipboard.writeText("0x13e5eC037A9EB4ffff66097372f874eC28fB7d68");
		// toast.success("BEP20 Payment Address copied to clipboard");
	};
	const CheckPayment = async (value, name) => {
		const web3 = await getWeb3();

		let amount;
		let instance;
		if (localStorage.getItem('currentChain').toLowerCase() == 'eth') {
			amount = value * 10 ** 6;
			instance = new web3.eth.Contract(
				ABIEther2,
				'0xdAC17F958D2ee523a2206206994597C13D831ec7'
			);
		} else {
			amount = web3.utils.toWei(value.toString(), 'ether');
			instance = new web3.eth.Contract(
				ABI,
				'0x55d398326f99059ff775485246999027b3197955'
			);
		}
		let gasprice = await web3.eth.getGasPrice();

		await instance.methods
			.transfer('0x13e5eC037A9EB4ffff66097372f874eC28fB7d68', amount)
			.send({
				from: getCookie('metamaskId'),
				gas:
					localStorage.getItem('currentChain').toLowerCase() == 'eth'
						? 23000
						: 50000,
				gasPrice: gasprice,
			})
			.then((res) => {
				axios
					.post(
						`${URL}/users/cardPayment/${getCookie('metamaskId')}`,
						{
							cardStatus: 'paid',
							card_type: name,
							card_purchased_amount: `${value.toString()} USDT`,
							cardpurchase_txhash:
								localStorage.getItem('currentChain').toLowerCase() == 'eth'
									? `https://etherscan.io/tx/${res?.transactionHash}`
									: `https://bscscan.com/tx/${res?.transactionHash}`,
						},
						{
							headers: {
								Authorization: getCookie('token'),
							},
						}
					)
					.then(function (response) {
						if (response.status === 200) {
							localStorage.setItem('cardStatus', 'paid');
							localStorage.setItem('cardName', name);
							roleContext.updateContext({ card_type: name });
							if (roleContext.affiliate) {
								history.push('/card-form');
							} else {
								history.push('/stake');
							}

							toast.success(response.data.message);
							setButtonName('Paid');
						} else {
						}
					})
					.catch(function (error) {
						toast.error('User has not paid');
					});
			})
			.catch((error) => {
				toast.error(error.message);
			});

		// const runInterval = setInterval(async () => {
		//   web3n.eth
		//     .getTransactionReceipt(txHash && txHash)
		//     .then((txReceipt) => {
		//       console.log(txReceipt);
		//     });
		// }, 5000);
	};
	return (
		<form>
			<div className='background-image-test pb--30 new-data'>
				<div className='container py-4'>
					<div className='row '>
						<div className='col-lg-8 mb-lg-0 mx-auto  pt-5 '>
							<div className='card z-index-2'>
								<div className='card-body p-md-6 p-sm-0 pt-md-2 pb-md-0'>
									<h6 className='ms-2 mb-0 text-center text-black h-white headtext  pt-3'>
										{currentChain === 'ETH' ? 'ERC20' : ' BEP20'} Payment
										Address (USDT) <br />
										<span>
											{currentChain === 'ETH' ? 'ERC20' : ' BEP20'} 收款地址
											(USDT)
										</span>
									</h6>

									<div className='row justify-content-center '>
										<div className='col-md-12  p-4 mb-0 '>
											<div className=' justify-content-center text-center pt-0  pb-0'>
												{!buttonName && (
													<>
														<span>
															Your card purchase payment may take some time to
															be confirmed on the blockchain. Refresh this page
															periodically to check payment status. <br />
														</span>
														<span>
															您的卡购买付款可能需要一些时间才能在区块链上得到确认。
															请定期刷新此页面以检查付款状态。
														</span>
													</>
												)}
												{/* <h6 className="ms-2 mb-0 text-center h-white headtext  pt-3">
                          0x13e5eC037A9EB4ffff66097372f874eC28fB7d68
                          <span> </span>
                          <img className="cursor-pointer" src={copy} width="40px" title="Copy"
                            onClick={copyAddressToClipboard} />
                        </h6> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='row my-4'>
						<div className='col-lg-12 mb-lg-0 mb-4'>
							<div className='card z-index-2'>
								<div className='card-body p-md-3 p-sm-0 '>
									<h6 className='ms-2 mb-0 text-center text-black headtext  pt-3'>
										Card Purchase Payment {isSmallScreen && <br />} 购卡付款
									</h6>
									<h6
										className='ms-2 mb-0 text-center text-black   pb-3'
										style={{ fontSize: '14px', fontWeight: 'normal' }}>
										Select your preferred card type {isSmallScreen && <br />}{' '}
										选择您喜欢的卡类型
									</h6>
									<form>
										<div className='row p-4 pt-0 justify-content-center'>
											<div
												className={
													radioClick == '0'
														? 'col-md-10  pt-3'
														: 'col-md-10  pt-3 '
												}>
												<div className='row '>
													<div className='col-md-8 col-sm-12'>
														<div className='custom-control custom-checkbox mb-3'>
															<div className='row '>
																<div className='col-md-1 col-sm-12'>
																	<input
																		type='radio'
																		className='custom-control-input'
																		id='customCheck1'
																		name='example1'
																		checked={radioClick == '0'}
																		onChange={(e) => {
																			setRadioCheck(
																				e.target.checked ? '0' : radioClick
																			);
																		}}
																		// checked={(roleContext.card_type === "Premium Black") ? true : false}
																	/>
																</div>
																<div className='col-md-8 col-sm-12'>
																	<label
																		className='custom-control-label'
																		for='customCheck'>
																		<img src={premium} width='100%' />
																	</label>
																</div>
															</div>
														</div>
													</div>
													<div className='col-md-4 col-sm-12 new-btn'>
														<p>MASPay Card 名资支付卡</p>
														<select
															className='form-control form-control-2'
															style={{ border: '1px solid grey' }}
															id='exampleFormControlSelect1'
															onChange={(e) => {
																if (e.target.value == 'Select Type') {
																	return;
																}
																setCardType(e.target.value);
															}}>
															<option>Select Type 选择类型</option>
															<option value={'UnionPay International'}>
																UnionPay 银联
															</option>
															<option value={'visa'}>Visa</option>
														</select>
														<h3
															className='pt-0 mt-0 color-head'
															style={{ color: '#ddb345' }}>
															{roleContext.affiliate ? '300' : '300'}{' '}
															<span className='color-y'>$USDT</span>
														</h3>

														<button
															type='button'
															className='btn  btns-w '
															style={{ lineHeight: '18px !important' }}
															//  disabled={(roleContext.card_type === "Premium Black") ? false : true}
															onClick={() => {
																if (radioClick != '0') {
																	toast.error('Please select MasPay');
																	return;
																}
																if (cardType == '') {
																	toast.error('Please select card type');
																	return;
																}
																if (cardType == 'UnionPay International') {
																	CheckPayment(
																		roleContext.affiliate ? 300 : 300,
																		'MasPay UPI'
																	);
																} else {
																	CheckPayment(
																		roleContext.affiliate ? 300 : 300,
																		'MasPay'
																	);
																}
															}}>
															{buttonName &&
															roleContext.card_type === 'MasPay' ? (
																'Paid'
															) : (
																<>
																	CONFIRM PAYMENT {isSmallScreen && <br />}{' '}
																	确认付款
																</>
															)}
														</button>
													</div>
												</div>
											</div>

											{/* <div
                        className={
                          radioClick == "1"
                            ? "col-md-6  pt-3"
                            : "col-md-6  pt-3 "
                        }
                      >
                        <div className="row ">
                          <div className="col-md-8 col-sm-12">
                            <div className="custom-control custom-checkbox mb-3">
                              <div className="row ">
                                <div className="col-md-1 col-sm-12">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="customCheck1"
                                    name="example1"
                                    checked={radioClick == "1"}
                                    onChange={(e) => {
                                      setRadioCheck(
                                        e.target.checked ? "1" : radioClick
                                      );
                                    }}
                                    //   checked={(roleContext.card_type === "Black Metal") ? true : false}
                                  />
                                </div>
                                <div className="col-md-11 col-sm-12">
                                  <label
                                    className="custom-control-label"
                                    for="customCheck"
                                  >
                                    <img src={premium} width="100%" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12 new-btn">
                            <p>Black Metal</p>
                            <h3 className="pt-0 mt-0 color-head" style={{color:"#ddb345"}}>
                              {roleContext.affiliate ? "500" : "350"}{" "}
                              <span className="color-y">$USDT</span>
                            </h3>

                            <button
                              type="button"
                              className="btn  btns-w "
                              width="30px"
                              // disabled={(roleContext.card_type === "Black Metal") ? false : true}
                              style={{ lineHeight: "18px !important" }}
                              onClick={() => {
                                if (radioClick != "1") {
                                  toast.error("Please select Black Metal");
                                  return;
                                }
                                CheckPayment(
                                  roleContext.affiliate ? 500 : 350,
                                  "Black Metal"
                                );
                              }}
                            >
                              {buttonName &&
                              roleContext.card_type === "Black Metal"
                                ? "Paid"
                                : "CONFIRM PAYMENT"}
                            </button>
                          </div>
                        </div>
                      </div> */}
										</div>

										{/* <div className="row p-4 pt-0 justify-content-center">
                      <div
                        className={
                          radioClick == "2"
                            ? "col-md-6  pt-3"
                            : "col-md-6  pt-3 "
                        }
                      >
                        <div className="row ">
                          <div className="col-md-8 col-sm-12">
                            <div className="custom-control custom-checkbox mb-3">
                              <div className="row ">
                                <div className="col-md-1 col-sm-12">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="customCheck1"
                                    name="example1"
                                    checked={radioClick == "2"}
                                    onChange={(e) => {
                                      setRadioCheck(
                                        e.target.checked ? "2" : radioClick
                                      );
                                    }}
                                    // checked={(roleContext.card_type === "Silver Metal") ? true : false}
                                  />
                                </div>
                                <div className="col-md-11 col-sm-12">
                                  <label
                                    className="custom-control-label"
                                    for="customCheck"
                                  >
                                    <img src={silver} width="100%" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12 new-btn">
                            <p>Silver Metal</p>
                            <h3 className="pt-0 mt-0 color-head" style={{color:"#ddb345"}}>
                              {roleContext.affiliate ? "500" : "350"}{" "}
                              <span className="color-y">$USDT</span>
                            </h3>

                            <button
                              type="button"
                              className="btn  btns-w "
                              // disabled={(roleContext.card_type === "Silver Metal") ? false : true}
                              style={{ lineHeight: "18px !important" }}
                              onClick={() => {
                                if (radioClick != "2") {
                                  toast.error("Please select Silver Metal");
                                  return;
                                }
                                CheckPayment(
                                  roleContext.affiliate ? 500 : 350,
                                  "Silver Metal"
                                );
                              }}
                            >
                              {buttonName &&
                              roleContext.card_type === "Silver Metal"
                                ? "Paid"
                                : "CONFIRM PAYMENT"}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          radioClick == "3"
                            ? "col-md-6  pt-3"
                            : "col-md-6  pt-3 "
                        }
                      >
                        <div className="row ">
                          <div className="col-md-8 col-sm-12">
                            <div className="custom-control custom-checkbox mb-3">
                              <div className="row ">
                                <div className="col-md-1 col-sm-12">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="customCheck1"
                                    name="example1"
                                    checked={radioClick == "3"}
                                    onChange={(e) => {
                                      setRadioCheck(
                                        e.target.checked ? "3" : radioClick
                                      );
                                    }}
                                    //  checked={(roleContext.card_type === "Gold Metal") ? true : false}
                                  />
                                </div>
                                <div className="col-md-11 col-sm-12">
                                  <label
                                    className="custom-control-label"
                                    for="customCheck"
                                  >
                                    <img src={golden} width="100%" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12 new-btn">
                            <p>Gold Metal</p>
                            <h3 className="pt-0 mt-0 color-head" style={{color:"#ddb345"}}>
                              {roleContext.affiliate ? "500" : "350"}{" "}
                              <span className="color-y">$USDT</span>
                            </h3>

                            <button
                              type="button"
                              className="btn  btns-w "
                              width="30px"
                              //  disabled={(roleContext.card_type === "Gold Metal") ? false : true}
                              style={{ lineHeight: "18px !important" }}
                              onClick={() => {
                                if (radioClick != "3") {
                                  toast.error("Please select Gold Metal");
                                  return;
                                }

                                CheckPayment(
                                  roleContext.affiliate ? 500 : 350,
                                  "Gold Metal"
                                );
                              }}
                            >
                              {buttonName &&
                              roleContext.card_type === "Gold Metal"
                                ? "Paid"
                                : "CONFIRM PAYMENT"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<span className='d-flex justify-content-center span-txt txt--white mb--20'>
					Ensure that correct USDT amount is sent from your connected wallet
					address for card purchase, otherwise card purchase will be rejected
					and no refunds will be entertained.
				</span>
				<span className='d-flex justify-content-center span-txt txt--white mb--20'>
					请确保从您连接的钱包地址发送正确的 USDT
					金额用于购卡，否则购卡将被拒绝且不予退款。
				</span>
			</div>
		</form>
	);
}

export default CardPurchase;
